import { BuycepsLogoStyle } from "@components/atoms/BuycepsLogo/types"
import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const BuycepsLogoContainer = styled.div<{ logoStyle: BuycepsLogoStyle }>``
BuycepsLogoContainer.displayName = "S.BuycepsLogoContainer"

export const BuycepsLogo = styled.div<{ margin: string }>`
  ${(props) => `
    width: 7rem;
    margin: ${props.margin};
  `};
`
BuycepsLogoContainer.displayName = "S.BuycepsLogoContainer"

export const BuycepsLogoMessage = styled.p<{
  mobileScreen: boolean
  logoStyle?: BuycepsLogoStyle
}>`
  font-family: ${C.baseFontFamily};
  color: ${C.theme.baseFontColor};
  font-size: ${C.fontSizeCaptions};
  font-weight: ${(props) =>
    props.mobileScreen ? C.lightFontWeight : C.normalFontWeight};
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.logoStyle === BuycepsLogoStyle.CART_PAGE ? "center" : "flex-start"};
  opacity: ${(props) =>
    props.logoStyle === BuycepsLogoStyle.SMALL_WITH_AUTHENTICITY ? "0.5" : "1"};
  svg {
    margin-top: 2px;
    margin-left: 2px;
  }

  ${(props) =>
    props.logoStyle === BuycepsLogoStyle.CART_PAGE &&
    `
    margin-top: -4px;
    opacity: 0.6;
    svg {
      margin-left: 4px;
      height: 1rem;
      width: 1rem;
    }
  `}
`
