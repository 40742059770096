import { SpacerStyle, SpacerType } from "@components/atoms/Spacer/types"
import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const SpacerContainer = styled.div<{
  spacerStyle: SpacerStyle
  spacerType: SpacerType
}>`
  position: relative;
  ${(props) => `
    ${
      props.spacerType === "horizontal"
        ? `
        width: 100%;
        height: ${C.spacing[props.spacerStyle]};
      `
        : `
        width: ${C.spacing[props.spacerStyle]};
        height: 100%;
      `
    }
  `}
`
SpacerContainer.displayName = "S.SpacerContainer"
