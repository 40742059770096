import { ApolloError, makeVar } from "@apollo/client"
import { User } from "@app/globalStates/User/types/User"
import { getAuthToken } from "@core/auth"

export interface UserGlobal {
  loading: boolean
  errors: ApolloError[] | null
  token: string | null
  user: User | null
}

const initialState = {
  token: getAuthToken(),
  user: null,
  errors: null,
  loading: false,
}
// initializing reactive variables
export const userGlobalState = makeVar<UserGlobal>(initialState)
