import { useModal } from "@temp/@next/hooks/useModal/useModal"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import React from "react"
import { Spacer } from "../../atoms/Spacer"
import { ErrorModal } from "../../organisms/ErrorModal/ErrorModal"
import * as S from "./styles"

interface Props {
  error: Error
  resetError?: () => void
  errorTitle?: string
  componentName?: string
}

export const ErrorFallbackUi = (props: Props) => {
  const { error, resetError, errorTitle, componentName } = props
  const matches: GlobalMediaQueryResultInterface = getMedia()
  const [Modal, open] = useModal({
    preventScroll: true,
    closeOnOverlayClick: true,
  })

  return (
    <S.ErrorWrapper role="alert">
      <S.ErrorTitle>{errorTitle}</S.ErrorTitle>
      {error?.message && <S.ErrorMessage>{error?.message}</S.ErrorMessage>}
      <Spacer spacerStyle="spacerExtra" />
      <S.ErrorButtonGroup>
        {resetError && (
          <S.ErrorButton onClick={() => resetError()}>Try again</S.ErrorButton>
        )}
        <S.ErrorButton onClick={() => open()}>View Error</S.ErrorButton>
      </S.ErrorButtonGroup>
      <Modal
        title="Help us fix this issue by sharing it with us"
        loading={false}
        hideModalButtons
        position={matches.small ? "bottom" : "center"}
      >
        <ErrorModal error={error} componentName={componentName!} />
      </Modal>
    </S.ErrorWrapper>
  )
}

ErrorFallbackUi.defaultProps = {
  resetError: undefined,
  errorTitle: "Uh Oh! Something went wrong...",
  componentName: "Not Found",
}
