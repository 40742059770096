import { HeaderStyle } from "@components/molecules/Header/types"
import styled from "styled-components"
import { ReactSVG } from "react-svg"
import * as C from "@temp/@next/globalStyles/constants"

const getHeaderHeight = (headerStyle: HeaderStyle, mobileScreen: boolean) => {
  switch (headerStyle) {
    case HeaderStyle.LOGIN: {
      return mobileScreen ? "5.25rem" : "4.75rem"
    }
    case HeaderStyle.CHECKOUT: {
      return "4.25rem"
    }
    //no default
  }
}

export const HeaderContainer = styled.h1<{
  headerStyle: HeaderStyle
  mobileScreen: boolean
  shouldSticky: boolean
}>`
  position: sticky;
  top: -0.1px;
  ${(props) =>
    props.shouldSticky &&
    `
      box-shadow: ${C.defaultShadow};
    `};
  left: 0;
  transition: box-shadow 300ms;
  padding: 0.75rem 1.5rem;
  min-height: ${(props) =>
    getHeaderHeight(props.headerStyle, props.mobileScreen)} !important;
  width: 100%;
  max-width: 100vw;
  background-color: ${C.theme.primary};
  display: flex;
  justify-content: ${(props) =>
    props.headerStyle === HeaderStyle.CARTPAGE ? "center" : "space-between"};
  align-items: center;
  z-index: 3;
  overflow: hidden;
  margin-bottom: 0px !important;
`
HeaderContainer.displayName = "S.HeaderContainer"

export const AuthenticityImage = styled(ReactSVG)`
  transform: scale(0.9) rotate(21deg);
  margin-right: 1rem;
  position: absolute;
  right: ${C.spacing.spacer};
  top: -3px;
`
AuthenticityImage.displayName = "S.AuthenticityImage"

export const AffliliateNavText = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  left: 10%;
  padding: 30px 10px;
  border-bottom: 2px solid;
  font: normal normal 500 16px/19px Causten;
  @media (min-width: 1200px) and (max-width: 1366px) {
    left: 12%;
  }
`
