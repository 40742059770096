import styled from "styled-components"

export const SocialWrapper = styled.div`
  padding: 0.2rem 0.5rem;

  svg * {
    transition: 0.3s;
    fill: #ffffff;
  }

  &:hover {
    svg * {
      fill: $color-primary;
    }
  }
`
