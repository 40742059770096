import { useReactiveVar } from "@apollo/client"
import { BuycepsLogo } from "@components/atoms/BuycepsLogo"
import { BuycepsLogoStyle } from "@components/atoms/BuycepsLogo/types"
import { HeaderProps, HeaderStyle } from "@components/molecules/Header/types"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import dynamic from "next/dynamic"
import React, { memo, useRef, useState } from "react"
import * as S from "./styles"

const BrowseSupplementsLink = dynamic(() =>
  import("@components/atoms/BrowseSupplementsLink").then(
    (mod) => mod.BrowseSupplementsLink
  )
)

const getLogoStyle = (style: HeaderStyle) => {
  if (style === HeaderStyle.CHECKOUT) {
    return BuycepsLogoStyle.SMALL_WITH_AUTHENTICITY
  } else if (style === HeaderStyle.CARTPAGE) {
    return BuycepsLogoStyle.CART_PAGE
  } else {
    return BuycepsLogoStyle.SMALL_WITH_MESSAGE
  }
}

const Header: React.FC<HeaderProps> = ({ headerStyle = HeaderStyle.NORMAL }) => {
  const matches: GlobalMediaQueryResultInterface = useReactiveVar(getMedia)
  const [shouldSticky, setShouldSticky] = useState(false)
  const scrollRef = useRef(null)

  const stickyObserver =
    typeof window !== "undefined" ?
      new IntersectionObserver(([e]) => setShouldSticky(e.intersectionRatio < 1), {
        threshold: [1],
      }) : undefined

  if (scrollRef && scrollRef.current) {
    stickyObserver?.observe(scrollRef.current)
  }

  return (
    <S.HeaderContainer
      shouldSticky={shouldSticky}
      ref={scrollRef}
      headerStyle={headerStyle}
      mobileScreen={matches.small}
    >
      <BuycepsLogo logoStyle={getLogoStyle(headerStyle)} />
      {headerStyle === HeaderStyle.LOGIN && !matches.small && (
        <BrowseSupplementsLink />
      )}
      {headerStyle === HeaderStyle.CHECKOUT && (
        <S.AuthenticityImage
          src={"/images/Checkout/product-authenticity-mask.svg"}
        />
      )}
    </S.HeaderContainer>
  )
}

export const MemoizedHeader = memo(Header)
