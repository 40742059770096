import { useMutation, useReactiveVar } from "@apollo/client"
import { checkoutGlobalState } from "@temp/@next/globalStates/Checkout/CheckoutGlobalState"
import { useRouter } from "next/router"
import React, { useEffect } from "react"
import { useCookies } from "react-cookie"
import { addReferralCodeToCheckoutMutation, affiliateClickMutation } from "./queries"

export const AffiliateCookie: React.FC = () => {
  const router = useRouter()
  const [clickMutation] = useMutation(affiliateClickMutation)
  const [addReferralMutation] = useMutation(addReferralCodeToCheckoutMutation)

  const { checkout } = useReactiveVar(checkoutGlobalState)
  let checkoutToken
  if (typeof window !== "undefined") {
    checkoutToken = localStorage.getItem("checkoutToken")
  }

  const [cookies, setCookie] = useCookies(["click_id", "referral_code"])

  const expires = new Date()
  expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 45)

  const rawMetaData = Object.entries(router.query).filter((v) =>
    v[0].startsWith("tm_")
  )
  const metaData = Object.fromEntries(
    rawMetaData.map(([key, value]) => [`${key.split("_")[1]}`, value])
  )

  const getClickId = async () => {
    const dataClick = await clickMutation({
      variables: {
        referralCode: router.query.ref,
        sourceId: router.query.tap_s,
        clickMetadata: metaData,
        landingPage: document.URL,
        referrer: document.referrer,
      },
    })
    const { data } = dataClick
    setCookie("click_id", data.affiliateClick.clickId, { expires })
  }

  const addReferralCodeToCheckout = async (
    referralCode: String,
    checkoutId: String
  ) => {
    await addReferralMutation({ variables: { referralCode, checkoutId } })
  }

  if (!cookies.referral_code && router.query.ref) {
    setCookie("referral_code", router.query.ref, { expires })
    router.replace(router.route, undefined, { shallow: true })
    getClickId()
  }
  useEffect(() => {
    if (checkoutToken && checkout?.referralCode === null && cookies.referral_code) {
      addReferralCodeToCheckout(cookies.referral_code, checkout?.id)
    }
  }, [checkoutToken])

  return <></>
}
