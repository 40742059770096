import { styled } from "@styles"
import * as C from "@temp/@next/globalStyles/constants"

const horizontalPadding = "2rem"

export const Overlay = styled.div`
  position: fixed;
  top: 20px;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000000;
`
Overlay.displayName = "S.Overlay"

export const OverlayModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100000000001;
`
OverlayModal.displayName = "S.OverlayModal"

export const Modal = styled.div<{
  position?: string
  largeHeight?: boolean
  backgroundTransparent: boolean
}>`
  position: fixed;
  z-index: 100000000002;
  width: 100%;
  background-color: ${({ backgroundTransparent }) =>
    !backgroundTransparent && "#fff"};
  border-radius: 0.5rem 0.5rem 0 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  .modal__content {
    flex-grow: 1;
  }

  ${(props) =>
    props.position === "center" &&
    `
    top: -1rem;
    bottom: 0;
    right: 0;
    left: 0;
    position: relative;
    max-width: 90%;
    animation: CenterOpen 0.3s;
    height: 95vh;
    overflow-y: auto;
    border-radius: ${C.v2Theme.borderRadius.borderRadius1};
  `}
  ${(props) =>
    props.position === "left" &&
    `
    left: 0;
    top: 0;
    height: 100%;
    width: 23.75rem;
    animation: sidenavLeftOpen 0.3s;
  `}
  ${(props) =>
    props.position === "right" &&
    `
    top: 0;
    right: 0;
    height: 100%;
    width: 23.75rem;
    animation: sidenavRightOpen 0.3s;
    border-radius: 0;

  `}
  ${(props) =>
    props.position === "bottom" &&
    `
    height: 80vh;
    bottom: 0;
    left: 0;
    transition: 300ms;
    overflow-y: scroll;
  `}
`
Modal.displayName = "S.Modal"

export const ModalTitle = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  align-items: center;
  /* border-bottom: 1px ${C.theme.turquoiseLight} solid; */
  color: ${C.v2Theme.fontColors.black};
  display: flex;
  font-weight: bold;
  /* background-color: #fff; */

  p {
    margin-right: auto;
  }
  padding: 1rem;
`
ModalTitle.displayName = "S.ModalTitle"

export const Footer = styled.div`
  margin-top: 2rem;
  position: relative;

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: ${C.colorGray2};
    height: 1px;
    width: calc(100% + ${horizontalPadding} * 2);
  }
`
Footer.displayName = "S.Footer"

export const CancelButton = styled.button`
  font-size: 16px;
  color: #21125e;
  background: transparent;
  outline: none;
  border: none;
  box-shadow: none;
  transform: none;
  text-decoration: underline;
  padding: 0;
  margin-right: 1rem;

  &:hover {
    background: transparent;
  }
`
CancelButton.displayName = "S.CancelBtn"

export const Close = styled.div`
  cursor: pointer;
  svg {
    path {
      fill: #fff;
    }
  }
`
Close.displayName = "S.Close"
