import { gql } from "@apollo/client"

export const secondaryMenu = gql`
  fragment SecondaryMenuSubItem on MenuItem {
    id
    name
    finalUrl
  }

  query SecondaryMenu {
    shop {
      navigation {
        secondary {
          id
          items {
            ...SecondaryMenuSubItem
            children {
              ...SecondaryMenuSubItem
            }
          }
        }
      }
    }
  }
`
