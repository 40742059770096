import { useQuery } from "@apollo/client"
import React from "react"

import { maybe } from "../../core/utils"
import { defaultContext, ShopContext } from "./context"
import { getShopQuery } from "./queries"

const ShopProvider: React.FC = ({ children }) => {
  const { data } = useQuery(getShopQuery)
  return (
    <ShopContext.Provider
      value={{
        ...maybe(() => data.shop, defaultContext),
        ...{ states: defaultContext.states },
      }}
    >
      {children}
    </ShopContext.Provider>
  )
}

export default ShopProvider
