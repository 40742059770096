import { useLayoutEffect } from "react"

export function useLockBodyScroll(isOpen: boolean): void {
  // useLaoutEffect callback return type is "() => void" type
  useLayoutEffect((): (() => void) => {
    // Get original body overflow
    const originalStyle: string = window.getComputedStyle(document.body).overflow
    if (isOpen) {
      // Prevent scrolling on mount
      document.body.style.overflow = "hidden"
      // Re-enable scrolling when component unmounts
    }
    return () => {
      document.body.style.overflow = originalStyle
    }
  }, [isOpen]) // Empty array ensures effect is only run on mount and unmount
}
