import { useReactiveVar } from "@apollo/client"
import { Icon } from "@components/atoms/Icon"
import Link from "@temp/core/link"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import React from "react"
import Image from "next/image"

import * as S from "./styles"
import { BuycepsLogoProps, BuycepsLogoStyle } from "./types"

export const BuycepsLogo: React.FC<BuycepsLogoProps> = ({
  logoStyle = BuycepsLogoStyle.SMALL,
  ...props
}: BuycepsLogoProps) => {
  const matches: GlobalMediaQueryResultInterface = useReactiveVar(getMedia)
  const getLogoMessage = () => {
    if (
      logoStyle === BuycepsLogoStyle.LARGE_WITH_MESSAGE ||
      logoStyle === BuycepsLogoStyle.MEDIUM_WITH_MESSAGE ||
      logoStyle === BuycepsLogoStyle.SMALL_WITH_MESSAGE
    ) {
      return (
        <S.BuycepsLogoMessage mobileScreen={matches.small}>
          Powering Your Fitness Regime
        </S.BuycepsLogoMessage>
      )
    } else if (
      logoStyle === BuycepsLogoStyle.LARGE_WITH_AUTHENTICITY ||
      logoStyle === BuycepsLogoStyle.MEDIUM_WITH_AUTHENTICITY ||
      logoStyle === BuycepsLogoStyle.SMALL_WITH_AUTHENTICITY
    ) {
      return (
        <S.BuycepsLogoMessage mobileScreen={matches.small} logoStyle={logoStyle}>
          100% Authenticity
          <Icon iconName={"authenticity"} size={20} />
        </S.BuycepsLogoMessage>
      )
    } else if (logoStyle === BuycepsLogoStyle.CART_PAGE) {
      return (
        <S.BuycepsLogoMessage mobileScreen={matches.small} logoStyle={logoStyle}>
          Your Shopping Cart
          <Icon iconName="bag" size={32} />
        </S.BuycepsLogoMessage>
      )
    }
  }

  let src = "/images/components/buycepslogo/buyceps-logo.svg"
  if (process.env.NEXT_PUBLIC_WEBSITE === "INWELCO") {
    src = "/images/components/buycepslogo/inwelco-logo.svg"
  }

  return (
    <S.BuycepsLogoContainer logoStyle={logoStyle}>
      <Link prefetch={false} href="/">
        <a>
          <S.BuycepsLogo
            margin={logoStyle === BuycepsLogoStyle.CART_PAGE ? "auto" : "none"}
          >
            <Image
              width={120}
              height={36}
              src={src}
              layout="fixed"
              alt="Buyceps Logo"
              priority
            />
          </S.BuycepsLogo>
          {getLogoMessage()}
        </a>
      </Link>
    </S.BuycepsLogoContainer>
  )
}
