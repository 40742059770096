import { gql } from "@apollo/client"

export const affiliateClickMutation = gql`
  mutation AffiliateClick(
    $referralCode: String!
    $sourceId: String
    $landingPage: String
    $referrer: String
  ) {
    affiliateClick(
      referralCode: $referralCode
      sourceId: $sourceId
      landingPage: $landingPage
      referrer: $referrer
    ) {
      clickId
      errors {
        field
        message
      }
    }
  }
`

export const addReferralCodeToCheckoutMutation = gql`
  mutation addReferralCodeToCheckout($referralCode: String!, $checkoutId: ID!) {
    checkoutAddReferralCode(referralCode: $referralCode, checkoutId: $checkoutId) {
      checkout {
        id
        referralCode
      }
      errors {
        field
        message
      }
    }
  }
`
