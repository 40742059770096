/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum AddressTypeEnum {
  BILLING = "BILLING",
  SHIPPING = "SHIPPING",
}

export enum AuthProvider {
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
}

/**
 * An enumeration.
 */
export enum DiscountVoucherDiscountValueTypeChoices {
  FIXED = "FIXED",
  FREEBIE = "FREEBIE",
  PERCENTAGE = "PERCENTAGE",
}

/**
 * An enumeration.
 */
export enum DiscountVoucherTypeChoices {
  GENERIC_VOUCHER = "GENERIC_VOUCHER",
  PRODUCT_REVIEW_VOUCHER = "PRODUCT_REVIEW_VOUCHER",
  VALUE = "VALUE",
}

/**
 * An enumeration.
 */
export enum GatewaysEnum {
  COD = "COD",
  INGENICO = "INGENICO",
  JUSPAY = "JUSPAY",
  RAZORPAY = "RAZORPAY",
  SMS = "SMS",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum Goal {
  GET_FIT = "GET_FIT",
  MUSCLE_GAIN = "MUSCLE_GAIN",
  WEIGHT_GAIN = "WEIGHT_GAIN",
  WEIGHT_LOSS = "WEIGHT_LOSS",
}

/**
 * An enumeration.
 */
export enum LayoutLayoutLineTypeChoices {
  BANNER_LIST = "BANNER_LIST",
  BRAND = "BRAND",
  CATEGORY = "CATEGORY",
  COLLECTION = "COLLECTION",
  EXCLUSIVE_ON_BUYCEPS = "EXCLUSIVE_ON_BUYCEPS",
  MAIN_BANNER = "MAIN_BANNER",
  OVERALL_TRENDING = "OVERALL_TRENDING",
  TRENDING_BRANDS = "TRENDING_BRANDS",
}

export enum LayoutPlatform {
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
}

export enum OTPPurpose {
  CHANGE_PHONE = "CHANGE_PHONE",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  LOGIN = "LOGIN",
  SET_PASSWORD = "SET_PASSWORD",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  VERIFY_PHONE = "VERIFY_PHONE",
}

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * An enumeration.
 */
export enum OrderFulfillmentStatusChoices {
  CANCELED = "CANCELED",
  DRAFT = "DRAFT",
  FULFILLED = "FULFILLED",
}

/**
 * An enumeration.
 */
export enum OrderOnlineShipmentCourierHandlerChoices {
  BLUEDART = "BLUEDART",
  BUYCEPS = "BUYCEPS",
  OTHERCOURIERS = "OTHERCOURIERS",
  SHIPROCKET = "SHIPROCKET",
}

/**
 * An enumeration.
 */
export enum OrderOnlineShipmentDeliveryStatusChoices {
  CANCELLED = "CANCELLED",
  DELIVERED = "DELIVERED",
  ORDER_CONFIRMED = "ORDER_CONFIRMED",
  OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY",
  READY_TO_SHIP = "READY_TO_SHIP",
  SHIPPED = "SHIPPED",
}

/**
 * An enumeration.
 */
export enum OrderOnlineShipmentStatusChoices {
  CANCELLED = "CANCELLED",
  DRAFT = "DRAFT",
  PROCESSED = "PROCESSED",
}

/**
 * An enumeration.
 */
export enum OrderStatus {
  CANCELED = "CANCELED",
  DRAFT = "DRAFT",
  EDITING = "EDITING",
  FULFILLED = "FULFILLED",
  PARTIALLY_FULFILLED = "PARTIALLY_FULFILLED",
  UNFULFILLED = "UNFULFILLED",
}

/**
 * An enumeration.
 */
export enum OrganizationOrganizationTypeChoices {
  FRANCHISE = "FRANCHISE",
  MARKETPLACE = "MARKETPLACE",
  WHOLESALE = "WHOLESALE",
}

export enum PasswordType {
  OTP = "OTP",
  PASSWORD = "PASSWORD",
}

/**
 * An enumeration.
 */
export enum PaymentChargeStatusEnum {
  FULLY_CHARGED = "FULLY_CHARGED",
  FULLY_REFUNDED = "FULLY_REFUNDED",
  NOT_CHARGED = "NOT_CHARGED",
  PARTIALLY_CHARGED = "PARTIALLY_CHARGED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
}

/**
 * An enumeration.
 */
export enum PaymentGatewayEnum {
  CUSTOM = "CUSTOM",
  INGENICO = "INGENICO",
  JUSPAY = "JUSPAY",
  RAZORPAY = "RAZORPAY",
}

/**
 * An enumeration.
 */
export enum PaymentTransactionErrorChoices {
  TRANSACTIONERROR_DECLINED = "TRANSACTIONERROR_DECLINED",
  TRANSACTIONERROR_EXPIRED = "TRANSACTIONERROR_EXPIRED",
  TRANSACTIONERROR_INCORRECT_ADDRESS = "TRANSACTIONERROR_INCORRECT_ADDRESS",
  TRANSACTIONERROR_INCORRECT_CVV = "TRANSACTIONERROR_INCORRECT_CVV",
  TRANSACTIONERROR_INCORRECT_NUMBER = "TRANSACTIONERROR_INCORRECT_NUMBER",
  TRANSACTIONERROR_INCORRECT_ZIP = "TRANSACTIONERROR_INCORRECT_ZIP",
  TRANSACTIONERROR_INVALID_CVV = "TRANSACTIONERROR_INVALID_CVV",
  TRANSACTIONERROR_INVALID_EXPIRY_DATE = "TRANSACTIONERROR_INVALID_EXPIRY_DATE",
  TRANSACTIONERROR_INVALID_NUMBER = "TRANSACTIONERROR_INVALID_NUMBER",
  TRANSACTIONERROR_PROCESSING_ERROR = "TRANSACTIONERROR_PROCESSING_ERROR",
}

export enum PaymentUpdateMethodEnum {
  COD = "COD",
  ONLINE = "ONLINE",
}

export enum ProductOrderField {
  DATE = "DATE",
  NAME = "NAME",
  POPULARITY = "POPULARITY",
  PRICE = "PRICE",
}

/**
 * An enumeration.
 */
export enum ProductProductRecommendationLevelChoices {
  HIGHLY_RECOMMENDED = "HIGHLY_RECOMMENDED",
  MUST_BUY = "MUST_BUY",
  SOMEWHAT_RECOMMENDED = "SOMEWHAT_RECOMMENDED",
}

export enum ProductReviewSortOrder {
  LATEST = "LATEST",
  POPULAR = "POPULAR",
}

export enum ProductReviewVoteOperation {
  VOTE_ADDED = "VOTE_ADDED",
  VOTE_REMOVED = "VOTE_REMOVED",
  VOTE_UPDATED = "VOTE_UPDATED",
}

export enum ProductReviewVoteType {
  DOWNVOTE = "DOWNVOTE",
  REMOVE_VOTE = "REMOVE_VOTE",
  UPVOTE = "UPVOTE",
}

export enum ProductUnboxingMediaTypeEnum {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

/**
 * An enumeration.
 */
export enum PurchaseEntryBarcodeStatusChoices {
  AVAILABLE = "AVAILABLE",
  DAMAGED = "DAMAGED",
  EXPIRED = "EXPIRED",
  INWARD_ENTRY_DRAFT = "INWARD_ENTRY_DRAFT",
  INWARD_STN_CONFIRMED = "INWARD_STN_CONFIRMED",
  INWARD_STN_NOT_CONFIRMED = "INWARD_STN_NOT_CONFIRMED",
  MISSING = "MISSING",
  NEAR_EXPIRY = "NEAR_EXPIRY",
  ORDER_ALLOCATED = "ORDER_ALLOCATED",
  ORDER_DISPATCHED = "ORDER_DISPATCHED",
  ORDER_DRAFT = "ORDER_DRAFT",
  OUTWARD_STN_CONFIRMED = "OUTWARD_STN_CONFIRMED",
  OUTWARD_STN_DRAFT = "OUTWARD_STN_DRAFT",
  OUTWARD_STN_MISSING = "OUTWARD_STN_MISSING",
  OUTWARD_STN_NOT_CONFIRMED = "OUTWARD_STN_NOT_CONFIRMED",
  RETURNED = "RETURNED",
}

export enum PushSubscriptionTypeEnum {
  ORDER = "ORDER",
  UPDATE = "UPDATE",
}

/**
 * An enumeration.
 */
export enum ReviewProductReviewStatusChoices {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

/**
 * An enumeration.
 */
export enum ReviewProductUnboxingMediaAvailabilityStatusChoices {
  AVAILABLE = "AVAILABLE",
  TRANSCODING_ERROR = "TRANSCODING_ERROR",
  UPLOAD_COMPLETE = "UPLOAD_COMPLETE",
  UPLOAD_ERROR = "UPLOAD_ERROR",
  UPLOAD_IN_PROGRESS = "UPLOAD_IN_PROGRESS",
  UPLOAD_PENDING = "UPLOAD_PENDING",
}

/**
 * An enumeration.
 */
export enum ReviewProductUnboxingMediaMediaTypeChoices {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

/**
 * An enumeration.
 */
export enum ReviewProductUnboxingMediaModerationStatusChoices {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum UsernameField {
  EMAIL = "EMAIL",
  EMAIL_OR_PHONE = "EMAIL_OR_PHONE",
  PHONE = "PHONE",
}

export interface AccessTokenInput {
  provider: AuthProvider
  value: string
}

export interface AddressInput {
  firstName?: string | null
  lastName?: string | null
  companyName?: string | null
  streetAddress1?: string | null
  streetAddress2?: string | null
  city?: string | null
  cityArea?: string | null
  postalCode?: string | null
  country?: string | null
  countryArea?: string | null
  phone?: string | null
  email?: string | null
  lat?: number | null
  lon?: number | null
  landmark?: string | null
}

export interface CheckoutCreateInput {
  lines: (CheckoutLineInput | null)[]
  email?: string | null
  phone?: string | null
  shippingAddress?: AddressInput | null
  billingAddress?: AddressInput | null
  referralCode?: string | null
}

export interface CheckoutLineInput {
  quantity: number
  variantId?: string | null
  variationId?: string | null
  isNearExpiry?: boolean | null
}

export interface ClientInfo {
  fbc?: string | null
  fbp?: string | null
  sourceUrl?: string | null
}

export interface CustomerInput {
  defaultBillingAddress?: AddressInput | null
  defaultShippingAddress?: AddressInput | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  isActive?: boolean | null
  note?: string | null
  goal?: Goal | null
  dateOfBirth?: any | null
  gender?: Gender | null
  age?: number | null
  weight?: any | null
  height?: any | null
  freshchatId?: string | null
}

export interface IngenicoACS {
  bankAcsFormName?: string | null
  bankAcsHttpMethod?: string | null
  bankAcsParams?: (string | null)[] | null
  bankAcsUrl?: string | null
}

export interface IngenicoAuthentication {
  type?: string | null
  subType?: string | null
}

export interface IngenicoError {
  code?: string | null
  desc?: string | null
}

export interface IngenicoInstruction {
  id?: string | null
  statusCode?: string | null
  errorcode?: string | null
  errordesc?: string | null
}

export interface IngenicoOTP {
  initiator?: string | null
  message?: string | null
  numberOfDigit?: string | null
  target?: string | null
  type?: string | null
}

export interface IngenicoPaymentMethod {
  token?: string | null
  instrumentAliasName?: string | null
  instrumentToken?: string | null
  bankSelectionCode?: string | null
  aCS?: IngenicoACS | null
  oTP?: IngenicoOTP | null
  paymentTransaction?: IngenicoPaymentTransaction | null
  authentication?: IngenicoAuthentication | null
  error?: IngenicoError | null
}

export interface IngenicoPaymentTransaction {
  amount?: string | null
  accountNo?: string | null
  balanceAmount?: string | null
  bankReferenceIdentifier?: string | null
  dateTime?: string | null
  errorMessage?: string | null
  identifier?: string | null
  refundIdentifier?: string | null
  statusCode?: string | null
  statusMessage?: string | null
  instruction?: IngenicoInstruction | null
  reference?: string | null
}

export interface IngenicoResponse {
  merchantCode?: string | null
  merchantTransactionIdentifier?: string | null
  merchantTransactionRequestType?: string | null
  responseType?: string | null
  transactionState?: string | null
  merchantAdditionalDetails?: string | null
  paymentMethod?: IngenicoPaymentMethod | null
  stringResponse?: string | null
  error?: string | null
}

export interface JuspayAuthorizeInput {
  orderId: string
  status: string
  statusId: string
  signature: string
  signatureAlgorithm: string
}

export interface OptionResponseInput {
  key: string
  value: boolean
}

export interface PasswordInput {
  type: PasswordType
  value: string
}

export interface PaymentInput {
  gateway: GatewaysEnum
  token: string
  amount?: any | null
  billingAddress?: AddressInput | null
}

export interface ProductOrder {
  field: ProductOrderField
  direction: OrderDirection
}

export interface RazorpayAuthorizeInput {
  paymentId: string
  orderId: string
  signature: string
}

export interface UsernameInput {
  field: UsernameField
  value: string
}

//==============================================================
// END Enums and Input Objects
//==============================================================
