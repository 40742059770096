import { makeVar } from "@apollo/client"

export enum FilterSource {
  brand = "brand",
  category = "category",
  vegNonVeg = "vegNonVeg",
  sortBy = "sortBy",
}

export interface FilterProp {
  name: string
  id: string
  source: FilterSource
}

interface GlobalFilterInterface {
  selectedFilters: FilterProp[]
}

const initialState = {
  selectedFilters: [],
}

export const globalFilterState = makeVar<GlobalFilterInterface>(initialState)
