import { OverlayContext, OverlayType } from "@temp/components/Overlay"
import { OverlayContextInterface } from "@temp/components/Overlay/context"
import dynamic from "next/dynamic"
import React from "react"

const Address = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("@temp/components/OverlayManager/Address").then((mod) => mod.Address)
)
const AddressAdd = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("@temp/components/OverlayManager/AddressAdd/AddressAdd").then(
    (mod) => mod.AddressAdd
  )
)
const AddressEdit = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("@temp/components/OverlayManager/AddressEdit/AddressEdit").then(
    (mod) => mod.AddressEdit
  )
)
const EmiPlans = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("@temp/components/OverlayManager/EmiPlans").then((mod) => mod.EmiPlans)
)
const LoginRegister = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("@temp/components/OverlayManager/LoginRegister").then(
    (mod) => mod.LoginRegister
  )
)
const OnlinePaymentSuggestion = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("@temp/components/OverlayManager/OnlinePaymentSuggestion").then(
    (mod) => mod.OnlinePaymentSuggestion
  )
)

const CodConfirmationSuggestion = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("@temp/components/OverlayManager/CodConfirmationSuggestion").then(
    (mod) => mod.CodConfirmationSuggestion
  )
)

const VoucherCodeApplied = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("@temp/components/OverlayManager/VoucherCodeApplied").then(
    (mod) => mod.VoucherCodeApplied
  )
)
const VoucherCodes = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("@temp/components/OverlayManager/VoucherCodes").then(
    (mod) => mod.VoucherCodes
  )
)
const Cart = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("./Cart").then((mod) => mod.Cart)
)
const ListingFilterMobile = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("./ListingFilterMobile/ListingFilterMobile").then(
    (mod) => mod.ListingFilterMobile
  )
)
const Login = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("./Login").then((mod) => mod.Login)
)
const MobileNav = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("./MobileNav").then((mod) => mod.MobileNav)
)
const Modal = dynamic(() => import("./Modal"))
const Notification = dynamic(() => import("./Notification"))
const ProductVariant = dynamic(() => import("./ProductVariant"))
const SortVariantNew = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import("./SortVariantNew").then((mod) => mod.SortVariantNew)
)
const StoreLocatorModal = dynamic(() => import("./StoreLocatorModal"))
const Overlay = dynamic(() => import("@components/molecules/Overlay/Overlay"))
const PopupOrderTracking = dynamic(() => import("./OrderTracking"))
const ProductImageZoomOverlay = dynamic(() =>
  import("./ProductImageZoomOverlay").then((mod) => mod.ProductImageZoomOverlay)
)
const AffiliateHeaderNav = dynamic<{ overlay: OverlayContextInterface }>(() =>
  import(
    "@temp/components/OverlayManager/AffiliateHeaderNav/AffiliateHeaderNav"
  ).then((mod) => mod.AffiliateHeaderNav)
)

export const OverlayManager: React.FC = () => (
  <OverlayContext.Consumer>
    {(overlay) => {
      switch (overlay.type) {
        case OverlayType.modal:
          return <Modal overlay={overlay} />

        case OverlayType.message:
          return <Notification overlay={overlay} />

        case OverlayType.address:
          return <Address overlay={overlay} />

        case OverlayType.address_add:
          return <AddressAdd overlay={overlay} />

        case OverlayType.address_edit:
          return <AddressEdit overlay={overlay} />

        case OverlayType.cart:
          return <Cart overlay={overlay} />

        case OverlayType.login:
          return <Login overlay={overlay} />

        case OverlayType.register:
          return <LoginRegister overlay={overlay} />

        case OverlayType.sideNav:
          return <MobileNav overlay={overlay} />

        case OverlayType.variantchooser:
          return <ProductVariant overlay={overlay} />

        case OverlayType.mainMenuNav:
          return <Overlay context={overlay} />

        case OverlayType.emiPlans:
          return <EmiPlans overlay={overlay} />

        case OverlayType.onlinePaymentSuggestion:
          return <OnlinePaymentSuggestion overlay={overlay} />

        case OverlayType.CodConfirmationSuggestion:
          return <CodConfirmationSuggestion overlay={overlay} />

        case OverlayType.voucherCodes:
          return <VoucherCodes overlay={overlay} />

        case OverlayType.voucherCodeApplied:
          return <VoucherCodeApplied overlay={overlay} />

        case OverlayType.sortchoosernew:
          return <SortVariantNew overlay={overlay} />

        case OverlayType.filterchooser:
          return <ListingFilterMobile overlay={overlay} />

        case OverlayType.storelocator:
          return <StoreLocatorModal overlay={overlay} />

        case OverlayType.transit:
          return <PopupOrderTracking overlay={overlay} />

        case OverlayType.productImageZoomPopup:
          return <ProductImageZoomOverlay overlay={overlay} />

        case OverlayType.AffiliateHeaderNav:
          return <AffiliateHeaderNav overlay={overlay} />
        default:
          return null
      }
    }}
  </OverlayContext.Consumer>
)
