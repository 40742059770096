// import "../../scripts/wdyr"

import { ApolloProvider } from "@apollo/client"
import { useApollo } from "@app/staticProps/apolloClient"
import { NextContainerWithEB } from "@components/atoms/NextContainer"
import { NotificationTemplate } from "@components/atoms/NotificationTemplate"
import { Font } from "@components/organisms/Font"
import { GlobalStyle } from "@styles"
import { GLOBAL_MEDIA_QUERIES, PRODUCT_PAGE_MEDIA_QUERIES } from "@styles/constants"
import { ServiceWorkerProvider } from "@components/atoms/ServiceWorkerProvider"
import {
  GlobalMediaQueryResultInterface,
  sendMetricsToAnalytics,
} from "@temp/core/utils"
import {
  fifthTick,
  firstTick,
  fourthTick,
  isDocumentComplete,
  secondTick,
  sixthTick,
  thirdTick,
} from "@temp/localStates/documentComplete"
import { getMedia } from "@temp/localStates/media"
import isEqual from "lodash/isEqual"
import { AppProps, NextWebVitalsMetric } from "next/app"
import dynamic from "next/dynamic"
import Head from "next/head"
import { useRouter } from "next/router"
import Script from "next/script"
import { Provider } from "next-auth/client"
import React, { useEffect } from "react"
import { positions, Provider as AlertProvider } from "react-alert"
import { useMedia as reactUseMedia } from "react-media"
import { TawkTo } from "@temp/@next/globalStates/TawkTo/TawkTo"
import { unzlibSync } from "fflate"
import { PushNotificationOverlay } from "@temp/@next/components/molecules/PushNotificationOverlay/PushNotificationOverlay"
import { AffiliateCookie } from "../@next/components/molecules/AffiliateCookie"

const PushNotification = dynamic(() =>
  import("../@next/components/atoms/PushNotification/PushNotification").then(
    (mod) => mod.PushNotification
  )
)

const SetUserProfileGTM = dynamic(() =>
  import("../@next/components/atoms/SetUserProfileGTM/SetUserProfileGTM").then(
    (mod) => mod.SetUserProfileGTM
  )
)

const UserState = dynamic(
  () => import("@app/globalStates/User/UserGlobal").then((mod) => mod.UserState),
  { ssr: false }
)
const CheckoutGlobal = dynamic(
  () =>
    import("@temp/@next/globalStates/Checkout/CheckoutGlobal").then(
      (mod) => mod.CheckoutGlobal
    ),
  { ssr: false }
)
const ExternalScripts = dynamic(
  () =>
    import("@components/organisms/ExternalScripts").then(
      (mod) => mod.ExternalScripts
    ),
  { ssr: false }
)
const NotificationState = dynamic(
  () =>
    import("@temp/@next/globalStates/Notification/Notification").then(
      (mod) => mod.NotificationState
    ),
  { ssr: false }
)
const TopProgressBar = dynamic(
  () =>
    import("@components/molecules/TopProgressBar").then((mod) => mod.TopProgressBar),
  { ssr: false }
)

// though functions in js are hoisted but avoid using them before declaring them
// React is smart but you might fall into the temporal dead zone (js developer's nightmare)
const dispatchMetricEvent = (metric: NextWebVitalsMetric) => {
  const metricEvent = new CustomEvent("metric", { detail: { metric } })
  window.dispatchEvent(metricEvent)
}

export const reportWebVitals = (metric: NextWebVitalsMetric): void => {
  dispatchMetricEvent(metric)
}

const handleReadyStateChange = (): void => {
  if (document.readyState === "complete" && !isDocumentComplete()) {
    isDocumentComplete(true)
    setTimeout(() => {
      firstTick(true)
    }, 10_000)
    setTimeout(() => {
      secondTick(true)
    }, 20_000)
    setTimeout(() => {
      thirdTick(true)
    }, 30_000)
    setTimeout(() => {
      fourthTick(true)
    }, 40_000)
    setTimeout(() => {
      fifthTick(true)
    }, 50_000)
    setTimeout(() => {
      sixthTick(true)
    }, 60_000)
  }
}

// Next Performance Analytics
const onMetricUpdate = (event: any) => {
  sendMetricsToAnalytics(event.detail)
}

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  // Hooks
  const router = useRouter()
  let apolloState
  let componentProps: any = {}

  if ("dataIsCompressed" in pageProps && pageProps.dataIsCompressed) {
    const originalUnit8Array = Uint8Array.from(pageProps.data)
    const originalEncodedData = unzlibSync(originalUnit8Array)

    const decoder = new TextDecoder("utf-8")
    componentProps = JSON.parse(decoder.decode(originalEncodedData))
  } else {
    // eslint-disable-next-line react/jsx-props-no-spreading
    componentProps = { ...pageProps }
  }

  if ("initialApolloState" in componentProps) {
    apolloState = componentProps.initialApolloState
  }
  const apolloClient = useApollo(apolloState)
  const newMatches: GlobalMediaQueryResultInterface = reactUseMedia({
    queries: GLOBAL_MEDIA_QUERIES,
  })
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("metric", onMetricUpdate)
      return () => {
        window.removeEventListener("metric", onMetricUpdate)
      }
    }
  }, [])
  useEffect(() => {
    if (typeof window !== "undefined") {
      const oldMatches = getMedia()
      if (!isEqual(oldMatches, newMatches)) {
        getMedia(newMatches)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMatches.large, newMatches.medium, newMatches.small])

  const newProductMatches: GlobalMediaQueryResultInterface = reactUseMedia({
    queries: PRODUCT_PAGE_MEDIA_QUERIES,
  })
  useEffect(() => {
    if (typeof window !== "undefined") {
      const oldProductMatches = getMedia()
      if (!isEqual(oldProductMatches, newProductMatches)) {
        getMedia(newProductMatches)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newProductMatches.large, newProductMatches.medium, newProductMatches.small])

  useEffect(() => {
    document.addEventListener("readystatechange", handleReadyStateChange)
    return () =>
      document.removeEventListener("readystatechange", handleReadyStateChange)
  }, [])

  // check if intersection observer polyfill required
  let needIOPolyfill = true
  if (
    typeof window !== "undefined" &&
    "IntersectionObserver" in window &&
    "IntersectionObserverEntry" in window &&
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
  ) {
    needIOPolyfill = false
  }

  // Setting timeout for service worker
  const serviceWorkerTimeout =
    Number.parseInt(process.env.NEXT_PUBLIC_SERVICE_WORKER_TIMEOUT!, 10) || 60 * 1000
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#FFD400" />
        <title>Buyceps</title>
        {/* <link rel="canonical" href={`https://buyceps.com${router.asPath}`} /> */}
        {process.env.NEXT_PUBLIC_PLATFORM === "DESKTOP" ? (
          <link
            rel="alternate"
            media="only screen and (max-width: 640px)"
            href={`https://m.buyceps.com${router.asPath}`}
          />
        ) : undefined}
      </Head>
      <TopProgressBar />
      <Font />
      <ExternalScripts />
      {needIOPolyfill && (
        <Script
          src="https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver"
          id="polyfill"
          strategy="lazyOnload"
        />
      )}
      <GlobalStyle />
      <Provider session={componentProps.session}>
        <ServiceWorkerProvider timeout={serviceWorkerTimeout}>
          <AlertProvider
            template={NotificationTemplate}
            position={positions.BOTTOM_RIGHT}
            timeout={2500}
          >
            <NotificationState />
            <PushNotification />
            {/* <PushNotificationOverlay /> */}
            <UserState />
            <CheckoutGlobal />
            <SetUserProfileGTM />
            <ApolloProvider client={apolloClient}>
              <AffiliateCookie />
              {/* TODO: refactor NextContainer to remove cartPage, emptyHeader, forgotPassword, orderConfirmation props */}
              <NextContainerWithEB
                menuFacadeData={componentProps.menuFacadeData}
                cartPage={componentProps.cartPage}
                emptyHeader={componentProps.emptyHeader}
                forgotPassword={componentProps.forgotPassword}
                orderConfirmation={componentProps.orderConfirmation}
                reviewRating={componentProps.reviewRating}
                affiliate={componentProps.affiliate}
              >
                <Component
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...componentProps}
                  key={
                    router.asPath.includes("?")
                      ? router.asPath.slice(
                        0,
                        Math.max(0, router.asPath.indexOf("?"))
                      )
                      : router.asPath
                  }
                />
              </NextContainerWithEB>
            </ApolloProvider>
            <TawkTo />
          </AlertProvider>
        </ServiceWorkerProvider>
      </Provider>
    </>
  )
}

// eslint-disable-next-line import/no-default-export,import/no-unused-modules
export default App
