import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const FooterContainer = styled.div`
  background-color: ${C.v2Theme.fontColors.black};
  padding: 3.5625rem 0rem 1.9375rem 0rem;

  @media (max-width: 540px) {
    padding: 12.136vw 4.854vw;
  }
  @media (max-width: 992px) {
    padding: 3.5625rem 0rem 1.9375rem 1rem;
  }
`
FooterContainer.displayName = "S.FooterContainer"

export const FooterDivider = styled.div`
  background-color: #3c3c40;
  height: 1px;
  width: 100%;
  border: none;
`
FooterDivider.displayName = "S.FooterDivider"
