import { FontSize } from "@components/atoms/Input/types"
import { DefaultTheme, styled } from "@styles"
import * as C from "@temp/@next/globalStyles/constants"

type WrapperProps = {
  active: boolean
  error: boolean
  disabled: boolean
  theme: DefaultTheme
  border?: boolean
  darkTheme: boolean
  onlyBottomBorder?: boolean
}

const getEdgeColor = (
  { active, error, disabled, darkTheme }: WrapperProps,
  hovered = false
) => {
  if (darkTheme) {
    return C.colorGrayishBlue
  }
  if (disabled) {
    return C.colorGray3
  }

  if (error) {
    return C.colorAlert
  }

  if (hovered) {
    return C.gray1
  }
  return active ? C.gray1 : C.gray2
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  ${(props) =>
    props.border &&
    `
    border: 1px solid ${getEdgeColor(props)};
  `};
  ${(props) =>
    props.onlyBottomBorder &&
    `
    border-bottom: 1px solid ${getEdgeColor(props)};
  `};
  border-radius: ${(props) => (props.onlyBottomBorder ? "0px" : "4px")};
  color: ${(props) => getEdgeColor(props)};
  transition: all 0.3s ease;
  height: 100%;
  width: 100%;

  &:hover {
    color: ${(props) => getEdgeColor(props, true)};
    border-color: ${(props) => getEdgeColor(props, true)};
    ${(props) =>
      props.darkTheme &&
      `
        opacity: 0.6;
      `}
  }
`

export const Content = styled.span`
  display: flex;
  align-items: center;
`

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Input = styled.input<{
  placeHolderFontSize?: FontSize
  darkTheme: boolean
  labelSize: number
  padding?: string
}>`
  ${({ padding }) => `padding : ${padding || "0.8rem 1rem"};`}
  margin: 0;
  border: none;
  width: 100%;
  height: 100%;
  font-family: ${C.baseFontFamily};
  font-size: ${C.fontSizeParagraph1};
  font-weight: ${C.normalFontWeight};
  color: ${({ darkTheme }) => (darkTheme ? C.colorSilver : C.theme.baseFontColor)};
  ${(props) =>
    props.labelSize > 25 &&
    `
   padding-top: 1.8rem;
  `}
  @media (max-width: ${C.smallScreen}px) {
    padding-top: 0.8rem;
  }
  outline: none;
  background-color: transparent;

  ::placeholder {
    font-size: ${(props) =>
      props.placeHolderFontSize
        ? `${props.placeHolderFontSize}rem`
        : C.theme.baseSecondaryFontColor};
    font-family: ${C.baseFontFamily};
    font-weight: ${C.normalFontWeight};
    color: ${C.theme.baseSecondaryFontColor};
  }

  ::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  [type="number"] {
    appearance: textfield;
  }
`

export const Label = styled.label<{
  active: boolean
  darkTheme: boolean
  textArea: boolean
  forgotPass: boolean
}>`
  position: absolute;
  left: 0.625rem;
  padding: 0 0.625rem;
  background-color: ${C.theme.white};
  font-family: ${C.baseFontFamily};
  font-size: 14px;
  font-weight: ${C.v2Theme.fontWeights.regular};
  color: #7687a1;
  top: -0.625rem;
  pointer-events: none;
  ${(props) =>
    props.forgotPass &&
    `
    background: linear-gradient(to bottom,#edf0f5,#f1f6fe); 
    `}

  ${(props) =>
    props.darkTheme &&
    `
      color: ${C.colorGray1};
    `}
`

export const InputArea = styled.textarea<{
  placeHolderFontSize?: FontSize
  darkTheme: boolean
  labelSize: number
}>`
  resize: none;
  width: 100%;
  padding: 0.8rem 1rem;
  margin: 0;
  border: none;
  height: ${C.spacer * 9}rem;
  font-family: ${C.baseFontFamily};
  font-size: ${C.fontSizeParagraph1};
  font-weight: ${C.normalFontWeight};
  color: ${({ darkTheme }) => (darkTheme ? C.colorSilver : C.theme.baseFontColor)};
  ${(props) =>
    props.labelSize > 25 &&
    `
     padding-top: 1.8rem;
    `}
  outline: none;
  background-color: transparent;

  ::placeholder {
    font-size: ${(props) =>
      props.placeHolderFontSize
        ? `${props.placeHolderFontSize}rem`
        : C.fontSizeParagraph1};
    font-family: ${C.baseFontFamily};
    font-weight: ${C.normalFontWeight};
    color: ${C.theme.baseSecondaryFontColor};
  }

  ::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  [type="number"] {
    appearance: textfield;
  }
`
InputArea.displayName = "S.InputArea"
