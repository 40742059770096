import { IconName } from "@components/atoms/Icon/types"
import React from "react"

export type ButtonStyle =
  | "primary"
  | "secondary"
  | "alternate"
  | "gray"
  | "social"
  | "addAddress"
  | "grayWithoutIcon"
  | "notfound"
  | "previous"
  | "buyNow"
  | "grayisBlue"
  | "rateDelivery"
  | "rateProduct"
  | "unboxingWithIcon"

export enum ButtonCurve {
  normal = "normal",
  none = "none",
  small = "small",
  large = "large",
}

export enum ButtonSize {
  large = "large",
  medium = "medium",
  small = "small",
  x_small = "x_small",
  cod = "cod",
  codSecond = "codSecond",
}

export enum FontSize {
  header = 1.25, // 20px
  subheader = 1.125, // 18px
  subtitle = 1, // 16px
  paragraph1 = 0.875, // 14px
  paragraph2 = 0.8125, // 13px
  caption = 0.75, // 12px
}

export interface ButtonStyledProps extends React.HTMLAttributes<HTMLButtonElement> {
  buttonStyle?: ButtonStyle
  btnRef?: React.RefObject<HTMLButtonElement>
  disabled?: boolean
  buttonCurve?: ButtonCurve
  iconName?: IconName
  buttonSize?: ButtonSize
  type?: string
  fontSize?: FontSize
  fontWeight?: string
  buttonWidth?: string
  buttonTopBottomMargin?: string
  textTransform?: string
}
