import React, { useCallback, useState } from "react"

import * as S from "./styles"
import { IProps } from "./types"

export const Input: React.FC<IProps> = ({
  onBlur,
  onFocus,
  contentLeft = null,
  contentRight = null,
  error = false,
  disabled = false,
  placeholder = "",
  label = "",
  value,
  name,
  border = true,
  onChange,
  placeholderFontSize,
  darkTheme = false,
  textArea = false,
  forgotPass = false,
  onlyBorderBottom = false,
  padding,
  ...props
}: IProps) => {
  const [active, setActive] = useState(false)

  const handleFocus = useCallback(
    (e) => {
      setActive(true)
      if (onFocus) {
        onFocus(e)
      }
    },
    [setActive, onFocus]
  )
  const handleBlur = useCallback(
    (e) => {
      setActive(false)
      if (onBlur) {
        onBlur(e)
      }
    },
    [setActive, onBlur]
  )
  return (
    <S.Wrapper
      active={active}
      error={error}
      disabled={disabled}
      border={border}
      darkTheme={darkTheme}
      onlyBottomBorder={onlyBorderBottom}
    >
      {contentLeft && <S.Content>{contentLeft}</S.Content>}
      <S.InputWrapper>
        {textArea ? (
          <S.InputArea
            {...props}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            onChange={onChange}
            name={name}
            placeholder={placeholder}
            placeHolderFontSize={placeholderFontSize}
            darkTheme={darkTheme}
            labelSize={label.length}
          />
        ) : (
          <S.Input
            {...props}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            onChange={onChange}
            name={name}
            placeholder={placeholder}
            placeHolderFontSize={placeholderFontSize}
            darkTheme={darkTheme}
            labelSize={label.length}
            padding={padding}
          />
        )}
        {label && (
          <S.Label
            active={active || !!value}
            darkTheme={darkTheme}
            textArea={textArea}
            forgotPass={forgotPass}
          >
            {label}
          </S.Label>
        )}
      </S.InputWrapper>
      {contentRight && <S.Content>{contentRight}</S.Content>}
    </S.Wrapper>
  )
}
