import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import { userGlobalState } from "@app/globalStates/User/userGlobalState"
import { initializeApollo } from "@app/staticProps/apolloClient"
import { ProductDetails_product } from "@app/staticProps/product/types/ProductDetails"
import { MenuFacadeData } from "@core/types"
import { BrandsFiltersQuery } from "@temp/@next/components/molecules/BrandFilters/queries"
import {
  brandsFilterQuery,
  brandsFilterQuery_brands_edges_node,
} from "@temp/@next/components/molecules/BrandFilters/types/brandsFilterQuery"
import { categoryFiltersQuery } from "@temp/@next/components/molecules/CategoryFilters/queries"
import {
  CategoryFiltersQuery,
  CategoryFiltersQuery_categories_edges_node,
} from "@temp/@next/components/molecules/CategoryFilters/types/CategoryFiltersQuery"
import { Checkout } from "@temp/@next/globalStates/Checkout/types/Checkout"
import {
  FilterProp,
  FilterSource,
  globalFilterState,
} from "@temp/@next/globalStates/GlobalFilter/GlobalFilterState"
import { mainMenu } from "@temp/components/MainMenu/queries"
import { LogAction } from "@temp/core/tracking/queries"
import { lang } from "@temp/translations"
import { Base64 } from "js-base64"
import { uniqBy } from "lodash"
// eslint-disable-next-line import/no-extraneous-dependencies

import {
  AddressInput,
  OrderDirection,
  ProductOrderField,
} from "../../types/globalTypes"
import {
  MainMenu,
  MainMenu_shop_navigation_main_items_children,
} from "../components/MainMenu/types/MainMenu"
import {
  ADD_PAYMENT_INFO,
  ADD_TO_CART,
  INITIATE_CHECKOUT,
  NEAR_EXPIRY_QUERY_PARAM,
  PURCHASE_EVENT,
  SHIPPING_METHOD_CASH_ON_DELIVERY,
  SHIPPING_METHOD_OUTSIDE,
  SHIPPING_METHOD_OUTSIDE_DELIVERY_COD,
  VARIANT_QUERY_PARAM,
} from "../constants"
import { PRODUCTS_PER_PAGE } from "./config"

export const slugify = (text: string | number): string =>
  text
    ?.toString()
    ?.toLowerCase()
    ?.trim()
    ?.replace(/\s+/g, "-") // Replace spaces with -
    ?.replace(/&/g, "-and-") // Replace & with 'and'
    ?.replace(/[^\w-]+/g, "") // Remove all non-word chars
    ?.replace(/--+/g, "-") // Replace multiple - with single -

export const getDBIdFromGraphqlId = (graphqlId: string, schema?: string): number => {
  // This is temporary solution, we will use slugs in the future
  const rawId = Base64.decode(graphqlId)
  const regexp = /(\w+):(\d+)/
  const [, expectedSchema, id] = regexp.exec(rawId)
  if (schema && schema !== expectedSchema) {
    throw new Error("Schema is not correct")
  }
  return Number.parseInt(id, 10)
}

export const getGraphqlIdFromDBId = (id: string, schema: string): string => {
  if (id) {
    const filteredId = id?.split("#")[0];
    // This is temporary solution, we will use slugs in the future
    const encodedId = Base64.encode(`${schema}:${filteredId}`);
    return encodedId;
  }
  return "";
}


export const getScreenSize = (matches: GlobalMediaQueryResultInterface) =>
  matches.small ? "mobile" : matches.large ? "desktop" : "pad"

export const generateProductUrl = (
  id: string,
  name: string,
  variant?: string,
  attributes?: string,
  nearExpiry?: string
) =>
  variant && attributes
    ? `/product/${slugify(name)}/${getDBIdFromGraphqlId(
      id,
      "Product"
    )}?${VARIANT_QUERY_PARAM}=${slugify(attributes)}-${getDBIdFromGraphqlId(
      variant,
      "ProductVariant"
    )}&${NEAR_EXPIRY_QUERY_PARAM}=${nearExpiry}`
    : `/product/${slugify(name)}/${getDBIdFromGraphqlId(id, "Product")}/`

export const generateComboProductUrl = (
  id: string,
  name: string,
  variant?: string
) =>
  variant
    ? `/combo-product/${slugify(name)}/${getDBIdFromGraphqlId(
      id,
      "ComboProduct"
    )}?variation=${getDBIdFromGraphqlId(variant, "ComboProductVariation")}`
    : `/combo-product/${slugify(name)}/${getDBIdFromGraphqlId(id, "ComboProduct")}/`

export const generateJusPayPaymentProcessorUrl = (
  paymentGatewayUrl: string,
  token: string
) => `/payments/process-payment/?gateway=${paymentGatewayUrl}&token=${token}`

export const generateRazorPayPaymentProcessorUrl = (
  razorPayOrderId: string,
  token: string
) => `/payments/process-payment/?razorOrderId=${razorPayOrderId}&token=${token}`

export const generateLoginRegisterUrl = (redirectUrl: string) => {
  if (redirectUrl) {
    return `/login-register/?redirect=${redirectUrl}`
  }
  return `/login-register/`
}

const generateCategoryUrl = (id: string, name: string) =>
  `/category/${slugify(name)}/${getDBIdFromGraphqlId(id, "Category")}/`

export const generatePageUrl = (slug: string) => `/page/${slug}/`

export const generateBrandUrl = (id: string, name: string) =>
  `/brand/${slugify(name)}/${getDBIdFromGraphqlId(id, "Brand")}/`

interface AttributeDict {
  [attributeSlug: string]: string[]
}

export const convertToAttributeScalar = (attributes: AttributeDict) => {
  const attributesObject = []
  for (const key in attributes) {
    if (Object.prototype.hasOwnProperty.call(attributes, key)) {
      for (const attribute of attributes[key]) {
        attributesObject.push(`${key}:${attribute}`)
      }
    }
  }
  return attributesObject
}

export const convertSortByFromString = (sortBy: string | undefined) => {
  if (!sortBy) {
    return null
  }
  const direction = sortBy.startsWith("-") ? OrderDirection.DESC : OrderDirection.ASC

  let field
  switch (sortBy.replace(/^-/, "")) {
    case "name":
      field = ProductOrderField.NAME
      break

    case "price":
      field = ProductOrderField.PRICE
      break

    case "updated_at":
      field = ProductOrderField.DATE
      break

    default:
      return null
  }
  // eslint-disable-next-line consistent-return
  return { field, direction }
}

export const maybe = <T>(exp: () => T, d?: T) => {
  try {
    const result = exp()
    return result === undefined ? d : result
  } catch {
    return d
  }
}

const arrayToMap = (array: any[], key: string) => {
  const resultMap: any = {}
  for (const object of array) {
    resultMap[object[key]] = object
  }
  return resultMap
}

export const getOrderStatus = (status: string) => {
  switch (status) {
    case "FULFILLED": {
      return "Ready to be shipped"
    }
    default: {
      return "Order Received"
    }
  }
}

export const getShipmentStatus = (status: number) => {
  switch (status) {
    case 1: {
      return "Awb Assigned"
    }
    case 2: {
      return "Label Generated"
    }
    case 3: {
      return "Pickup Scheduled"
    }
    case 4: {
      return "Pickup Queued"
    }
    case 5: {
      return "Manifest Generated"
    }
    case 6: {
      return "Shipped"
    }
    case 7: {
      return "Delivered"
    }
    case 8: {
      return "Cancelled"
    }
    case 9: {
      return "RTO Initiated"
    }
    case 10: {
      return "RTO Delivered"
    }
    case 11: {
      return "Pending"
    }
    case 12: {
      return "Lost"
    }
    case 13: {
      return "Pickup Error"
    }
    case 14: {
      return "RTO Acknowledged"
    }
    case 15: {
      return "Pickup Rescheduled"
    }
    case 16: {
      return "Cancellation Requested"
    }
    case 17: {
      return "Out For Delivery"
    }
    case 18: {
      return "In Transit"
    }
    case 19: {
      return "Out For Pickup"
    }
    case 20: {
      return "Pickup Exception"
    }
    case 21: {
      return "Undelivered"
    }
    case 22: {
      return "Delayed"
    }
    default:
      return false
  }
}

export const populateProductPageBreadCrumbs = (product: ProductDetails_product) => {
  if (product.category.ancestors!.edges.length > 0) {
    return [
      {
        link: generateCategoryUrl(
          product.category.ancestors!.edges[0].node.id,
          product.category.ancestors!.edges[0].node.name
        ),
        value: product.category.ancestors!.edges[0].node.name,
      },
      {
        link: generateCategoryUrl(product.category.id, product.category.name),
        value: product.category.name,
      },
      {
        link: generateProductUrl(product.id, product.name),
        value: product.name,
      },
    ]
  }
  return [
    {
      link: generateCategoryUrl(product.category.id, product.category.name),
      value: product.category.name,
    },
    {
      link: generateProductUrl(product.id, product.name),
      value: product.name,
    },
  ]
}

export const alphabetalizeMenuItems = (
  items: MainMenu_shop_navigation_main_items_children[],
  numberOfDivision: number
) => {
  const resultArray = []
  const featuredArray = []
  let alphabetToContinue = ""

  let subArray: MainMenu_shop_navigation_main_items_children[] = []
  for (const item of items) {
    const updatedItem = { ...item }
    updatedItem.style = "normal"
    if (updatedItem.featured) {
      featuredArray.push(updatedItem)
    }
    if (subArray.length < numberOfDivision) {
      subArray.push(updatedItem)
      alphabetToContinue = updatedItem.name[0]
    } else if (updatedItem.name[0] === alphabetToContinue) {
      subArray.push(updatedItem)
    } else {
      alphabetToContinue = ""
      resultArray.push(subArray)
      subArray = [updatedItem]
    }
  }

  // Pushing the last array
  resultArray.push(subArray)

  return { resultArray, featuredArray }
}

export const abbreviate = (value: string) =>
  /\b([A-Z])/g.test(value) ? value.match(/\b([A-Z])/g).join("") : value

const getCookie = (cookieName: string): string => {
  const name = `${cookieName}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookies = decodedCookie.split(";")
  for (let cookie of cookies) {
    while (cookie.charAt(0) === " ") {
      cookie = cookie.slice(1)
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.slice(name.length, cookie.length)
    }
  }
  return ""
}

export const uuidV4 = (): string =>
  (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: number) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(
      16
    )
  )

const TagManagerEvent = async (
  { eventName = "", eventValue = {} },
  eventId = "",
  userId = ""
) => {
  const TagManager = (await import("react-gtm-module")).default
  if (eventId.length > 0) {
    TagManager.dataLayer({
      dataLayer: {
        eventId,
        external_id: userId,
      },
    })
  } else {
  if (eventValue?.ecommerce !== undefined) {
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: undefined,
        },
      })
    }
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...eventValue,
      },
    })
  }
}

export const trackEvent = async ({
  eventName = "",
  eventValue = {},
  sendToPixel = false,
  sendToDataLayer = false,
}): Promise<void> => {
  if (
    process.env.NEXT_PUBLIC_STOREFRONT_ENV === "PRODUCTION" ||
    process.env.NEXT_PUBLIC_TRACK_EVENTS === "TRUE"
  ) {
    const eventID = uuidV4()
    let userId
    const { user } = userGlobalState()
    if (user) {
      userId = getDBIdFromGraphqlId(user.id, "User").toString()
    }
    if (sendToPixel === true) {
      let sendConversionAPIEvent = false
      if (
        [PURCHASE_EVENT, INITIATE_CHECKOUT, ADD_TO_CART, ADD_PAYMENT_INFO].includes(
          eventName
        )
      ) {
        sendConversionAPIEvent = true
      }

      await TagManagerEvent({}, eventID, userId)

      if (sendConversionAPIEvent) {
        const clientInfo = {
          fbc: getCookie("_fbc"),
          fbp: getCookie("_fbp"),
          sourceUrl: window.location.href,
        }
        const apolloClient: ApolloClient<NormalizedCacheObject> = initializeApollo()
        apolloClient.mutate({
          mutation: LogAction,
          variables: {
            clientInfo,
            event: JSON.stringify(eventValue),
            eventId: eventID,
            eventName,
          },
        })
      }
    }

    if (sendToDataLayer) {
      await TagManagerEvent({
        eventName,
        eventValue,
      })
    }
  }
}

export const getAddressFormErrors = (input: AddressInput) => {
  const errors = []
  if (!input.streetAddress1) {
    errors.push({
      field: "streetAddress1",
      message: "Please enter an address",
    })
  }
  if (!input.firstName) {
    errors.push({
      field: "firstName",
      message: "Please enter first name",
    })
  }
  if (!input.lastName) {
    errors.push({
      field: "lastName",
      message: "Please enter last name",
    })
  }
  if (!input.phone) {
    errors.push({
      field: "phone",
      message: "Please enter a phone number",
    })
  }
  if (!input.email) {
    errors.push({
      field: "email",
      message: "Please enter an email address",
    })
  }
  if (!input.postalCode) {
    errors.push({
      field: "postalCode",
      message: "Please enter a zip code",
    })
  }
  if (!input.city) {
    errors.push({
      field: "city",
      message: "Please enter a city",
    })
  }
  if (!input.countryArea) {
    errors.push({
      field: "countryArea",
      message: "Please enter a state",
    })
  }
  return errors
}

export const getShippingCost = (checkout: Checkout) => {
  if (checkout && checkout.codShippingPrice) {
    return checkout.codShippingPrice.gross.amount
  }
  // if (checkout && checkout.availableShippingMethods && checkout.shippingMethod) {
  //   const shippingMethodMap = arrayToMap(checkout.availableShippingMethods, "name")
  //   if (
  //     checkout.shippingMethod.name === SHIPPING_METHOD_OUTSIDE ||
  //     checkout.shippingMethod.name === SHIPPING_METHOD_OUTSIDE_DELIVERY_COD
  //   ) {
  //     return (
  //       shippingMethodMap[SHIPPING_METHOD_OUTSIDE_DELIVERY_COD].price.amount -
  //       shippingMethodMap[SHIPPING_METHOD_OUTSIDE].price.amount
  //     )
  //   }
  //   return shippingMethodMap[SHIPPING_METHOD_CASH_ON_DELIVERY].price.amount
  // }
  return 0
}

export const getShippingCostLocalized = (checkout: Checkout, currency: string) =>
  new Intl.NumberFormat(lang, {
    currency,
    style: "currency",
  }).format(getShippingCost(checkout))

export const getLocalizedAmount = (currency: string, cost: number) =>
  new Intl.NumberFormat(lang, {
    currency,
    style: "currency",
  }).format(cost)

export const getLocalizedWholeNumberAmount = (currency: string, cost: number) =>
  new Intl.NumberFormat(lang, {
    currency,
    style: "currency",
  })
    .format(cost)
    .replace(/\D00(?=\D*$)/, "")

export const sanitizePhoneNumber = (phone: string) => phone.replace("+91", "")

export const monthNames = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]

// next brand
export const getNextAttributesFromQs = (query) => {
  let queryObject = {}
  if (query["supplement-vegetarian-non-vegetarian"]) {
    queryObject = {
      "supplement-vegetarian-non-vegetarian": query[
        "supplement-vegetarian-non-vegetarian"
      ]
        .toString()
        .split(","),
      ...queryObject,
    }
  }

  return queryObject
}
export interface GlobalMediaQueryResultInterface {
  large: boolean
  medium: boolean
  small: boolean
}

export const capitalizeFirstLetter = (string_: string): string =>
  string_.charAt(0).toUpperCase() + string_.slice(1)

export const validateField = (value: string, type: string) => {
  let valid = true
  let sanitizedValue = value
  let message = ""

  if (!value) {
    valid = false
    message = "Please enter a valid phone number"
    return { sanitizedValue, valid, message }
  }

  switch (type) {
    case "phone": {
      if (sanitizedValue.startsWith("+91")) {
        sanitizedValue = sanitizedValue.slice(3, sanitizedValue.length)
      }
      // this regex chain removes spaces, leading zero, any alphabets
      sanitizedValue = sanitizedValue
        .trim()
        .replace(/\s/g, "")
        .replace(/^0+/, "")
        .replace(/\D/g, "")
      if (sanitizedValue && sanitizedValue.length !== 10) {
        valid = false
        message = "Please enter a valid phone number"
      }
      sanitizedValue = sanitizedValue.slice(0, 10)
      break
    }
    default:
      valid = false
  }

  return { newValue: sanitizedValue, valid, message }
}

export const getCardDimensions = () => {
  // constants
  const HEIGHT = 320
  const IMG_HEIGHT = 144

  // defaults
  return { height: HEIGHT, imgHeight: IMG_HEIGHT }
}

export const getMenuFacadeData = async (): Promise<MenuFacadeData[]> => {
  const apolloClient: ApolloClient<NormalizedCacheObject> = initializeApollo()
  const menuData = await apolloClient.query<MainMenu>({
    query: mainMenu,
  })

  if (menuData && menuData.data) {
    let loaderText
    const { data } = menuData
    if (data && data.shop?.navigation?.main?.items) {
      const items = data.shop?.navigation?.main?.items
      if (items && items.length > 0) {
        loaderText = items.map((item) => ({
          name: item!.name.toLowerCase(),
          style: "header",
          id: item!.id,
          finalUrl: item!.finalUrl!,
          children: item!.children!.length > 0 ? [""] : [],
        }))
        return loaderText
      }
    }
  }
  return []
}

export const sendMetricsToAnalytics = (metric) => {
  if (metric && metric.metric) {
    const coreWebVitalsData = {
      webVitalsMeasurement: {
        name: metric.metric.name,
        id: metric.metric.id,
        value: metric.metric.value,
        valueRounded: Math.round(
          metric.metric.name === "CLS"
            ? metric.metric.value * 1000
            : metric.metric.value
        ),
        label: metric.metric.label,
      },
    }

    trackEvent({
      eventName: "coreWebVitals",
      eventValue: coreWebVitalsData,
      sendToPixel: false,
      sendToDataLayer: true,
    })
  }
}

// Note: This doesn't work in Firefox 65 which supports displaying webp,
// but not creating a webp data url from a canvas element
export const isWebPSupported = (): boolean => {
  const element = document.createElement("canvas")

  if (element.getContext && element.getContext("2d")) {
    // was able or not to get WebP representation
    return element.toDataURL("image/webp").indexOf("data:image/webp") === 0
  }
  // very old browser like IE 8, canvas not supported
  return false
}

// Get the current domain
export const getOrigin = () => {
  // The URL object needs to be provided with atleast a protocol and host to initialize
  const url = new URL("https://example.com")
  if (process.env.NEXT_PUBLIC_PLATFORM === "DESKTOP") {
    url.hostname = (process.env.NEXT_PUBLIC_DESKTOP_DOMAIN ||
      process.env.NEXT_PUBLIC_VERCEL_URL)!
    url.protocol = "https:"
    // In development mode set port and set protocol to http:
    if (process.env.NEXT_PUBLIC_STOREFRONT_ENV === "DEVELOPMENT") {
      url.port = process.env.NEXT_PUBLIC_DESKTOP_PORT!
      url.protocol = "http:"
    }
  } else {
    url.hostname = (process.env.NEXT_PUBLIC_MOBILE_DOMAIN ||
      process.env.NEXT_PUBLIC_VERCEL_URL)!
    url.protocol = "https:"
    // In development mode set port and set protocol to http:
    if (process.env.NEXT_PUBLIC_STOREFRONT_ENV === "DEVELOPMENT") {
      url.port = process.env.NEXT_PUBLIC_MOBILE_PORT!
      url.protocol = "http:"
    }
  }
  return url.href
}

const fileExtensionObject: { [key: string]: string } = {
  ".jpeg": "00",
  ".jpg": "01",
  ".png": "02",
  ".svg": "03",
  ".tiff": "04",
  ".webp": "05",
  ".gif": "06",
  ".jfif": "07",
  ".bmp": "08",
  ".ico": "09",
  ".icns": "10",
  ".pct": "11",
}

// Custom loader for images
export const customLoader = ({
  src,
  width,
  quality,
}: {
  src: string
  width: number
  quality: number
}) => {
  let origin = getOrigin()
  if (typeof window !== "undefined") {
    origin = window.location.origin
  }
  const newSource = src.split("#")[0]
  const removeBg = src.split("#")[1]
  const url = new URL(newSource, origin)
  const addPadding = 0

  // Break the pathname into slugs
  const paths = url.pathname.split("/").filter((path) => path.length > 0)
  // Get the file name of the image
  let fileName = paths[paths.length - 1]

  // Break the file name into image name and extension
  let [imageName] = fileName.split(/\.(?=[^.]+$)/)
  const fileExtension = fileName.slice(fileName.lastIndexOf("."))
  const allCaps = fileExtension === fileExtension.toUpperCase() ? "1" : "0"
  // Remove bg is kept 0 for testing
  const filter = `${removeBg}${fileExtensionObject[fileExtension.toLowerCase()]
    }${allCaps}${addPadding}${quality || 75}${width}`
  imageName = [imageName, filter].join("_")

  // Change the extension to webp (if supported by the browser?)
  // extension = "webp"

  // Generate the updated file name
  fileName = `${imageName}.webp`

  // Generate the updated pathname
  paths[paths.length - 1] = fileName
  url.pathname = paths.join("/")

  // Return the url with updated hostname
  if (process.env.NEXT_PUBLIC_STOREFRONT_ENV === "PRODUCTION") {
    url.hostname = "images.buyceps.com"
    url.protocol = "https:"
  } else {
    url.hostname = "dev-images.buyceps.com"
    url.protocol = "https:"
    // url.hostname = "localhost"
    // url.port = "5000"
  }
  return url.href
}

export const getCategoryFilterNameFromId = (
  id: string,
  filterArray: CategoryFiltersQuery_categories_edges_node[]
) => {
  let name = ""
  for (const filter of filterArray) {
    if (filter.id === id) {
      name = filter.name
    } else if (
      filter.children?.edges.some((childFilter) => childFilter.node.id === id)
    ) {
      name = filter.children?.edges.find((childFilter) => childFilter.node.id === id)
        ?.node.name!
    }
  }

  return name
}

export const getBrandFilterNameFromId = (
  id: string,
  filterArray: brandsFilterQuery_brands_edges_node[]
) => {
  let name = ""
  for (const filter of filterArray) {
    if (filter.id === id) {
      name = filter.name
    }
  }
  return name
}

export const getRenderedFilters = (
  id: string,
  name: string,
  source: FilterSource
): FilterProp[] => {
  const renderedFilter = globalFilterState().selectedFilters.some(
    (filter) => filter.id === id
  )
    ? globalFilterState().selectedFilters.filter((filter) => filter.id !== id)
    : [{ id, name, source }, ...globalFilterState().selectedFilters]

  return renderedFilter
}

export const getInitialFilterData = async (
  id: string,
  source: FilterSource
): Promise<FilterProp | null> => {
  const apolloClient: ApolloClient<NormalizedCacheObject> = initializeApollo()
  if (source === FilterSource.category) {
    const categoryData = await apolloClient.query<CategoryFiltersQuery>({
      query: categoryFiltersQuery,
    })
    const categoryFilters = categoryData.data?.categories?.edges
      .filter((edge) => edge.node.children?.edges.length! > 0)
      .map((category) => category.node)
    return {
      id,
      name: getCategoryFilterNameFromId(id, categoryFilters!),
      source,
    }
  }

  if (source === FilterSource.brand) {
    const brandData = await apolloClient.query<brandsFilterQuery>({
      query: BrandsFiltersQuery,
    })
    const brandFilters = uniqBy(
      brandData.data?.brands?.edges.map((brand) => brand.node),
      "id"
    )
    return {
      id,
      name: getBrandFilterNameFromId(id, brandFilters!),
      source,
    }
  }

  return null
}

export const getListingVariables = (query, pathInfo) => {
  const pageNo = Number.parseInt(pathInfo.pageNo, 10)
  const filteredId = pathInfo.id;
  let sortBy = null
  let after = ""
  if (pageNo > 1) {
    const offset = PRODUCTS_PER_PAGE * (pageNo - 1) - 1
    after = getGraphqlIdFromDBId(offset.toString(), "arrayconnection")
  }

  // If pageNo is not NaN, then it is a paginated page
  if (pathInfo.paginated) {
    sortBy = {
      field: "NAME",
      direction: "ASC",
    }
  }
  const attributes = getNextAttributesFromQs(query)
  if (query.SortBy) {
    sortBy = convertSortByFromString(query.SortBy?.toString())
  }
  const variables = {
    after,
    categories: [] as any,
    pageSize: PRODUCTS_PER_PAGE as number,
    attributes: attributes ? convertToAttributeScalar(attributes) : [],
    brands: [] as any,
    priceGte: null,
    priceLte: null,
    sortBy,
    collections: [] as any,
    query: "",
  }
  const id =
    pathInfo.typeSlug !== "brand-category"
      ? getGraphqlIdFromDBId(filteredId, capitalizeFirstLetter(pathInfo.typeSlug))
      : ""

  if (pathInfo.typeSlug === "brand-category") {
    variables.brands = getGraphqlIdFromDBId(filteredId, "Brand")
    variables.categories = getGraphqlIdFromDBId(pathInfo.seondaryId, "Category")
  }
  const queryCategories: string[] = []
  const queryBrands: string[] = []

  if (pathInfo.typeSlug === "brand") {
    variables.brands = id
  }
  if (pathInfo.typeSlug === "brand-category") {
    queryBrands.push(getGraphqlIdFromDBId(filteredId, "Brand"))
    queryCategories.push(getGraphqlIdFromDBId(pathInfo.seondaryId, "Category"))
  }
  if (pathInfo.typeSlug === "category") {
    variables.categories = id
  }
  if (pathInfo.typeSlug === "collection") {
    variables.collections = id
  }
  if (query.categories) {
    const decodedURI = decodeURIComponent(query.categories.toString()).split(",")
    for (const category of decodedURI) {
      queryCategories.push(getGraphqlIdFromDBId(category, "Category"))
    }
    if (pathInfo.typeSlug === "category") {
      queryCategories.push(id)
    }
    variables.categories = queryCategories
  }
  if (query.brands) {
    const decodedURI = decodeURIComponent(query.brands.toString()).split(",")
    for (const brand of decodedURI) {
      queryBrands.push(getGraphqlIdFromDBId(brand, "Brand"))
    }
    if (pathInfo.typeSlug === "brand") {
      queryBrands.push(id)
    }
    variables.brands = queryBrands
  }

  if (query.q) {
    variables.query = query.q.toString()
  }
  return variables
}

export const isProductPage = (pathname: string) =>
  pathname === "/combo-product/[name]/[id]" || pathname === "/product/[name]/[id]"

export const truncateString = (string: string, limits: number) =>
  string?.length > limits
    ? `${string?.slice(0, Math.max(0, limits - 1))}...`
    : string

export const numberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
