import { makeVar } from "@apollo/client"

export const isDocumentComplete = makeVar(false)

export const firstTick = makeVar(false)

export const secondTick = makeVar(false)

export const thirdTick = makeVar(false)

export const fourthTick = makeVar(false)

export const fifthTick = makeVar(false)

export const sixthTick = makeVar(false)
