import { tawkToGlobalState } from "./TawkToGlobal"

export async function hmacSha256Hex(secret: string, message: string) {
  // @ts-ignore
  const enc = new TextEncoder("utf-8")
  const algorithm = { name: "HMAC", hash: "SHA-256" }
  const key = await crypto.subtle.importKey(
    "raw",
    enc.encode(secret),
    algorithm,
    false,
    ["sign", "verify"]
  )
  const hashBuffer = await crypto.subtle.sign(
    algorithm.name,
    key,
    enc.encode(message)
  )
  // @ts-ignore
  const hashArray = [...new Uint8Array(hashBuffer)]
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")
  return hashHex
}

export const hideTawktoWidget = () => {
  if (window.Tawk_API?.hideWidget) {
    window.Tawk_API.hideWidget()
  }
  const facade = document.querySelector("#tawktofacade")
  if (facade && !tawkToGlobalState().loaded) {
    facade.style.display = "none"
  }
}

export const showTawktoWidget = () => {
  if (window.Tawk_API?.showWidget) {
    window.Tawk_API.showWidget()
  }
  const facade = document.querySelector("#tawktofacade")
  if (facade && !tawkToGlobalState().loaded) {
    facade.style.display = "flex"
  }
}
