import { PaymentObject } from "@temp/@next/components/organisms/PaymentTemplate/types"
import { Checkout } from "@temp/@next/globalStates/Checkout/types/Checkout"
import React, { ReactChild } from "react"

export enum OverlayType {
  address = "address",
  address_add = "address_add",
  address_edit = "address_edit",
  address_delete = "address_delete",
  cart = "cart",
  checkout = "checkout",
  login = "login",
  message = "message",
  sideNav = "side-nav",
  password = "password",
  variantchooser = "variantchooser",
  sortchoosernew = "sortchoosernew",
  filterchooser = "filterchooser",
  searchfilterchooser = "searchfilterchooser",
  storelocator = "storelocator",
  CodConfirmationSuggestion = "CodConfirmationSuggestion",
  transit = "transit",
  productImageZoomPopup = "productImageZoomPopup",
  mainMenuNav = "main-menu-nav",
  modal = "modal",
  register = "register",
  emiPlans = "emiPlans",
  onlinePaymentSuggestion = "onlinePaymentSuggestion",
  voucherCodes = "voucherCodes",
  voucherCodeApplied = "voucherCodeApplied",
  AffiliateHeaderNav = "AffiliateHeaderNav",
}

export enum OverlayTheme {
  left = "left",
  right = "right",
  modal = "modal",
  bottom = "bottom",
  center = "center",
}

export interface InnerOverlayContextInterface {
  title?: string
  content?: string | React.ReactNode
  status?: "success" | "error"
  data?: any
  handleOverlayClosed?: () => void
  handleOnlineSelected?: () => void
  handlePayment?: (
    paymentObject: PaymentObject,
    checkoutData?: Checkout | undefined,
    amount?: number | undefined
  ) => void
}

export type ShowOverlayType = (
  type: OverlayType,
  theme?: OverlayTheme,
  context?: InnerOverlayContextInterface
) => void

export interface OverlayContextProps {
  children: ReactChild
  pathname?: string
}

export interface OverlayContextInterface {
  type: OverlayType | null
  theme: OverlayTheme | null
  context: InnerOverlayContextInterface | null
  show: ShowOverlayType
  hide(): void
}

export const OverlayContext = React.createContext<OverlayContextInterface>({
  context: null,
  hide: () => {},
  show: () => {},
  theme: null,
  type: null,
})

OverlayContext.displayName = "OverlayContext"
