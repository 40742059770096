import { gql } from "@apollo/client"

export const CategoryFilterFragments = {
  entry: gql`
    fragment CategoryFilter on Category {
      id
      name
      children(first: 100) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
}

export const categoryFiltersQuery = gql`
  query CategoryFiltersQuery {
    categories(first: 100, level: 0) {
      edges {
        node {
          ...CategoryFilter
        }
      }
    }
  }
  ${CategoryFilterFragments.entry}
`
