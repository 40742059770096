import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const FooterLowerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding-top: 1.375rem;
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`
FooterLowerContainer.displayName = "S.FooterLowerContainer"

export const FooterLowerContainerCompanyName = styled.div`
  text-align: left;
  font-size: ${C.fontSizeParagraph2};
  font-family: ${C.baseFontFamily};
  font-weight: ${C.normalFontWeight};
  letter-spacing: 0;
  color: ${C.colorGray2};
`

FooterLowerContainerCompanyName.displayName = "S.FooterLowerContainerCompanyName"

export const FooterLowerContainerIcons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 540px) {
    grid-row: 1;
  }
`

FooterLowerContainerIcons.displayName = "S.FooterLowerContainerIcons"

export const FooterLowerContainerLinks = styled.div`
  text-align: right;
  font-size: ${C.fontSizeParagraph2};
  font-family: ${C.baseFontFamily};
  font-weight: ${C.normalFontWeight};
  letter-spacing: 0;
  color: ${C.colorGray2};
  @media (max-width: ${C.smallScreen}px) {
    text-align: left;
  }
`

FooterLowerContainerLinks.displayName = "S.FooterLowerContainerLinks"
