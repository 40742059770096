import { useReactiveVar } from "@apollo/client"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import Link from "next/link"
import React, { useCallback, useContext, useRef, useState } from "react"
import { ButtonStyled } from "../../atoms/Button"
import { ButtonSize, ButtonCurve, FontSize } from "../../atoms/Button/types"
import { BuycepsLogo } from "../../atoms/BuycepsLogo"
import { BuycepsLogoStyle } from "../../atoms/BuycepsLogo/types"
import { HeaderStyle } from "@components/molecules/Header/types"
import { AffliliateNavText, HeaderContainer } from "../../molecules/Header/styles"
import Image from "next/image"
import {
  MainMenuUpperSection,
  MainMenuMobileHamburgerIcon,
  MainMenuMobileLogo,
} from "../MainMenu/styles"
import { OverlayType, OverlayTheme, OverlayContext } from "@temp/components/Overlay"

export const AffiliateHeader: React.FC = () => {
  const matches: GlobalMediaQueryResultInterface = useReactiveVar(getMedia)
  const [shouldSticky, setShouldSticky] = useState(false)
  const overlayContext = useContext(OverlayContext)
  const scrollRef = useRef(null)

  const stickyObserver =
    typeof window !== "undefined" ?
      new IntersectionObserver(([e]) => setShouldSticky(e.intersectionRatio < 1), {
        threshold: [1],
      }) : undefined

  if (scrollRef && scrollRef.current) {
    stickyObserver?.observe(scrollRef.current)
  }
  const onHamburgerClick = useCallback(
    () => overlayContext.show(OverlayType.AffiliateHeaderNav, OverlayTheme.left),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <header>
      {matches.large ? (
        <HeaderContainer
          shouldSticky={shouldSticky}
          ref={scrollRef}
          headerStyle={HeaderStyle.AFFILIATE}
          mobileScreen={matches.small}
        >
          <>
            <div style={{ display: "flex" }}>
              <BuycepsLogo logoStyle={BuycepsLogoStyle.MEDIUM} />
              {matches.large && (
                <AffliliateNavText>Affiliate programme</AffliliateNavText>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={matches.large ? { paddingRight: "20px" } : undefined}>
                <Link passHref href="https://app.partners.buyceps.com/login">
                  <div
                    style={{
                      font: "normal normal 600 16px/19px Montserrat",
                    }}
                  >
                    Login
                  </div>
                </Link>
              </div>
              {matches.large && (
                <ButtonStyled
                  color="secondary"
                  buttonStyle="secondary"
                  buttonSize={ButtonSize.large}
                  buttonWidth="167px"
                  buttonCurve={ButtonCurve.normal}
                  fontSize={FontSize.subtitle}
                >
                  <Link passHref href="https://app.partners.buyceps.com/">
                    <div
                      style={{
                        font: "normal normal 600 16px/19px Montserrat",
                      }}
                    >
                      Become Partner
                    </div>
                  </Link>
                </ButtonStyled>
              )}
            </div>
          </>
        </HeaderContainer>
      ) : (
        <MainMenuUpperSection>
          <MainMenuMobileHamburgerIcon onClick={onHamburgerClick}>
            <Image
              width={24}
              height={18}
              src="/images/components/buycepslogo/hamburger.svg"
              layout="fixed"
              priority
              alt="menu-hamburger"
            />
          </MainMenuMobileHamburgerIcon>
          <MainMenuMobileLogo>
            <BuycepsLogo logoStyle={BuycepsLogoStyle.SMALL} />
          </MainMenuMobileLogo>
        </MainMenuUpperSection>
      )}
    </header>
  )
}
