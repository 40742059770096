import { gql } from "@apollo/client"

export const getShopQuery = gql`
  query GetShop {
    shop {
      defaultCountry {
        code
        country
      }
      countries {
        country
        code
      }
      geolocalization {
        country {
          code
          country
        }
      }
      paymentGateway
    }
  }
`

export const getPaymentMethod = gql`
  query PaymentMethod {
    shop {
      paymentGateway
    }
  }
`
