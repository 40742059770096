import { firstTick } from "@temp/localStates/documentComplete"
import NextLink, { LinkProps } from "next/link"
import React from "react"

const Link = (props: React.PropsWithChildren<LinkProps>) => {
  const prefetch =
    typeof props.prefetch === "boolean"
      ? props.prefetch
      : process.env.NEXT_PUBLIC_STOREFRONT_ENV === "PRODUCTION" && firstTick()
  return <NextLink {...{ ...props, prefetch }} />
}

export default Link
