import {
  hideTawktoWidget,
  showTawktoWidget,
} from "@temp/@next/globalStates/TawkTo/utils"
import React, { useCallback, useRef, useState } from "react"
import { NewModal } from "./NewModal"

interface ModalOptions {
  preventScroll?: boolean
  closeOnOverlayClick?: boolean
  elementId?: string
}

interface ModalWrapperProps {
  position: string
  children: React.ReactNode
  elementId?: "modal-root" | string
  cancelButtonText?: string
  submitButtonText?: string
  loading: boolean
  hideModalButtons: boolean
  title?: string
  backgroundTransparent?: boolean
}

type UseModal = (
  options?: ModalOptions
) => [
  ModalWrapper: React.FC<ModalWrapperProps>,
  open: () => void,
  close: () => void,
  isOpen: boolean
]

export const useModal: UseModal = (options = {}) => {
  const {
    preventScroll = false,
    closeOnOverlayClick = true,
    elementId = "modal-root",
  } = options
  const [isOpen, setOpen] = useState<boolean>(false)
  const tawktoStatus = useRef(false)

  const open = useCallback(() => {
    hideTawktoWidget()
    if (window?.Tawk_API?.isChatMaximized && window?.Tawk_API?.isChatMaximized()) {
      tawktoStatus.current = true
      if (window?.Tawk_API) {
        window?.Tawk_API?.minimize()
      }
    }
    setOpen(true)
  }, [setOpen])

  const close = useCallback(() => {
    showTawktoWidget()
    if (tawktoStatus.current) {
      tawktoStatus.current = false
      if (window?.Tawk_API) {
        window?.Tawk_API?.maximize()
      }
    }
    setOpen(false)
  }, [setOpen])

  const onOverlayClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      if (closeOnOverlayClick) {
        close()
      }
    },
    [closeOnOverlayClick, close]
  )

  const ModalWrapper = useCallback(
    ({
      children,
      position,
      title,
      loading,
      submitButtonText,
      cancelButtonText,
      backgroundTransparent,
    }) => (
      <NewModal
        backgroundTransparent={backgroundTransparent}
        position={position}
        title={title}
        loading={loading}
        submitButtonText={submitButtonText}
        cancelButtonText={cancelButtonText}
        hideModalButtons
        isOpen={isOpen}
        onOverlayClick={onOverlayClick}
        elementId={elementId}
        close={close}
        preventScroll={preventScroll}
      >
        {children}
      </NewModal>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen, close, elementId]
  )

  return [ModalWrapper, open, close, isOpen]
}
