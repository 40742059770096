import { gql } from "@apollo/client"

export const LogAction = gql`
  mutation LogAction(
    $eventId: String!
    $eventName: String!
    $clientInfo: ClientInfo!
    $event: JSONString
  ) {
    logAction(
      eventId: $eventId
      eventName: $eventName
      clientInfo: $clientInfo
      event: $event
    ) {
      errors {
        field
        message
      }
    }
  }
`
