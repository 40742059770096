import { makeVar } from "@apollo/client"

import { CartLineInterface, CheckoutContextInterface } from "./types"

export const getLocalCartLines = (): CartLineInterface[] => {
  try {
    // local storage get item returns string| null but JSON parse requires string
    return JSON.parse(localStorage.getItem("cart") || "[]")
  } catch {
    return []
  }
}

const initialState = {
  checkout: null,
  loadingCheckout: true,
  summaryVerified: false,
  isCod: false,
  //  Flag to determine, when the user checkout should be fetched from the
  //  API and override the current, stored one - happens after user log in
  syncUserCheckout: true,
  selectedPaymentMethod: null,
  // cart states
  loadingCart: false,
  cartLines: getLocalCartLines(),
  cartErrors: [],
  // to store cart changes across tabs
  cartSessionId: "",
  trackCheckout: true,
}

// creating checkout global state
export const checkoutGlobalState = makeVar<CheckoutContextInterface>(initialState)
