import React from "react"

import {
  InnerOverlayContextInterface,
  OverlayContext,
  OverlayContextInterface,
  OverlayContextProps,
  OverlayTheme,
  OverlayType,
} from "./context"

class Provider extends React.Component<
  OverlayContextProps,
  OverlayContextInterface
> {
  notificationCloseDelay = 2500
  constructor(props) {
    super(props)
    this.state = {
      context: null,
      hide: this.hide,
      show: this.show,
      theme: null,
      type: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.pathname !== prevProps.pathname &&
      this.state.type !== null &&
      this.state.type !== OverlayType.message
    ) {
      this.hide()
    }
  }
  componentWillUnmount() {
    this.hide()
  }

  show = (
    type: OverlayType,
    theme?: OverlayTheme,
    context?: InnerOverlayContextInterface
  ) => {
    this.setState({ type, theme, context })
    document.body.style.overflowY =
      type === OverlayType.message || type === OverlayType.mainMenuNav
        ? "auto"
        : "hidden"
    if (type === OverlayType.message) {
      setTimeout(this.hide, this.notificationCloseDelay)
    }
  }

  hide = () => {
    this.setState({ type: null })
    document.body.style.overflowY = "auto"
  }

  render() {
    return (
      <OverlayContext.Provider value={this.state}>
        {this.props.children}
      </OverlayContext.Provider>
    )
  }
}

export default Provider
