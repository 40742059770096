import React from "react"
import { ReactSVG } from "react-svg"

import * as S from "./styles"
import { IconProps } from "./types"

const StyledSocialMediaIcon: React.FC<IconProps> = ({ medium, target }) => (
  <S.SocialWrapper>
    <a href={medium.href} target={target || "_blank"} aria-label={medium.ariaLabel}>
      <ReactSVG src={medium.path} />
    </a>
  </S.SocialWrapper>
)

export default StyledSocialMediaIcon
