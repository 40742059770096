import { useReactiveVar } from "@apollo/client"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import React, { memo, useEffect, useRef, useState } from "react"

import * as S from "./styles"
import { MainMenuComponentProps, MainMenuProps } from "./types"

const MainMenuDesktop = dynamic<MainMenuComponentProps>(() =>
  import("@temp/@next/components/organisms/MainMenu/MainMenuDesktop").then(
    (mod) => mod.MainMenuDesktop
  )
)
const MainMenuMobile = dynamic(() =>
  import("@temp/@next/components/organisms/MainMenu/MainMenuMobile").then(
    (mod) => mod.MainMenuMobile
  )
)

const MainMenu: React.FC<MainMenuProps> = ({ menuFacadeData }) => {
  const router = useRouter()
  const { pathname } = router
  const matches: GlobalMediaQueryResultInterface = useReactiveVar(getMedia)
  const [lastScrollY, setLastScrollY] = useState(0)
  const navRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    window.addEventListener("scroll", handleNavScroll)
    return () => window.removeEventListener("scroll", handleNavScroll)
  }, [lastScrollY])

  const handleNavScroll = () => {
    if (typeof window !== "undefined" && navRef.current) {
      const currentScrollPos = window.pageYOffset
      if (currentScrollPos > lastScrollY && currentScrollPos > 80) {
        // if scroll down hide the navbar
        navRef.current.classList.add("scrolled")
      } else {
        // if scroll up show the navbar
        navRef.current.classList.remove("scrolled")
      }
      setLastScrollY(currentScrollPos)
    }
  }

  return (
    <S.MainMenuContainer
      isProductPage={pathname === "/product/[name]/[id]"}
      ref={navRef}
    >
      <>
        {!matches.large ? (
          <MainMenuMobile />
        ) : (
          <MainMenuDesktop menuFacadeData={menuFacadeData} />
        )}
      </>
    </S.MainMenuContainer>
  )
}

export const MemoizedMainMenu = memo(MainMenu)
