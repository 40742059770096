import { gql } from "@apollo/client"

export const BrandsFilterFragments = {
  entry: gql`
    fragment BrandFilter on Brand {
      id
      name
      products {
        totalCount
      }
    }
  `,
}

export const BrandsFiltersQuery = gql`
  query brandsFilterQuery {
    brands(first: 500) {
      edges {
        node {
          ...BrandFilter
        }
      }
    }
  }
  ${BrandsFilterFragments.entry}
`
