import { GatewaysEnum, PaymentGatewayEnum } from "../../types/globalTypes"

type MetaProps = JSX.IntrinsicElements["meta"]

interface MetaContextInterface {
  title?: string
  description?: string
  url?: string
  image?: string
  type?: string
  pagination?: Record<string, any>
  custom?: MetaProps[]
  googleStructuredData?: string
}

const generatePageUrl = (slug: string) => `/page/${slug}/`

export const BASE_URL = "/"
export const PRODUCTS_PER_PAGE = 12
export const PROVIDERS = {
  // BRAINTREE: GatewaysEnum.BRAINTREE,
  DUMMY: GatewaysEnum.DUMMY,
  RAZORPAY: PaymentGatewayEnum.RAZORPAY,
  INGENICO: PaymentGatewayEnum.INGENICO,
  COD: GatewaysEnum.COD,
}
export const STATIC_PAGES = [
  {
    label: "About",
    url: generatePageUrl("about"),
  },
]
export const SOCIAL_MEDIA = [
  {
    ariaLabel: "linkedin",
    href: "https://www.linkedin.com/company/buycepsindia/",
    path: "/images/Footer/SocialMedia/linkedin-icon.svg",
  },
  {
    ariaLabel: "facebook",
    href: "https://www.facebook.com/BuycepsIndia/",
    path: "/images/Footer/SocialMedia/facebook-icon.svg",
  },
  {
    ariaLabel: "instagram",
    href: "https://www.instagram.com/buycepsindia/",
    path: "/images/Footer/SocialMedia/instagram-icon.svg",
  },
  {
    ariaLabel: "twitter",
    href: "https://twitter.com/hashtag/buyceps",
    path: "/images/Footer/SocialMedia/twitter-icon.svg",
  },
]

// TODO : Fix this hardcoding
export const META_DEFAULTS: MetaContextInterface = {
  custom: [],
  description: "Buyceps India is a best supplement store in Mumbai",
  image: "/images/logo.svg",
  title: "Buyceps",
  type: "website",
  url: "https://buyceps.com",
}
