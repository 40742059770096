import { RefObject, useEffect } from "react"

export function useOverlay(
  isOpen: boolean,
  close: () => void,
  reference: RefObject<HTMLDivElement>
): void {
  useEffect(() => {
    if (!isOpen) {
      return
    }

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        close()
      }
    }

    document.body.addEventListener("keydown", handleKeydown)

    // eslint-disable-next-line consistent-return
    return () => {
      document.body.removeEventListener("keydown", handleKeydown)
    }
  }, [isOpen, close, reference])
}
