import React from "react"

import * as S from "./styles"
import { IProps } from "./types"

const getHeight = () => {
  const headerHeight = document.getElementById("header").offsetHeight
  const footerHeight = document.getElementById("footer").offsetHeight
  return window.innerHeight - headerHeight - footerHeight
}

export const Loader: React.FC<IProps> = ({ fullScreen }: IProps) => {
  return (
    <S.Wrapper fullScreen={fullScreen && { height: getHeight() }}>
      <S.Items>
        <span />
        <span />
        <span />
      </S.Items>
    </S.Wrapper>
  )
}
