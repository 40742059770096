import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const NavlinkContainer = styled.div<{
  navstyle: string
  isMobileScreen?: boolean
  setStyle?: boolean
}>`
  ${(props) =>
    (props.navstyle === "normal" || props.navstyle === "header") &&
    `margin: 0.3rem 0;
font-family: ${C.baseFontFamily};
font-size: ${C.fontSizeParagraph2};
font-weight: ${C.normalFontWeight};
text-transform: capitalize;
white-space: nowrap;

${props.setStyle && ` text-decoration: underline;`}

a{
  font-weight: ${C.semiBoldFontWeight};
}


${
  props.isMobileScreen
    ? `
  display: flex;
  align-items: center;
  font-weight: ${C.semiBoldFontWeight};
`
    : `
display: flex;
  align-items: center;
  font-size: ${C.fontSizeParagraph1};
  font-weight: ${props.navstyle === "normal" ? "500" : "600"} !important;
`
}
`}

  ${(props) =>
    props.navstyle === "underline" &&
    ` text-decoration: underline !important;
margin-top: 12px;
margin-bottom: 12px;
font-family: ${C.baseFontFamily};
font-weight: ${C.semiBoldFontWeight};
font-size: ${C.fontSizeParagraph1};
text-transform: capitalize;

@media (max-width: ${C.smallScreen}px) {
  font-weight: ${C.semiBoldFontWeight};
}`}


${(props) =>
    props.navstyle === "gray_background" &&
    ` background-color: #929495;
display: flex;
align-items: center;
justify-content: center;
color: ${C.white};
padding: 8px;
border-radius: 4px;
margin-top: 12px;
margin-bottom: 12px;
font-family: ${C.baseFontFamily};
font-weight: ${C.semiBoldFontWeight};
font-size: ${C.fontSizeParagraph1};
text-transform: none;

@media (max-width:${C.smallScreen}px) {
  font-weight: ${C.semiBoldFontWeight};
}`}

${(props) =>
    props.navstyle === "yellow_background" &&
    `    background-color: #fade00;
display: flex;
align-items: center;
justify-content: center;
color: ${C.v2Theme.fontColors.black};
padding: 8px;
border-radius: 4px;
margin-bottom: 12px;
font-family: ${C.baseFontFamily};
font-weight: ${C.semiBoldFontWeight};
font-size: ${C.fontSizeParagraph1};
text-transform: none;

@media (max-width: ${C.smallScreen}px) {
  font-weight: ${C.semiBoldFontWeight};
}`}
`
NavlinkContainer.displayName = "S.NavlinkContainer"
