// colors
import { keyframes } from "styled-components"

// new theme colors

export const primaryColor1 = "#ffd400"
export const primaryColor2 = "#44a228"
export const secondaryColor1 = "#de8e0e"
export const secondaryColor2 = "#867457"
export const secondaryColor3 = "#fd4b75"
export const tintColor1 = "#e5f8dd"
export const tintColor2 = "#fcf4db"
export const tintColor3 = "#f2efe7"
export const grayishNavy = "#8f90a9"
export const bluishWhite = "#e8ecf3"

// background and text colors

export const black = "#101114"
export const gray0 = "#212640"
export const gray1 = "#7687a1"
export const gray2 = "#dbe2ea"
export const gray3 = "#eff3f9"
export const gray4 = "#e4eaf2"
export const white = "#ffffff"
export const dark2 = "#000000"
export const darkBlueShade = "#181921"
export const transparent = "transparent"

// gradients

export const gradients1 = "linear-gradient(to bottom, #edf0f5, #f1f6fe)"
export const gradients2 = "linear-gradient(to bottom, #fefeff, #f8faff 100%)"
export const gradient3 =
  "linear-gradient(to right, rgba(250, 252, 255, 0.5) -9%, #fefeff 76%)"

// shadows
export const shadow1 = "16px 16px 68px 0 rgba(121, 99, 114, 0.7)"
export const shadow2 = "0 6px 6px 0 rgba(0, 0, 0, 0.05)"
export const shadow3 = "8px 8px 34px rgba(121, 99, 114, 0.15)"
export const shadow4 = "rgba(100,99,121,0.2) 0px 8px 8px"
export const shadow5 = "8px 8px 34px 0 rgba(121, 99, 114, 0.15)"
export const dropShadow1 = "drop-shadow(rgba(121, 99, 114, 0.4) 16px 16px 14px)"

// borders
export const border1 = `solid 1px ${gray1}`
export const border2 = `solid 1px ${gray2}`
export const border3 = `solid 1px ${gray3}`
export const border4 = `solid 4px ${gray2}`
export const border5 = `solid 1px ${primaryColor2}`
export const border6 = `solid 3px ${gray0}`

// new typography
export const baseFontFamily = "montserrat"

// font sizes
export const v2FontSizeSuperHeading = "40px"
export const v2FontSizeHeading = "24px"
export const v2FontSizeSubHeading = "20px"
export const v2FontSizeTitle = "18px"
export const v2FontSizeSubTitle = "16px"
export const v2FontSizeParagraph1 = "14px"
export const v2FontSizeParagraph2 = "13px"
export const v2FontSizeCaption = "12px"

// font weights
export const normalFontWeight = 500
export const semiBoldFontWeight = 600

export type FontSizes =
  | "caption"
  | "heading"
  | "paragraph1"
  | "paragraph2"
  | "subHeading"
  | "subTitle"
  | "superHeading"
  | "title"

// border radius
export const v2borderRadius1 = "14px"
export const v2borderRadius2 = "8px"

// new theme
export const v2Theme = {
  backgroundColors: {
    black,
    gradients1,
    gradients2,
    gray0,
    gray1,
    gray2,
    gray3,
    gray4,
    primaryColor1,
    primaryColor2,
    tintColor1,
    tintColor2,
    tintColor3,
    transparent,
    white,
    bluishWhite,
  },
  borders: {
    border1,
    border2,
    border3,
    border4,
    border5,
    border6,
  },
  borderRadius: {
    borderRadius1: v2borderRadius1,
    borderRadius2: v2borderRadius2,
  },
  colors: {
    primaryColor1,
    primaryColor2,
    secondaryColor1,
    secondaryColor2,
    secondaryColor3,
    tintColor1,
    tintColor2,
    tintColor3,
    bluishWhite,
  },
  fontColors: {
    black,
    gray0,
    gray1,
    gray2,
    gray3,
    primaryColor2,
    secondaryColor3,
    white,
    dark2,
    grayishNavy,
    darkBlueShade,
  },
  fontSizes: {
    caption: v2FontSizeCaption, // 12px
    heading: v2FontSizeHeading, // 24px
    paragraph1: v2FontSizeParagraph1, // 14px
    paragraph2: v2FontSizeParagraph2, // 13px
    subHeading: v2FontSizeSubHeading, // 20px
    subTitle: v2FontSizeSubTitle, // 16px
    superHeading: v2FontSizeSuperHeading, // 40px
    title: v2FontSizeTitle, // 18px
  },
  fontWeights: {
    regular: normalFontWeight, // 500
    semiBold: semiBoldFontWeight, // 600
  },
  gradients: {
    gradients1,
    gradients2,
  },
  shadows: {
    dropShadow1,
    shadow1,
    shadow2,
    shadow3,
    shadow4,
    shadow5,
  },
}

export const autofillColor = "rgb(250, 255, 189)"
export const autofillColorSelected = "rgb(232, 240, 254)"
export const disabledColor = "#f0f0f0"
export const colorGrayAddress = "#606060"
export const colorGray1 = "#929495"
export const colorGray2 = "#c4c4c4"
export const colorGray3 = "#f6f6f6"
export const colorGray4 = "#fafafa"
export const grayDark = "#7d7d7d"
export const dividerColor = "#E2E0E5"
export const selectedColor = "#44a228"
export const dividerColorAlt = "#f1f2f3"
export const selectedBackgroundColor = "#FFFAD3"
export const colorAlternate = "#2a912e"
export const colorAlternateDark = "#1F6E22"
export const colorHint = "#288b72"
export const colorAlert = "#fa6349"
export const colorAlertAlt = "#f05555"
export const colorPrimary = "#fade00"
export const colorPrimaryWithOpacity = "rgb(250,222,0,0.2)"
export const underlineColor = "#FACA00"
export const colorPrimaryDark = "#886915"
export const colorPrimaryExtraDark = "#886915"
export const colorPrimaryExtraLight = "#fffdef"
export const turquoiseLight = "#f1f5f5"
export const secondaryColor5 = "linear-gradient(rgb(53,55,55), rgb(100, 91, 15))"
export const filterBackground = "linear-gradient(rgb(250, 154, 0), rgb(244, 55, 55))"
export const overlayBackground = "rgba(0,0,0,0.7)"
export const defaultBackground = "#34353E"
export const headerBackground = "#222B3D"
export const alternateBackground = colorGray4
export const defaultShadow = "0 3px 6px rgba(0,0,0,0.05)"
export const defaultBorderColor = "#E2E0E5"
export const selectedBackgroundColor1 = "#fafffa"
export const textButton = "#8e8e8e"
export const grayLight = "#f6f6f6"
export const colorPrimaryLight = "#FFFCC3"
export const colorPrimaryMid = "#E6DA0C"
export const colorDarkBlue = "#343D4E"
export const colorDarkBluePrimary = "#1B202B"
export const colorDarkBlueSeconday = "#1F2531"
export const colorDimGray = "#707070"
export const colorPaleYellow = "#F0E9B6"
export const colorLemonYellow = "#FFF43C"
export const colorGrayishBlue = "#343D4E"
export const colorSilver = "#C4C4C4"
export const grayAlternateLight = "#e2e0e5"
export const graynot = "#d9d7de"
export const colorDarkGray1 = "#7687a1"
export const turquoise = "#51e9d2"
export const darkBlue = "#cad5e5"

// theme colors
export const theme = {
  activeBorder: `1px solid ${colorAlternate}`,
  alternateBackground,
  alternateButton: colorAlternate,
  alternateButtonDark: colorAlternateDark,
  autofill: autofillColor,
  autofillSelected: autofillColorSelected,
  baseFontColor: black,
  basePlaceholderFontColor: colorGray1,
  textPlaceHolder: colorGray1,
  baseSecondaryFontColor: colorGray2,
  baseLightSecondaryFontColor: graynot,
  border: `1px solid ${dividerColor}`,
  borderRadius: `4px`,
  dark: black,
  defaultBackground,
  defaultBorderColor,
  disabled: disabledColor,
  discountBackground: secondaryColor5,
  divider: dividerColor,
  error: colorAlert,
  errorAlt: colorAlertAlt,
  errorBorder: `1px solid ${colorAlert}`,
  grayButton: colorGray3,
  grayButtonDark: dividerColor,
  headerBackground,
  light: colorGray3,
  overlayBackground,
  primary: colorPrimary,
  primaryDark: underlineColor,
  primaryExtraDark: colorPrimaryDark,
  primaryExtraLight: colorPrimaryExtraLight,
  secondaryButton: colorGray3,
  secondaryButtonDark: colorGray2,
  secondaryButtonLight: graynot,
  socialButton: white,
  socialButtonDark: colorGray3,
  success: colorAlternate,
  textButton,
  grayLight,
  primaryLight: colorPrimaryLight,
  primaryMid: colorPrimaryMid,
  primaryDarkBlue: colorDarkBluePrimary,
  secondaryDarkBlue: colorDarkBlueSeconday,
  paleYellow: colorPaleYellow,
  dimGray: colorDimGray,
  darkBlue: colorDarkBlue,
  borderGray: grayAlternateLight,
  white,
  turquoiseLight,
  darkGray1: colorDarkGray1,
  lemonYellow: colorLemonYellow,
  grayishBlue: colorGrayishBlue,
  silver: colorSilver,
  buyNow: darkBlue,
}

// typography
export const baseFontSize = "1rem" // 16px
export const baseLineHeight = "1.25rem" // 20px
export const thinFontWeight = 300
export const mobileSmallNormalFontWeight = 400
export const lightFontWeight = 400
export const boldFontWeight = 700
export const extraBoldFontWeight = 800
export const superExtraBoldFontWeight = 900
export const h1LineHeight = 1
export const superHeaderNormalLineHeight = "2.5rem"
export const dividerSize = "1px"
export const fontSizeSuperHeader = "1.5rem" // 24px at 1280px width
export const fontSizeSuperHeaderNormal = "2rem" // 32 px at 1280px width
export const fontSizeSuperHeaderII = "2.5rem" // 40px at 1280px width
export const fontSizeSuperHeaderMid = "1.75rem" // 28px at 1280px width
export const fontSizeSuperHeaderDouble = "5rem" // 80px at 1280px width
export const fontSizeHeader = "1.25rem" // 20px at 1280px width
export const fontSizeSubHeader = "1.125rem" // 18px at 1280px width
export const fontSizeSubTitle = "1rem" // 16px at 412px width
export const fontSizeParagraph1 = "0.875rem" // 14px at 1280px width
export const fontSizeParagraph2 = "0.8125rem" // 13px at 1280px width
export const fontSizeCaptions = "0.75rem" // 12px at 1280px width
export const fontSizeSmallCaptions = "0.5625rem" // 12px at 1280px width

export const mobileFilterButtonHeight = "7.524vw"
export const mobileGridColumnGap = "4.37vw"
export const mobileHeaderFontSize = "4.85vw" // 20px at 412px width

// border
export const defaultBorderRadius = "0.25rem"
export const defaultBorderWidthSmall = "1px"
export const defaultButtonborderRadius = "0.4rem"

export const orderColor = "#f5f5f5"
export const xFontSize = "0.7rem"
export const mobileCaptionsFontSize = "2.91vw"
export const mobileSubTitleFontSize = "3.88vw"
export const mobileParagraph2FontSize = "3.15vw"
export const mobileButtonHeightLarge = "12.621vw"
export const mobileSubHeaderFontSize = "4.37vw"
export const mobileButtonFontSize = "3.25rem"

export const headerHeight = "3.5rem"
export const mobileHeaderHeight = "9.2vw"

export const defaultBorder = "1px solid #E2E0E5"

export const spacer = 1 // rem
export const mobileFormSpacer = 1.5 // rem

export const ButtonBoxShadow = "-5px 5px 14px 0px rgba(0, 0, 0, 0.2)"
export const ButtonBoxShadowClicked = "-3px 3px 14px 0px rgba(129, 67, 67, 0.2)"
export const MessageShadow = "0px 6px 15px 3px rgba(0, 0, 0, 0.25)"
export const SearchFieldShadow = "0 3px 6px 0 rgba(0, 0, 0, 0.15)"
export const searchShadowSelected = "0px 6px 10px 0px rgba(0, 0, 0, 0.25)"

// dimension

export const dimension = 1 // rem
export const MobileSpacerLarge = "3.883vw"
export const MobileSpacerExtraLarge = "6.553vw"

// breakpoints
// export const mediumScreen = 992
// export const smallScreen = 540
// export const x_smallScreen = 320

export const mediumScreen = 1191
export const smallScreen = 767
// eslint-disable-next-line @typescript-eslint/naming-convention
export const x_smallScreen = 320
// eslint-disable-next-line @typescript-eslint/naming-convention
export const m_smallScreen = 375

// brand item
export const featuredBrandItemHeight = 200 // 200px
export const featuredBrandLogoHeight = 50 // 50px

export const GLOBAL_MEDIA_QUERIES = {
  large: "(min-width: 1192px)",
  medium: "(min-width: 768px) and (max-width: 1192px)",
  small: "(max-width: 767px)",
}

export const PRODUCT_PAGE_MEDIA_QUERIES = {
  large: "(min-width: 1280px)",
  medium: "(min-width: 812px) and (max-width: 1279px)",
  small: "(max-width: 811px)",
}

export enum GridColumns {
  single = "1fr",
  double = "1fr 1fr",
  triple = "1fr 1fr 1fr",
  quad = "1fr 1fr 1fr 1fr",
}

export type ScreenSize = "desktop" | "mobile" | "pad"

export const slideUp = keyframes`
  from {
    bottom: -100vh;
  }

  to {
    bottom: 0;
  }
`

export const slideUpDesktop = keyframes`
  from {
    bottom: -50%;
    transform: translate(-50%, 0);
  }

  to {
    bottom: 50%;
    transform: translate(-50%, 50%);
  }
`

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const slideDown = keyframes`
  from {
    transform: translateY(-500px);
  }

  to {
    transform: translateY(0px);
  }
`

// Product Container sizes
export const productImageContainer = {
  desktop: {
    width: "190px",
    widthNumber: 190,
    height: "216px",
    heightNumber: 216,
    padding: "12px 24px",
    margin: "52px auto 24px",
  },
  mobile: {
    height: "189px",
    heightNumber: 189,
    width: "100%",
    widthNumber: 142,
    padding: "",
    margin: "",
  },
}

// Spacing

export const spacing = {
  halfSpacer: `${spacer / 2}rem`, // 8px
  mediumHalfSpacer: `${spacer - 0.25}rem`, // 12px
  mobileSpace: `${spacer * 0.7}rem`, // 11px
  none: `0`,
  quarterSpacer: `${spacer / 4}rem`, // 4px
  spacer: `${spacer}rem`, // 16px
  spacerDouble: `${spacer * 2}rem`, // 32px
  spacerExtra: `1.125rem`, // 18px
  spacerDouble2x: `${spacer * 4}rem`, // 64px
  spacerMedium: `${spacer + 0.5}rem`, // 24px
  spacerQuadruple: `${spacer * 4}rem`, // 64px
  spacerTriple: `${spacer * 3}rem`, // 48px
  spacerPentuple: `${spacer * 5}rem`,
  mobileSpacerLarge: MobileSpacerLarge, // 3.883vw;
  mobileSpacerExtraLarge: MobileSpacerExtraLarge, // 6.553vw
  spacerTriple2x: `${spacer * 6}rem`, // 96px
  spacerSuper: `${spacer * 7.5}rem`, // 120px
  oneAndQuarterSpacer: `${spacer * 1.25}rem`,
  spacerTripleDouble: `${spacer * 6}rem`, // 96px
  v2ThemeSpaceMedium: `${spacer * 4.75}rem`, // 76px
  spacerAndQuarter: `${spacer * 1.25}rem`, // 20px
  spacerSixty: `${spacer * 3.75}rem`, // 60px
}

// Container

export const containerWidth: { [key: string]: any } = {
  width: {
    large: "1150px",
    medium: "736px",
    small: "100%",
  },
}
