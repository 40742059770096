import Link from "@temp/core/link"
import React from "react"

import * as S from "./styles"
import { NavLinkProps } from "./types"

export const NavLink: React.FC<NavLinkProps> = ({
  setStyle = false,
  item,
  prefetch,
  ...props
}) => {
  // Hooks
  const { style, finalUrl } = item
  const name =
    item.name && item.name.length > 25 ? `${item.name.slice(0, 24)}…` : item.name

  if (finalUrl) {
    return (
      <S.NavlinkContainer navstyle={style} setStyle={setStyle}>
        <Link href={finalUrl} prefetch={prefetch} {...props}>
          {name}
        </Link>
      </S.NavlinkContainer>
    )
  }

  return (
    <S.NavlinkContainer navstyle={style} setStyle={setStyle}>
      {" "}
      <span {...props}>{name}</span>{" "}
    </S.NavlinkContainer>
  )
}
