import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { ReactSVG } from "react-svg"
import { ButtonSize, FontSize } from "@temp/@next/components/atoms/Button/types"
import { ButtonStyled } from "@temp/@next/components/atoms/Button"
import { useOverlay } from "./useOverlay"

import * as S from "./styles"
import { useLockBodyScroll } from "../useLockBodyScroll"

interface ModalProps {
  position: string
  children: React.ReactNode
  isOpen: boolean
  onOverlayClick: React.MouseEventHandler<HTMLDivElement>
  elementId?: "modal-root" | string
  cancelButtonText?: string
  submitButtonText?: string
  loading: boolean
  hideModalButtons: boolean
  title: string
  close: () => void
  preventScroll: boolean
  backgroundTransparent?: boolean
  hideTitleSection?: boolean
}

export const NewModal: React.FC<ModalProps> = ({
  children,
  isOpen = false,
  onOverlayClick,
  elementId = "modal-root",
  cancelButtonText = "Cancel",
  hideModalButtons,
  submitButtonText = "Submit",
  loading,
  title,
  position,
  close,
  preventScroll,
  backgroundTransparent = false,
  hideTitleSection = false,
}) => {
  const reference = useRef<HTMLDivElement>(null)
  useOverlay(isOpen, close, reference)
  useLockBodyScroll(isOpen && preventScroll)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (isOpen === false) {
    return null
  }

  return mounted
    ? createPortal(
        <S.Overlay role="dialog" aria-modal>
          <S.OverlayModal onClick={onOverlayClick} />
          <S.Modal
            className="modal__wrapper"
            ref={reference}
            position={position}
            largeHeight
            backgroundTransparent={backgroundTransparent}
          >
            {!hideTitleSection ? (
              <S.ModalTitle>
                <p>{title}</p>
                <S.Close onClick={onOverlayClick}>
                  <ReactSVG
                    src="/images/close/modal-close.svg"
                    className="modal__close"
                  />
                </S.Close>
              </S.ModalTitle>
            ) : null}
            <div className="modal__content">{children}</div>
            {!hideModalButtons && (
              <S.Footer>
                {cancelButtonText && (
                  <S.CancelButton onClick={onOverlayClick}>
                    {cancelButtonText}
                  </S.CancelButton>
                )}
                {submitButtonText && (
                  <ButtonStyled
                    fontSize={FontSize.subheader}
                    color="alternate"
                    disabled={loading}
                    buttonStyle="alternate"
                    buttonSize={ButtonSize.small}
                    type="submit"
                  >
                    {loading ? "Loading" : submitButtonText}
                  </ButtonStyled>
                )}
              </S.Footer>
            )}
          </S.Modal>
        </S.Overlay>,
        document.querySelector(`#${elementId}`) as HTMLElement
      )
    : null
}
