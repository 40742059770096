import { useEffect, useState } from "react"

export const useServiceWorker = ({ timeout = 1000 }) => {
  const [updateAvailable, setUpdateAvailable] = useState<boolean>(false)
  const [registrations, setRegistration] = useState<any>(null)

  // Listener to update registration
  useEffect(() => {
    const interval = setInterval(() => {
      if (registrations) {
        registrations.update()
      }
    }, timeout)
    return () => clearInterval(interval)
  }, [registrations, timeout])

  // Function to register service worker
  const registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        // this will register new service-worker.js file
        navigator.serviceWorker
          .register(`${window.location.origin}/service-worker.js`)
          // eslint-disable-next-line promise/always-return
          .then((registration) => {
            registration.addEventListener("updatefound", () => {
              setUpdateAvailable(true)
            })
            setRegistration(registration)
            console.log(
              "Service Worker (service-worker.js) registration successful with scope:",
              registration.scope
            )
          })
          .catch((error) => {
            console.log("Service Worker registration failed:", error)
          })

        // this will un-register old sw.js file
        setTimeout(() => {
          navigator.serviceWorker
            .getRegistrations()
            .then((swRegistrations) => {
              for (const registration of swRegistrations) {
                if (
                  registration?.active?.scriptURL ===
                  `${window.location.origin}/sw.js`
                ) {
                  console.log("unregistered sw.js")
                  registration.unregister()
                }
              }
              return null
            })
            .catch((error) => console.log(error))
        }, 10_000)
      })
    }
  }

  // Function to remove service worker
  // const unregisterServiceWorker = () => {
  //   if (window && registrations) {
  //     window.removeEventListener("load", registerServiceWorker)
  //     registrations.unregister()
  //     setRegistration(null)
  //   }
  // }

  // Listener to register and unregister service worker when app is mounted/dismounted
  useEffect(() => {
    registerServiceWorker()
    // return () => unregisterServiceWorker()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrations])

  return { updateAvailable }
}
