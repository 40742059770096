import React from "react"

import { Icon } from "../Icon"
import * as S from "./styles"
import { ButtonCurve, ButtonSize, ButtonStyledProps, FontSize } from "./types"

// TODO : Rename this back to Button (I had renamed it to avoid conflict between this component and the main component in src/component folder)
export const ButtonStyled: React.FC<ButtonStyledProps> = ({
  color = "primary",
  buttonStyle = "primary",
  fontWeight = "600",
  buttonWidth = "100%",
  buttonTopBottomMargin = "0rem",
  textTransform = "capitalize",
  buttonCurve = ButtonCurve.normal,
  buttonSize = ButtonSize.small,
  fontSize = FontSize.subheader,
  iconName,
  btnRef,
  children,
  ...props
}: ButtonStyledProps) => {
  let ButtonWithTheme = S.Primary
  let ButtonText = S.Text
  let ButtonIcon
  switch (buttonStyle) {
    case "primary": {
      ButtonWithTheme = S.Primary
      ButtonText = S.Text
      break
    }
    case "secondary": {
      ButtonWithTheme = S.Secondary
      ButtonText = S.Text
      break
    }
    case "notfound": {
      ButtonWithTheme = S.Notfound
      ButtonText = S.Text
      break
    }
    case "previous": {
      ButtonWithTheme = S.Previous
      ButtonText = S.Text
      break
    }
    case "alternate": {
      ButtonWithTheme = S.Alternate
      ButtonText = S.AlternateText
      break
    }
    case "gray": {
      ButtonWithTheme = S.Gray
      ButtonText = S.GrayText
      ButtonIcon = <Icon iconName={iconName} color="#c4c4c4" size={32} />
      break
    }
    case "rateDelivery": {
      ButtonWithTheme = S.Primary
      ButtonText = S.Text
      break
    }
    case "unboxingWithIcon": {
      ButtonWithTheme = S.Primary
      ButtonText = S.UnboxingText
      ButtonIcon = <Icon iconName={iconName} size={32} />
      break
    }
    case "unboxingUploaded": {
      ButtonWithTheme = S.Primary
      ButtonText = S.GrayText
      ButtonIcon = <Icon iconName={iconName} size={28} color="#33B95E" />
      break
    }
    case "grayWithoutIcon": {
      ButtonWithTheme = S.Gray
      ButtonText = S.GrayWithoutIconText
      break
    }
    case "addAddress": {
      ButtonWithTheme = S.AddAddress
      ButtonText = S.AddAddressText
      break
    }
    case "social": {
      ButtonWithTheme = S.Social
      ButtonText = S.SocialText
      ButtonIcon = (
        <S.SocialIconContainer>
          <Icon iconName={iconName} size={32} />
        </S.SocialIconContainer>
      )
      break
    }
    case "grayisBlue": {
      ButtonWithTheme = S.GrayisBlue
      ButtonText = S.GrayisBlueText
      break
    }
    case "rateProduct": {
      ButtonWithTheme = S.GrayisBlue
      ButtonText = S.Text
      break
    }
    default:
  }
  return (
    <ButtonWithTheme
      buttonTopBottomMargin={buttonTopBottomMargin}
      buttonWidth={buttonWidth}
      buttonStyle={buttonStyle}
      buttonSize={buttonSize}
      buttonCurve={buttonCurve}
      ref={btnRef}
      {...props}
    >
      <ButtonText
        textTransform={textTransform}
        buttonSize={buttonSize}
        fontSize={fontSize}
        fontWeight={fontWeight}
      >
        {ButtonIcon}
        <S.ChildrenContainer>{children}</S.ChildrenContainer>
      </ButtonText>
    </ButtonWithTheme>
  )
}
