import { Container } from "@components/atoms/Container"
import { FooterLower } from "@components/molecules/FooterLower"
import { FooterUpper } from "@components/molecules/FooterUpper"
import React, { memo } from "react"

import * as S from "./styles"

const NewFooter: React.FC = () => (
  <S.FooterContainer id="footer">
    <Container backgroundColor="#101114">
      <FooterUpper />
      <S.FooterDivider />
      <FooterLower />
    </Container>
  </S.FooterContainer>
)

export const MemoizedFooter = memo(NewFooter)
