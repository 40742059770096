import { GetShop_shop } from "@temp/components/ShopProvider/types/GetShop"
import { createContext } from "react"

import { PaymentGatewayEnum } from "../../../types/globalTypes"

export const defaultCountry = {
  __typename: "CountryDisplay" as const,
  code: "IN",
  country: "India",
}

const defaultStates = [
  "Andaman and Nicobar",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra And Nagar Haveli",
  "Daman And Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Pondicherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
]

export const defaultContext: BuycepsShopContext = {
  __typename: "Shop",
  countries: [],
  states: defaultStates,
  defaultCountry,
  geolocalization: { __typename: "Geolocalization", country: defaultCountry },
  paymentGateway: PaymentGatewayEnum.RAZORPAY,
}

export interface paymentMethodInterface {
  paymentGateway: string
  paymentGatewayIntegration: string
}

export interface BuycepsShopContext extends GetShop_shop {
  states: string[]
}

export const ShopContext = createContext<BuycepsShopContext>(defaultContext)

ShopContext.displayName = "ShopContext"
