export enum HeaderStyle {
  "NORMAL",
  "LOGIN",
  "CHECKOUT",
  "CARTPAGE",
  "AFFILIATE",
}

export interface HeaderProps {
  headerStyle: HeaderStyle
}
