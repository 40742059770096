// TODO : Optmize this to remove so many styles
export enum BuycepsLogoStyle {
  SMALL,
  MEDIUM,
  LARGE,
  SMALL_WITH_AUTHENTICITY,
  MEDIUM_WITH_AUTHENTICITY,
  LARGE_WITH_AUTHENTICITY,
  SMALL_WITH_MESSAGE,
  MEDIUM_WITH_MESSAGE,
  LARGE_WITH_MESSAGE,
  CART_PAGE,
}

export interface BuycepsLogoProps {
  logoStyle: BuycepsLogoStyle
}
