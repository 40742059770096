import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client"
import { BatchHttpLink } from "@apollo/client/link/batch-http"
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist"
import { relayStylePagination } from "@apollo/client/utilities"
import { errorLink } from "@core/apolloLinks/onError"
import { graphCdnUrl } from "@temp/constants"
import { authLink } from "@temp/core/auth"
import merge from "deepmerge"
import isEqual from "lodash/isEqual"
import { useMemo } from "react"

let apolloClient: ApolloClient<NormalizedCacheObject>

// const routingLink = new RetryLink().split(
//   (operation) => operation.getContext().skipCDN,
//   new BatchHttpLink({
//     uri: apiUrl,
//   }),
//   new BatchHttpLink({
//     uri: graphCdnUrl,
//   })
// )

const headerLink = ApolloLink.split(
  (operation) => operation.getContext().skipCDN,
  new BatchHttpLink({
    uri: graphCdnUrl,
    headers: {
      "X-BC-Release": process.env.NEXT_PUBLIC_COMMIT_SHA || "",
      "X-Bypass-CDN": "1",
    },
  }),
  new BatchHttpLink({
    uri: graphCdnUrl,
    headers: { "X-BC-Release": process.env.NEXT_PUBLIC_COMMIT_SHA || "" },
  })
)

const link = ApolloLink.from([authLink, errorLink, headerLink])

const inMemoryCacheConfig = {
  typePolicies: {
    Brand: {
      fields: {
        logo: {
          merge: true,
        },
      },
    },
    Checkout: {
      fields: {
        shippingPrice: {
          merge: true,
        },
        subtotalPrice: {
          merge: true,
        },
        totalPrice: {
          merge: true,
        },
      },
    },
    CheckoutLine: {
      fields: {
        price: {
          merge: true,
        },
        totalPrice: {
          merge: true,
        },
      },
    },
    Navigation: {
      keyFields: [],
    },
    ComboProduct: {
      fields: {
        pricing: {
          merge: true,
        },
      },
    },
    ComboProductVariation: {
      fields: {
        pricing: {
          merge: true,
        },
      },
    },
    Order: {
      fields: {
        total: {
          merge: true,
        },
      },
    },
    Product: {
      fields: {
        pricing: {
          merge: true,
        },
        thumbnail: {
          merge: true,
        },
      },
    },
    ProductVariant: {
      fields: {
        pricing: {
          merge: true,
        },
      },
    },
    Query: {
      fields: {
        shop: {
          merge: true,
        },
        me: {
          merge: true,
        },
        purchasables: {
          merge: true,
        },
      },
    },
    Shop: {
      fields: {
        navigation: {
          merge: true,
        },
      },
      keyFields: [],
    },
    User: {
      fields: {
        orders: relayStylePagination(),
      },
    },
  },
}

const cache = new InMemoryCache(inMemoryCacheConfig)

const createApolloClient = (): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    cache,
    link,
    ssrMode: typeof window === "undefined", // set to true for SSR
  })

// Set up cache persistence.
if (typeof window !== "undefined") {
  persistCache({
    cache,
    maxSize: 512_000,
    debounce: 1000 * 60 * 60 * 24, // refresh cache every 24 hrs
    storage: new LocalStorageWrapper(window.localStorage),
  })
}

export const initializeApollo = ({
  initialState = null,
}: {
  initialState?: NormalizedCacheObject | null
} = {}): ApolloClient<NormalizedCacheObject> => {
  const localApolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = localApolloClient.cache.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    })

    // Restore the cache with the merged data
    localApolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return localApolloClient

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = localApolloClient

  return localApolloClient
}

export const useApollo = (
  initialState: NormalizedCacheObject
): ApolloClient<NormalizedCacheObject> =>
  useMemo<ApolloClient<NormalizedCacheObject>>(
    () => initializeApollo({ initialState }),
    [initialState]
  )
