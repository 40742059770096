import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

import { IProps } from "./types"

const borderColors = () => ({
  action: C.theme.error,
  error: C.theme.error,
  neutral: C.theme.primaryDark,
  success: C.theme.success,
})

export const Wrapper = styled.div<{ status: IProps["status"] }>`
  width: 21rem;
  padding: 1rem 1.5rem;
  background-color: ${C.white};
  box-shadow: 0px 6px 15px 3px rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: ${C.spacing.spacer};
  right: ${C.spacing.spacer};
  border-left: 0.4rem solid;
  border-color: ${(props) => borderColors()[props.status!]};
`

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.p`
  text-transform: uppercase;
  font-weight: 0 ${C.spacer * 1.5}rem 0 0;
  letter-spacing: 0.5px;
  margin: 0 ${C.spacer * 1.5}rem 0 0;
`

export const CloseButton = styled.button`
  cursor: pointer;

  path {
    transition: 0.3s;
  }

  &:hover {
    path {
      fill: ${C.colorPrimary};
    }
  }
`

export const Content = styled.div`
  margin: ${C.spacer}rem 0 0;
`

export const ActionButton = styled.button`
  color: ${C.colorGray3};
  cursor: pointer;
  font-size: ${C.baseFontSize};
  text-decoration: underline;
`
