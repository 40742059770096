var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"dd53afe926a978bbfa3b13aabd26dd240311fb37"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

let environment;
if(process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
  environment = "production";
}
else if(process.env.NEXT_PUBLIC_VERCEL_ENV === "preview") {
  const backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL;
  if(backendUrl.includes("alpha")) {
    environment = "alpha";
  }
  else if(backendUrl.includes("beta")) {
    environment = "beta";
  }
  else if(backendUrl.includes("charlie")) {
    environment = "charlie";
  }
  else if(backendUrl.includes("delta")) {
    environment = "delta";
  }
}
else if(process.env.NEXT_PUBLIC_VERCEL_ENV === "development") {
  environment = "development";
}

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://d546fc974db44320b524f40e3b4e92a6@o256215.ingest.sentry.io/1829472",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.02,
  // tunnel: "/api/tunnel",
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment,
})
