import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const FooterUpperContainer = styled.div<{ isMobileScreen?: boolean }>`
  grid-gap: ${C.spacing.spacer} * 2;
  ${(props) => `
    ${
      props.isMobileScreen
        ? `
    grid-template-columns: 1fr;
    grid-gap: ${C.spacing.spacer};
    `
        : `
    display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    `
    }
  `}
`

FooterUpperContainer.displayName = "S.FooterUpperContainer"

export const FooterUpperLogoSection = styled.div``
FooterUpperLogoSection.displayName = "S.FooterUpperLogoSection"

export const FooterUpperLogoSectionLogo = styled.div<{
  isMobileScreen?: boolean
}>`
  ${(props) => `
    ${
      props.isMobileScreen
        ? `
      svg {
        transform: scale(0.8);
        margin-left: -12px;
      }
    `
        : `
    
    `
    }
  `}
`
FooterUpperLogoSectionLogo.displayName = "S.FooterUpperLogoSectionLogo"

export const FooterUpperLogoMessage = styled.h6<{ isMobileScreen?: boolean }>`
  text-align: left;
  font-family: ${C.baseFontFamily};
  font-weight: ${C.normalFontWeight};
  letter-spacing: 0;
  color: ${C.white};

  ${(props) => `
    ${
      props.isMobileScreen
        ? `font-size: ${C.mobileCaptionsFontSize};`
        : `font-size: ${C.fontSizeCaptions};`
    }
  `}
`
FooterUpperLogoMessage.displayName = "S.FooterUpperLogoMessage"

export const FooterUpperLogoPhone = styled.div<{ isMobileScreen?: boolean }>`
  ${(props) => `
    ${props.isMobileScreen ? `padding: 1.2rem 0;` : `padding: 2.4rem 0;`}
  `}
`
FooterUpperLogoPhone.displayName = "S.FooterUpperLogoPhone"

export const FooterUpperLogoAddress = styled.div<{
  isMobileScreen?: boolean
  isIPadScreen?: boolean
}>`
  text-align: left;
  font-family: ${C.baseFontFamily};
  font-size: ${C.fontSizeParagraph2};
  font-weight: ${C.normalFontWeight};
  letter-spacing: 0;
  color: ${C.colorGray2};
  padding: 1.375rem 112px 1.375rem 0;

  ${(props) => `
    ${
      props.isMobileScreen
        ? `
    padding: 1rem 1.375rem 1rem 0;
    font-size: ${C.mobileParagraph2FontSize};
    `
        : props.isIPadScreen
        ? `padding: 1.375rem 1.375rem 1.375rem 0;`
        : `padding: 1.375rem 112px 1.375rem 0px;`
    }
  `}
`
FooterUpperLogoAddress.displayName = "S.FooterUpperLogoAddress"

export const FooterUpperLinkSection = styled.div<{ isMobile?: boolean }>`
  ${(props) => `
    ${props.isMobile ? `display: none;` : ``}
  `}
`
FooterUpperLinkSection.displayName = "S.FooterUpperLinkSection"

export const FooterUpperLinkHeader = styled.h4`
  height: 30px;
  text-align: left;
  font-family: ${C.baseFontFamily};
  font-weight: ${C.semiBoldFontWeight};
  font-size: ${C.fontSizeParagraph2};
  letter-spacing: 0;
  color: ${C.white};
`
FooterUpperLinkHeader.displayName = "S.FooterUpperLinkHeader"

export const FooterUpperLinkContent = styled.div`
  text-align: left;
  font-family: ${C.baseFontFamily};
  font-size: ${C.fontSizeCaptions};
  font-weight: ${C.normalFontWeight};
  letter-spacing: 0;
  color: ${C.colorGray2};

  a {
    line-height: 1.8rem;
  }
`
FooterUpperLinkContent.displayName = "S.FooterUpperLinkContent"

export const FooterUpperMobile = styled.div<{ isMobileScreen?: boolean }>`
  ${(props) => `
    ${
      props.isMobileScreen
        ? `
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${C.spacing.spacer};
    `
        : `display: none;`
    }
  `}
`
FooterUpperMobile.displayName = "S.FooterUpperMobile"

export const FooterUpperMobileLinkSection = styled.div``
FooterUpperMobileLinkSection.displayName = "S.FooterUpperMobileLinkSection"

export const FooterUpperMobileLinkSectionHeader = styled.h4<{
  isMobileScreen?: boolean
}>`
  height: 30px;
  text-align: left;
  font-family: ${C.baseFontFamily};
  letter-spacing: 0;
  color: ${C.white};
  display: flex;
  ${(props) => `
    ${
      props.isMobileScreen
        ? `
    font-size: ${C.mobileSubTitleFontSize};
    font-weight: ${C.semiBoldFontWeight};
    `
        : `
    font-weight: ${C.semiBoldFontWeight};
    font-size: ${C.fontSizeParagraph2};
    `
    }
  `}
`
FooterUpperMobileLinkSectionHeader.displayName =
  "S.FooterUpperMobileLinkSectionHeader"

export const FooterUpperMobileLinkSectionContent = styled.p<{
  isMobileScreen?: boolean
}>`
  text-align: left;
  font-family: ${C.baseFontFamily};
  font-weight: ${C.normalFontWeight};

  letter-spacing: 0;
  color: ${C.colorGray2};
  line-height: 1.8rem;
  ${(props) => `
    ${
      props.isMobileScreen
        ? `font-size: ${C.mobileCaptionsFontSize};`
        : `font-size: ${C.fontSizeCaptions};`
    }
  `}
`
FooterUpperMobileLinkSectionContent.displayName =
  "S.FooterUpperMobileLinkSectionContent"

export const FooterUpperMobileLinkSectionHeaderarrow = styled.div<{
  isActive: boolean
}>`
  transition: 300ms;
  height: 24px;
  padding: 0 0.5rem;
  transform: ${(props) => (props.isActive ? `rotate(180deg)` : `rotate(0deg)`)};
`
FooterUpperMobileLinkSectionHeaderarrow.displayName =
  "S.FooterUpperMobileLinkSectionHeaderarrow"
