import { ExclusiveBrand } from "./@next/components/atoms/ProductsByExclusiveListItem/types"

export const apiUrl = process.env.NEXT_PUBLIC_BACKEND_URL
  ? `${process.env.NEXT_PUBLIC_BACKEND_URL}graphql`
  : "/graphql"
export const graphCdnUrl = process.env.NEXT_PUBLIC_GRAPHCDN_URL || apiUrl
export const serviceWorkerTimeout =
  Number.parseInt(process.env.NEXT_PUBLIC_SERVICE_WORKER_TIMEOUT!, 10) || 60 * 1000
export const phoneNumberRegex = /^\+\d*$|^\d*$/
export const emailRegex =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/
export const upiRegex = /^[\w.-]{3,}@[\w.-]{3,}$/
export const SHIPPING_METHOD_FREE = "FREE"
export const SHIPPING_METHOD_CASH_ON_DELIVERY = "COD"
export const SHIPPING_METHOD_OUTSIDE = "ODA"
export const SHIPPING_METHOD_OUTSIDE_DELIVERY_COD = "ODA_COD"
export const SHIPPING_METHOD_ZERO_CART = "ZERO_CART"
export const BRAND_PAGE_DEFAULT_BANNER_IMAGE =
  "https://cdn.buyceps.com/brand-backgrounds/buyceps-brand-page-default-banner.jpg"
export const BRAND_PAGE_DEFAULT_MOBILE_BANNER_IMAGE =
  "https://cdn.buyceps.com/brand-backgrounds/buyceps-brand-page-default-mobile-banner.jpg"

// Allowed brands for authenticity
export const AUTHENTICITY_BRANDS = [
  "bpi sports",
  "bsn",
  "scivation",
  "on (optimum nutrition)",
  "dymatize",
  "gnc",
  "mutant",
  "muscletech",
  "musclepharm",
  "muscleblaze",
  "labrada",
  "universal nutrition",
  "ultimate nutrition",
  "isopure",
]

export const mobileSortOptions = [
  { id: "name", name: "Name A-Z", value: "name" },
  { id: "-name", name: "Name Z-A", value: "-name" },
  { id: "popularity", name: "Most Popular", value: "popularity" },
  { id: "-popularity", name: "Least Popular", value: "-popularity" },
  { id: "price", name: "Price Low-High", value: "price" },
  { id: "-price", name: "Price High-Low", value: "-price" },
]

// Enums
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum GRAPHQL_EXCEPTIONS {
  DUPLICATE_KEY = "duplicate key",
  INVALID_PHONE_NUMBER = "not a valid phone number",
}

export const ENTER_KEY = 13
export const VEG_NONVEG_ATTRIBUTE_KEY = "QXR0cmlidXRlOjQ="
export const BRAND_ATTRIBUTE_KEY = "QXR0cmlidXRlOjY="
export const BRAND_QUERY_KEY = "supplement-brand"
export const PREFERENCE_QUERY_KEY = "supplement-vegetarian-non-vegetarian"
export const menuListWidth = 216
export const menuListPadding = 76
export const menuListBreakpoint = 10
export const ellipsis = "…"
export const VARIANT_QUERY_PARAM = "variant"
export const NEAR_EXPIRY_QUERY_PARAM = "nearExpiry"

interface AvailableVoucherCode {
  code: string
  name: string
  description: string
  isApplicable: (
    subTotal: number,
    brandId?: string | null,
    productId?: string | null
  ) => boolean
  getDiscount: (subTotal: number) => number
}

export const AVAILABLE_VOUCHER_CODES: AvailableVoucherCode[] = []
export const googleMap = "AIzaSyDHVB4qEj7wqnnTF_CjayuWvrlE40f9MKI"
export const mapStyle = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#46bcec",
      },
      {
        visibility: "on",
      },
    ],
  },
]

// Events Enum
export const PURCHASE_EVENT = "Purchase"
export const ADD_PAYMENT_INFO = "AddPaymentInfo"
export const INITIATE_TRANSACTION = "InitiateTransaction"
export const INITIATE_CHECKOUT = "InitiateCheckout"
export const COMPLETE_TRANSACTION = "CompleteTransaction"
export const ADD_TO_CART = "AddToCart"
export const CREATE_SHIPPING_ADDRESS = "CreateShippingAddress"
export const EDIT_SHIPPING_ADDRESS = "EditShippingAddress"
export const EDIT_BILLING_ADDRESS = "EditBillingAddress"
export const INITIATE_CREATE_SHIPPING_ADDRESS = "InitiateCreateShippingAddress"
export const INITIATE_EDIT_SHIPPING_ADDRESS = "InitiateEditShippingAddress"
export const CREATE_BILLING_ADDRESS = "CreateBillingAddress"
export const INITIATE_CREATE_BILLING_ADDRESS = "InitiateCreateBillingAddress"
export const INITIATE_EDIT_BILLING_ADDRESS = "InitiateEditBillingAddress"
export const COUPON_APPLIED = "CouponApplied"
export const COUPON_REMOVED = "CouponRemoved"

export const exclusiveBrands: ExclusiveBrand[] = [
  {
    backgroundUrl: "/images/home/exclusive/other-format/need.png",
    redirectUrl: "brand/need-supplements/51/",
    webpBackgroundUrl: "/images/home/exclusive/webp/need.webp",
    alt: "Exclusive brand Need Banner",
  },
  {
    backgroundUrl: "/images/home/exclusive/other-format/divine.png",
    redirectUrl: "brand/divine-nutrition/86/",
    webpBackgroundUrl: "/images/home/exclusive/webp/divine.webp",
    alt: "Exclusive brand InnovaPharm Banner",
  },
  {
    backgroundUrl: "https://cdn.buyceps.com/brands/primevel-feature-brand.png",
    redirectUrl: "brand/primeval-labs/100",
    webpBackgroundUrl: "https://cdn.buyceps.com/brands/primevel-feature-brand.webp",
    alt: "Exclusive brand Primeval Labs Banner",
  },
  {
    backgroundUrl: "/images/home/exclusive/other-format/bodyfirst.jpg",
    redirectUrl: "brand/body-first/10/",
    webpBackgroundUrl: "/images/home/exclusive/webp/bodyfirst.webp",
    alt: "Exclusive brand Body First Banner",
  },
]

export const getJuspayPaymentMethodsUrl = `${process.env.NEXT_PUBLIC_JUSPAY_URL}/merchants/buyceps/paymentmethods`
export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

export const vapidKey = process.env.NEXT_PUBLIC_FIREBASE_VAPIDKEY

export const seoDefaultTitle =
  "Buyceps - India's #1 Authentic Online Sports Nutrition & Supplements Store"

export const seoDefaultDescription =
  "India's most trusted Online store for Authentic Bodybuilding Health Supplements, Diet Sports Nutrition Products for all Fitness Levels. Buy Online Authentic proteins & vitamins at best price from biggest brands in India. Free Shipping all India."
