import { smallScreen } from "@temp/@next/globalStyles/constants"
import styled from "styled-components"

export const ErrorModalContainer = styled.div`
  padding: 12px;
  min-height: 70vh;
  position: relative;
`

export const ErrorModalHeading = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: #111;
  margin-bottom: 20px;
  padding: 8px 0;
`

export const ErrorModalPoint = styled.span`
  font-weight: 600;
  color: #666;
  margin-right: 8px;
`

export const ErrorModalRow = styled.p`
  margin-bottom: 12px;
  word-wrap: break-word;
  textarea {
    height: 4rem;
  }
`

export const ErrorModalInfo = styled.div`
  border: solid 1px #999;
  border-radius: 12px;
  padding: 8px;
  height: 180px;
  overflow-y: scroll;
  font-size: 14px;
  word-wrap: break-word;
  background: #e2e8f0;
`

export const ErrorButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: ${smallScreen}px) {
    position: fixed;
    justify-content: space-between;
    left: 0;
    bottom: 0px;
    padding: 10px 20px;
  }
  padding: 10px 0;
  width: 100%;
  background: #fff;
`

export const ErrorWhatsappButton = styled.a`
  color: #fff;
  background: #10b981;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  gap: 4px;
  align-items: center;
`

export const ErrorGmailButton = styled.a`
  color: #fff;
  background: #60a5fa;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  gap: 4px;
  align-items: center;
`
