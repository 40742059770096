import { onError } from "@apollo/client/link/error"

// Log any GraphQL errors or network error that occurred
export const errorLink = onError(
  ({ operation, response, graphQLErrors, networkError }) => {
    if (graphQLErrors)
      for (const { message, locations, path } of graphQLErrors)
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }
    console.log(operation)
    console.log(response)
  }
)
