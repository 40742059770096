import styled from "styled-components"

export const ErrorWrapper = styled.div`
  max-width: 600px;
  padding: 16px;
  border: solid 1px #d1d5db;
  color: #000;
  border-radius: 8px;
  background: #f3f4f6;
  margin: 0 auto;
`

export const ErrorTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
`

export const ErrorMessage = styled.p`
  font-weight: 500;
  font-size: 14px;
`

export const ErrorButton = styled.button`
  color: #374151;
  background: #d1d5db;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
`

export const ErrorButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
