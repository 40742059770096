import React from "react"

import { icons } from "./definitions"
import { IProps } from "./types"

const getPathColor = (color: string | string[], index: number) => {
  if (typeof color === "string") {
    return color
  }

  return color[index] ? color[index] : "inherit"
}

// TODO : Replace iconName with a missing icon
export const Icon: React.FC<IProps> = ({
  size = 32,
  color,
  iconName = "edit",
}: IProps) => {
  const icon = icons[iconName]
  return (
    <svg height={size} viewBox={`0 0 ${size} ${size}`} width={size}>
      {icon &&
        icon.map((path, index) => (
          <path
            d={path.d}
            fill={color ? getPathColor(color, index) : path.fill}
            stroke={path.stroke}
            transform={path.transform}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}
    </svg>
  )
}

export type { IProps as IIcon }
