import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const MainMenuContainer = styled.header<{
  isProductPage: boolean
}>`
  height: auto;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 4;
  transform: translateY(-0%);
  transition: transform 0.2s ease-in-out;
  margin-bottom: ${(props) => (props.isProductPage ? "800px" : "0px")};

  &.scrolled {
    transform: translateY(-100%);
  }
`
MainMenuContainer.displayName = "S.MainMenuContainer"

export const MainMenuDesktopLogo = styled.div`
  height: 36px;
  margin: 0 20px 0 0;
`
MainMenuDesktopLogo.displayName = "S.MainMenuDesktopLogo"

export const MainMenuDesktopContainer = styled.div`
  display: block;
  height: 100%;
`
MainMenuDesktopContainer.displayName = "S.MainMenuDesktopContainer"

export const MainMenuDesktopAccountWrapper = styled.div`
  position: relative;

  ul {
    padding: 16px;
  }
`
MainMenuDesktopAccountWrapper.displayName = "S.MainMenuDesktopAccountWrapper"

export const MainMenuDesktopAccountLinks = styled.li`
  font-family: ${C.baseFontFamily};
  color: ${C.v2Theme.fontColors.black};
  font-size: ${C.v2Theme.fontSizes.paragraph1};
  font-weight: ${C.v2Theme.fontWeights.regular};
`
MainMenuDesktopAccountLinks.displayName = "S.MainMenuDesktopAccountLinks"

export const MainMenuDesktopMenuActionDividerContainer = styled.div`
  height: 33px;
  opacity: 0.3;
`
MainMenuDesktopMenuActionDividerContainer.displayName =
  "S.MainMenuDesktopMenuActionDividerContainer"

export const MainMenuUpperSection = styled.div`
  z-index: 5;
  display: flex;
  align-items: center;
  position: relative;
  background: ${C.v2Theme.colors.primaryColor1};
  height: 64px;
  padding: 0 24px 0 20px;
  box-shadow: ${C.v2Theme.shadows.shadow2};
`
MainMenuUpperSection.displayName = "S.MainMenuUpperSection"

export const MainMenuLowerSection = styled.div<{ isDesktop: boolean }>`
  ${(props) => `
    height: 100%;
    position: relative;
    z-index: 4;
    background: ${C.v2Theme.colors.primaryColor1};
    height: 54px;
    box-shadow: none;
    padding: ${props.isDesktop ? "0 20px 0 48px" : "12px 18px"};
  `}
`
MainMenuLowerSection.displayName = "S.MainMenuLowerSection"

export const MainMenuMobileHamburgerIcon = styled.div`
  div {
    height: 18px;
  }
`
MainMenuMobileHamburgerIcon.displayName = "S.MainMenuMobileHamburgerIcon"

export const MainMenuMobileLogo = styled.div`
  height: 32px;
  margin: 0 0 0 12px;
`
MainMenuMobileLogo.displayName = "S.MainMenuMobileLogo"

export const MainMenuMobileMenuActions = styled.div`
  justify-self: flex-end;
  margin-left: auto;
  display: flex;
  align-items: center;
`
MainMenuMobileMenuActions.displayName = "S.MainMenuMobileMenuActions"
