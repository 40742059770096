import styled from "styled-components"
import { containerWidth } from "@temp/@next/globalStyles/constants"

export const ContainerWrapper = styled.div<{ backgroundColor: string }>`
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  background: ${(props) => props.backgroundColor};
`
ContainerWrapper.displayName = "S.ContainerWrapper"

export const Container = styled.div<{ screen: string }>`
  width: ${(props) =>
    props.screen === "medium" ? "100%" : `${containerWidth.width[props.screen]}`};
`
Container.displayName = "S.Container"
