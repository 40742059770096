import {
  ButtonCurve,
  ButtonSize,
  ButtonStyle,
  FontSize,
} from "@components/atoms/Button/types"
import styled from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

const buttonStyled = {
  height: {
    large: "3.375rem",
    medium: "3.25rem",
    codSecond: "2.625rem",
    cod: "48px",
    small: "38px",
    x_small: "24px",
  },
  radius: {
    large: "1rem",
    none: "0",
    normal: "0.375rem",
    small: "2px",
  },
  styles: {
    addAddress: {
      activeBackground: C.dividerColor,
      background: C.theme.disabled,
      border: "none",
      disabledColor: C.disabledColor,
      fontColor: C.theme.dark,
      fontSize: C.fontSizeSubHeader,
      hoverBackground: C.dividerColor,
      hoverColor: C.theme.dark,
    },
    previous: {
      activeBackground: C.theme.secondaryButtonDark,
      background: C.theme.secondaryButton,
      border: "none",
      disabledColor: C.disabledColor,
      fontColor: C.theme.dark,
      hoverBackground: C.theme.primary,
      hoverColor: C.theme.dark,
    },
    alternate: {
      activeBackground: C.theme.alternateButtonDark,
      background: C.theme.alternateButton,
      border: "none",
      disabledColor: C.disabledColor,
      fontColor: C.theme.white,
      hoverBackground: C.theme.alternateButtonDark,
      hoverColor: C.white,
    },
    gray: {
      activeBackground: C.theme.grayButtonDark,
      background: C.theme.grayButton,
      border: "none",
      disabledColor: C.disabledColor,
      fontColor: C.theme.dark,
      hoverBackground: C.theme.grayButtonDark,
      hoverColor: C.theme.dark,
    },
    unboxingWithIcon: {
      activeBackground: C.theme.primaryDark,
      background: C.v2Theme.colors.primaryColor1,
      border: "none",
      disabledColor: C.disabledColor,
      fontColor: C.theme.dark,
      hoverBackground: C.theme.primaryDark,
      hoverColor: C.theme.dark,
      height: "32px",
    },
    unboxingUploaded: {
      activeBackground: C.theme.grayButtonDark,
      // background: C.theme.grayButton,
      border: "1px solid #CAD5E5",
      borderRadius: "7px",
      disabledColor: C.disabledColor,
      fontColor: C.theme.dark,
      // hoverBackground: C.theme.grayButtonDark,
      hoverColor: C.theme.dark,
      height: "32px",
    },
    grayWithoutIcon: {
      activeBackground: C.theme.grayButtonDark,
      background: C.theme.grayButton,
      border: "none",
      disabledColor: C.disabledColor,
      fontColor: C.theme.dark,
      hoverBackground: C.theme.grayButtonDark,
      hoverColor: C.theme.dark,
    },
    primary: {
      activeBackground: C.theme.primaryDark,
      background: C.v2Theme.colors.primaryColor1,
      border: "none",
      disabledColor: C.disabledColor,
      fontColor: C.theme.white,
      hoverBackground: C.theme.primaryDark,
      hoverColor: C.white,
      extraLight: C.theme.primaryExtraLight, // fffdef
    },
    secondary: {
      activeBackground: C.theme.secondaryButtonDark,
      background: C.theme.secondaryButton,
      border: "none",
      disabledColor: C.disabledColor,
      fontColor: C.theme.dark,
      hoverBackground: C.theme.secondaryButtonDark,
      hoverColor: C.theme.dark,
    },
    buyNow: {
      activeBackground: C.theme.secondaryButtonDark,
      background: C.theme.darkBlue,
      border: "none",
      disabledColor: C.disabledColor,
      fontColor: C.theme.dark,
      hoverBackground: C.theme.secondaryButtonDark,
      hoverColor: C.theme.dark,
    },
    notfound: {
      activeBackground: C.theme.secondaryButtonLight,
      background: C.theme.secondaryButtonLight,
      border: "none",
      disabledColor: C.disabledColor,
      fontColor: C.theme.dark,
      hoverBackground: C.theme.primary,
      hoverColor: C.theme.dark,
    },
    social: {
      activeBackground: C.theme.socialButtonDark,
      background: C.theme.socialButton,
      border: C.theme.border,
      disabledColor: C.disabledColor,
      fontColor: C.theme.basePlaceholderFontColor,
      fontSize: C.fontSizeParagraph1,
      hoverBackground: C.theme.socialButtonDark,
      hoverColor: C.theme.basePlaceholderFontColor,
    },
    grayisBlue: {
      activeBackground: C.theme.buyNow,
      background: C.theme.buyNow,
      border: "none",
      disabledColor: C.disabledColor,
      fontColor: C.theme.dark,
      hoverBackground: C.theme.buyNow,
      hoverColor: C.theme.buyNow,
    },
    rateProduct: {
      activeBackground: C.theme.buyNow,
      background: C.theme.buyNow,
      border: "none",
      disabledColor: C.disabledColor,
      fontColor: C.theme.dark,
      hoverBackground: C.theme.grayButtonDark,
      hoverColor: C.theme.buyNow,
      height: "28px",
    },
    rateDelivery: {
      activeBackground: C.theme.secondaryButtonDark,
      // background: C.theme.secondaryButton,
      border: "1px solid #CAD5E5;",
      borderRadius: "7px",
      disabledColor: C.disabledColor,
      fontColor: C.theme.dark,
      hoverBackground: C.theme.white,
      hoverColor: C.theme.dark,
      height: "28px",
    },
  },
}

export const Primary = styled.button<{
  buttonStyle: ButtonStyle
  buttonCurve: ButtonCurve
  buttonSize: ButtonSize
  buttonWidth: string
  buttonTopBottomMargin: string
}>`
  background-color: ${(props) => buttonStyled.styles[props.buttonStyle].background};
  border: ${(props) => buttonStyled.styles[props.buttonStyle].border};
  border-radius: ${(props) => buttonStyled.radius[props.buttonCurve]};
  transition: 0.3s;
  outline: none;
  font-family: ${C.baseFontFamily};
  cursor: pointer;
  color: ${(props) => buttonStyled.styles[props.buttonStyle].fontColor};
  height: ${(props) =>
    buttonStyled.styles[props.buttonStyle].height ||
    buttonStyled.height[props.buttonSize]};
  margin: ${(props) => `${props.buttonTopBottomMargin}`} 0;
  width: ${(props) => `${props.buttonWidth}`};
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.6;

    &,
    &:hover {
      cursor: default;
      background-color: ${(props) =>
        buttonStyled.styles[props.buttonStyle].hoverBackground};
      color: ${(props) => buttonStyled.styles[props.buttonStyle].hoverColor};
    }
  }

  @media (min-width: ${C.mediumScreen}px) {
    &:hover {
      background-color: ${(props) =>
        buttonStyled.styles[props.buttonStyle].hoverBackground};
      color: ${(props) => buttonStyled.styles[props.buttonStyle].hoverColor};
    }
  }
`

export const Secondary = styled(Primary)`
  background-color: ${buttonStyled.styles.secondary.background};
`
export const Notfound = styled(Primary)`
  background-color: ${buttonStyled.styles.notfound.background};
`

export const Alternate = styled(Primary)`
  background-color: ${buttonStyled.styles.alternate.background};
`

export const Gray = styled(Primary)`
  background-color: ${buttonStyled.styles.gray.background};
`

export const Social = styled(Primary)`
  background-color: ${buttonStyled.styles.social.background};
`

export const AddAddress = styled(Primary)`
  background-color: ${buttonStyled.styles.addAddress.background};
`
export const Previous = styled(Primary)`
  background-color: ${buttonStyled.styles.previous.background};
`

export const GrayisBlue = styled(Primary)`
  background-color: ${buttonStyled.styles.grayisBlue.background};
`

export const Text = styled.span<{
  buttonSize?: ButtonSize
  fontSize?: FontSize
  fontWeight?: string
  textTransform?: string
}>`
  display: inline-block;
  font-family: ${C.baseFontFamily};
  color: ${C.v2Theme.fontColors.black};
  font-size: ${(props) => `${props.fontSize}rem`};
  font-weight: ${(props) => `${props.fontWeight}`};
  line-height: ${C.baseLineHeight};
  text-transform: ${(props) => `${props.textTransform}`};
`

export const GrayText = styled.span<{ fontSize?: FontSize }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${C.spacing.spacer};
  padding-right: ${C.spacing.spacer};
  font-family: ${C.baseFontFamily};
  color: ${buttonStyled.styles.gray.fontColor};
  font-size: ${(props) => `${props.fontSize}rem`};
  font-weight: ${C.semiBoldFontWeight};
  line-height: ${C.baseLineHeight};
`
export const UnboxingText = styled.span<{ fontSize?: FontSize }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${C.spacing.spacer};
  padding-right: ${C.spacing.spacer};
  font-family: ${C.baseFontFamily};
  color: ${buttonStyled.styles.gray.fontColor};
  font-size: ${(props) => `${props.fontSize}rem`};
  font-weight: ${C.semiBoldFontWeight};
  line-height: ${C.baseLineHeight};
  text-decoration: underline;
`

export const AlternateText = styled.span<{ fontSize?: FontSize }>`
  display: inline-block;
  font-family: ${C.baseFontFamily};
  color: ${C.white};
  font-size: ${(props) => `${props.fontSize}rem`};
  font-weight: ${C.semiBoldFontWeight};
  line-height: ${C.baseLineHeight};
`

export const AddAddressText = styled.span<{
  buttonSize?: ButtonSize
  fontSize?: FontSize
}>`
  display: inline-block;
  font-family: ${C.baseFontFamily};
  color: ${C.v2Theme.fontColors.black};
  font-size: ${(props) => `${props.fontSize}rem`};
  font-weight: ${C.normalFontWeight};
  line-height: ${C.baseLineHeight};
`

export const SocialText = styled.span`
  display: grid;
  grid-template-columns: 1fr 4fr;
  padding-left: 1rem;
  text-align: start;
  grid-gap: 0.875rem;
  font-family: ${C.baseFontFamily};
  color: ${buttonStyled.styles.social.fontColor};
  font-size: ${buttonStyled.styles.social.fontSize};
  font-weight: ${C.lightFontWeight};
  line-height: ${C.baseLineHeight};
`

export const GrayWithoutIconText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${C.baseFontFamily};
  color: ${buttonStyled.styles.gray.fontColor};
  font-size: ${C.fontSizeSubTitle};
  text-transform: capitalize;
  font-weight: ${C.semiBoldFontWeight};
  line-height: ${C.baseLineHeight};
`
GrayWithoutIconText.displayName = "S.GrayWithoutIconText"

export const GrayisBlueText = styled(GrayWithoutIconText)`
  color: ${C.v2Theme.fontColors.black};
  font-weight: ${C.v2Theme.fontWeights.regular};
`

export const SocialIconContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    transform: scale(0.65);
  }
`

export const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;
`
