import { createGlobalStyle } from "styled-components"
import * as C from "@temp/@next/globalStyles/constants"

export const GlobalStyle = createGlobalStyle`

  ::-webkit-scrollbar {
    width: 4px; /* Remove scrollbar space */
    height: 0px;
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    overflow:auto;
    min-width: 320px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${C.baseFontFamily};
    font-size: ${C.baseFontSize};
    line-height: ${C.baseLineHeight};
    color: ${C.theme.baseFontColor};
    background: ${C.v2Theme.backgroundColors.gradients1};
    scroll-behavior: smooth;
    overflow-y: auto;
    overflow-x: hidden;

    &.fc-widget-open {
      position: static;
    }
  }

  h1 {
    font-size: ${C.fontSizeSuperHeader};
    line-height: ${C.h1LineHeight};
  }

  h3 {
    font-size: ${C.fontSizeSubTitle};
    line-height: 1.7rem;
  }

  h4 {
    font-size: ${C.fontSizeParagraph1};
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  p {
    line-height: 1.5rem;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    border-radius: 4px;
  }
  
  /* reset css */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  
  ol, ul {
    list-style: none;
  }

  #__next {
    
    & > div:first-of-type {
      display: flex;
      min-height: 100vh;
      overflow: hidden;
      flex-direction: column;
      
      & > div:first-of-type {
        flex: 1;
        margin: auto;
      }
    }
  }
  
  /* animations */
  .fade-enter {
    opacity: 0;
    transition: opacity 300ms;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .fade-exit {
    opacity: 1;
    transition: opacity 300ms;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  /* nprogress css */
  #nprogress {
  pointer-events: none;
  }
  #nprogress .bar {
    background: #FF871A;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
  }
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #FF871A, 0 0 5px #FF871A;
    opacity: 1;
    transform: rotate(3deg) translate(0, -4px);
  }
  #nprogress .spinner {
    display: none;
  }
  #nprogress .spinner-icon {
    display: none;
  }
  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  #fc_frame {
    bottom: 15px;
  }

  #fc_frame.bottom-change {
    bottom: 88px !important;
  }

  #fc_frame.display-none {
    display: none !important;
  }

  #fc-icon.display-none {
    display: none !important;
  }

  #zestmoney-amount-info {
    display: none;
  }

  #zestmoney-widget-control {
    display: none;
  }

  #w369-widget-control {
    display: none;
  }

`
