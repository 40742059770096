import { SOCIAL_MEDIA } from "@temp/core/config"
import Link from "next/link"
import React from "react"

import StyledSocialMediaIcon from "../../atoms/SocialMediaIcon/StyledSocialMediaIcon"
import * as S from "./styles"

export const FooterLower: React.FC = () => (
  <S.FooterLowerContainer>
    <S.FooterLowerContainerCompanyName>
      @{new Date().getFullYear()} Inwelco Science Private Limited
    </S.FooterLowerContainerCompanyName>
    <S.FooterLowerContainerIcons>
      {SOCIAL_MEDIA.map((medium) => (
        <StyledSocialMediaIcon medium={medium} key={medium.ariaLabel} />
      ))}
    </S.FooterLowerContainerIcons>
    <S.FooterLowerContainerLinks>
      <Link href="/page/privacy_policy" passHref>
        <a target="_blank">Privacy Policy</a>
      </Link>
    </S.FooterLowerContainerLinks>
  </S.FooterLowerContainer>
)
