import { MemoizedHeader } from "@components/molecules/Header"
import { HeaderStyle } from "@components/molecules/Header/types"
import { MemoizedFooter } from "@components/templates/Footer/NewFooter"
import { withErrorBoundary } from "@sentry/nextjs"
import { MemoizedMainMenu } from "@temp/@next/components/organisms/MainMenu"
import { OverlayProvider } from "@temp/components/Overlay"
import { OverlayManager } from "@temp/components/OverlayManager"
import ShopProvider from "@temp/components/ShopProvider"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import React from "react"
import LazyHydrate from "react-lazy-hydration"
import { ErrorFallbackUi } from "../../molecules/ErrorFallbackUi/ErrorFallbackUi"
import { AffiliateHeader } from "../../organisms/AffiliateHeader/AffiliateHeader"

import { NextContainerProps } from "./types"

export const NextContainer: React.FC<NextContainerProps> = ({
  children,
  emptyHeader = false,
  orderConfirmation = false,
  forgotPassword = false,
  cartPage = false,
  reviewRating = false,
  affiliate = false,
  menuFacadeData,
}) => {
  const matches: GlobalMediaQueryResultInterface = getMedia()
  return (
    <ShopProvider>
      <OverlayProvider>
        <>
          {!emptyHeader &&
          !forgotPassword &&
          !cartPage &&
          !reviewRating &&
          !affiliate ? (
            <MemoizedMainMenu menuFacadeData={menuFacadeData} />
          ) : emptyHeader ? (
            <MemoizedHeader headerStyle={HeaderStyle.CHECKOUT} />
          ) : affiliate ? (
            <AffiliateHeader />
          ) : forgotPassword ? (
            <MemoizedHeader headerStyle={HeaderStyle.LOGIN} />
          ) : reviewRating ? null : (
            <MemoizedHeader
              headerStyle={
                matches.large ? HeaderStyle.CHECKOUT : HeaderStyle.CARTPAGE
              }
            />
          )}
          {children}
          {!emptyHeader &&
            !orderConfirmation &&
            !forgotPassword &&
            !cartPage &&
            !reviewRating && (
              <LazyHydrate whenVisible>
                <MemoizedFooter />
              </LazyHydrate>
            )}
          <OverlayManager />
        </>
      </OverlayProvider>
    </ShopProvider>
  )
}

export const NextContainerWithEB = withErrorBoundary(NextContainer, {
  fallback: ({ error }) => (
    <ErrorFallbackUi componentName="NextContainer" error={error} />
  ),
})
