export const icons = {
  account: [
    {
      d: "M1079 21c6.627 0 12 5.373 12 12s-5.373 12-12 12-12-5.373-12-12 5.373-12 12-12zm.398 14.54c-3.45 0-6.398.544-6.398 2.72 0 2.177 2.929 2.74 6.398 2.74 3.45 0 6.397-.543 6.397-2.72s-2.928-2.74-6.397-2.74zm0-10.54c-2.35 0-4.234 1.884-4.234 4.234s1.884 4.233 4.234 4.233 4.233-1.884 4.233-4.233c0-2.35-1.883-4.234-4.233-4.234z",
      fill: "#200e32",
      transform: "translate(-1067 -21)",
    },
  ],
  arrow_back: [
    {
      d: "M7.7,8l5.545,5.533L18.792,8l1.7,1.7-7.248,7.248L6,9.7Z",
      fill: "#000",
      transform: "translate(25.951 2) rotate(90)",
    },
  ],
  arrow_down: [
    {
      d: "M7.7,8l5.545,5.533L18.792,8l1.7,1.7-7.248,7.248L6,9.7Z",
      fill: "#000",
    },
  ],
  arrow_front: [
    {
      d: "M7.7,8l5.545,5.533L18.792,8l1.7,1.7-7.248,7.248L6,9.7Z",
      fill: "#000",
      transform: "translate(0 25) rotate(-90)",
    },
  ],
  arrow_up: [
    {
      d: "M7.7,8l5.545,5.533L18.792,8l1.7,1.7-7.248,7.248L6,9.7Z",
      fill: "#000",
      transform: "translate(25 25) rotate(180)",
    },
  ],
  authenticity: [
    {
      d: "M10.64,1,3,4.4V9.489a10.565,10.565,0,0,0,7.64,10.187,10.565,10.565,0,0,0,7.64-10.187V4.4Zm-1.7,13.583-3.4-3.4,1.2-1.2,2.2,2.19,5.594-5.594,1.2,1.205Z",
      fill: "#000",
      transform: "translate(-1, -1) scale(0.9)",
    },
  ],
  bag: [
    {
      d: "M17.093 24H5.907C2.65 24 0 21.397 0 18.197v-6.394C0 8.603 2.65 6 5.907 6h11.186C20.35 6 23 8.603 23 11.803v6.394c0 3.2-2.65 5.803-5.907 5.803",
      fill: "#200e32",
    },
    {
      d: "M16.09 10c-.503 0-.91-.405-.91-.903v-3.65c0-2.008-1.647-3.64-3.67-3.64-.93 0-1.907.37-2.603 1.053-.697.685-1.083 1.596-1.086 2.57v3.667c0 .498-.408.903-.91.903C6.407 10 6 9.595 6 9.097v-3.65c.006-1.48.584-2.846 1.625-3.868C8.665.554 10.011.039 11.515 0 14.537 0 17 2.443 17 5.446v3.65c0 .5-.408.904-.91.904",
    },
  ],
  billing: [
    {
      d: "M15,14H5.4V12.4H15Zm0-3.2H5.4V9.2H15Zm0-3.2H5.4V6H15ZM3,18l1.2-1.2L5.4,18l1.2-1.2L7.8,18,9,16.8,10.2,18l1.2-1.2L12.6,18l1.2-1.2L15,18l1.2-1.2L17.4,18V2L16.2,3.2,15,2,13.8,3.2,12.6,2,11.4,3.2,10.2,2,9,3.2,7.8,2,6.6,3.2,5.4,2,4.2,3.2,3,2Z",
      fill: "#000",
      transform: "scale(1.1)",
    },
  ],
  browse_arrow_front: [
    {
      d: "M10,0,20,17H0Z",
      fill: "#000",
      transform: "translate(17, 5) rotate(90)",
    },
  ],
  edit: [
    {
      d: "M15.001 9.478l-3.1 3.104c-1.142 1.144-2.291 2.287-3.426 3.444-0.268 0.274-0.47 0.64-0.548 1-0.411 1.889-0.809 3.777-1.207 5.666l-0.222 1.046c-0.104 0.51 0.033 1.020 0.378 1.366 0.268 0.268 0.64 0.418 1.031 0.418 0.111 0 0.222-0.013 0.333-0.033l1.149-0.242c1.827-0.386 3.661-0.778 5.488-1.157 0.424-0.092 0.783-0.281 1.090-0.588 5.13-5.143 10.259-10.28 15.388-15.411 0.378-0.379 0.587-0.83 0.64-1.386 0.006-0.091 0-0.183-0.020-0.268-0.033-0.131-0.059-0.268-0.091-0.399-0.072-0.333-0.15-0.712-0.307-1.078-0.959-2.196-2.552-3.764-4.731-4.647-0.437-0.176-0.9-0.235-1.305-0.288l-0.111-0.013c-0.587-0.072-1.122 0.124-1.586 0.595-2.937 2.954-5.893 5.915-8.843 8.869zM25.214 1.59c0.013 0 0.020 0 0.033 0l0.111 0.013c0.339 0.039 0.653 0.078 0.901 0.183 1.775 0.719 3.080 2 3.87 3.804 0.091 0.209 0.15 0.477 0.209 0.758 0.020 0.105 0.046 0.209 0.065 0.314-0.026 0.118-0.072 0.196-0.163 0.281-5.136 5.13-10.265 10.274-15.395 15.411-0.085 0.085-0.163 0.124-0.281 0.15-1.834 0.386-3.661 0.771-5.495 1.157l-0.966 0.203 0.183-0.856c0.398-1.882 0.796-3.771 1.201-5.653 0.013-0.059 0.065-0.15 0.124-0.216 1.136-1.15 2.271-2.294 3.413-3.431l3.1-3.104c2.956-2.961 5.913-5.921 8.862-8.888 0.124-0.105 0.183-0.124 0.228-0.124z M2.539 7.126h11.394c0.444 0 0.803-0.359 0.803-0.804s-0.359-0.804-0.803-0.804h-11.394c-1.403 0-2.539 1.144-2.539 2.542v21.398c0 1.405 1.142 2.542 2.539 2.542h21.36c1.403 0 2.539-1.144 2.539-2.542v-10.947c0-0.444-0.359-0.804-0.803-0.804s-0.803 0.359-0.803 0.804v10.947c0 0.516-0.424 0.941-0.94 0.941h-21.353c-0.516 0-0.94-0.425-0.94-0.941v-21.391c0-0.516 0.424-0.941 0.94-0.941z",
      fill: "#323232",
    },
  ],
  location: [
    {
      d: "M10.184,0A10.009,10.009,0,0,1,20.368,9.825,9.87,9.87,0,0,1,14.083,18.9c-2.448.633-3.838,3.757-3.838,3.757A5.558,5.558,0,0,0,6.273,18.9,9.8,9.8,0,0,1,0,9.825,10.009,10.009,0,0,1,10.184,0Z",
      fill: "#000000",
    },
    {
      d: "M8.953,12.527,4.814,17.168c-.133.143-.369-.044-.32-.231L5.3,13.6H4.071c-.123,0-.212-.059-.177-.177L5.257,8.935c.02-.079.093-.256.177-.256H8.357a.187.187,0,0,1,.162.28L7.265,12.124h1.55c.138,0,.246.285.138.4Z",
      fill: "#fade00",
      transform: "translate(4 -2.5)",
    },
  ],
  lock: [
    {
      d: "M16,7h-.857V5.286a4.286,4.286,0,1,0-8.571,0V7H5.714A1.719,1.719,0,0,0,4,8.714v8.571A1.719,1.719,0,0,0,5.714,19H16a1.719,1.719,0,0,0,1.714-1.714V8.714A1.719,1.719,0,0,0,16,7Zm-5.143,7.714A1.714,1.714,0,1,1,12.571,13,1.719,1.719,0,0,1,10.857,14.714ZM13.514,7H8.2V5.286a2.657,2.657,0,0,1,5.314,0Z",
      fill: "#000000",
    },
  ],
  payment: [
    {
      d: "M0,0H18V3H0Z",
      fill: "#000000",
      transform: "translate(1 4)",
    },
    {},
    {
      d: "M0,0H18V8H0Z",
      fill: "#000000",
      transform: "translate(1 9)",
    },
  ],
  plus: [
    {
      d: "M18 14v-12c0-1.104-0.896-2-2-2s-2 0.896-2 2v12h-12c-1.104 0-2 0.896-2 2s0.896 2 2 2h12v12c0 1.104 0.896 2 2 2s2-0.896 2-2v-12h12c1.104 0 2-0.896 2-2s-0.896-2-2-2h-12z",
      fill: "#21125E",
    },
  ],
  shipping: [
    {
      d: "M20,8H17V4H3A2.006,2.006,0,0,0,1,6V17H3a3,3,0,1,0,6,0h6a3,3,0,1,0,6,0h2V12ZM6,18.5A1.5,1.5,0,1,1,7.5,17,1.5,1.5,0,0,1,6,18.5Zm13.5-9L21.46,12H17V9.5Zm-1.5,9A1.5,1.5,0,1,1,19.5,17,1.5,1.5,0,0,1,18,18.5Z",
      fill: "#000000",
    },
  ],
  social_email: [
    {
      d: "M16.9834 25.7373L16.8926 25.7373Q15.833 28.6284 13.0479 28.6284Q10.959 28.6284 9.67993 27.1147Q8.40088 25.6011 8.40088 22.9219Q8.40088 19.4556 10.1719 17.2834Q11.9429 15.1113 14.4404 15.1113Q16.6958 15.1113 17.271 16.8823L17.3315 16.8823L17.4829 15.3838L20.8887 15.3838Q20.2075 21.832 20.2075 23.8149Q20.2075 25.9341 21.3579 25.9341Q22.5688 25.9341 23.356 24.3296Q24.1431 22.7251 24.1431 20.1367Q24.1431 16.5947 21.8877 14.3318Q19.6323 12.0688 15.5908 12.0688Q11.2617 12.0688 8.39331 15.0886Q5.5249 18.1084 5.5249 22.4526Q5.5249 26.6606 8.0603 29.0825Q10.5957 31.5044 15.061 31.5044Q18.4668 31.5044 21.2974 30.2026L21.2974 33.0938Q18.709 34.1987 14.6372 34.1987Q9.05176 34.1987 5.54761 30.9973Q2.04346 27.7959 2.04346 22.3921Q2.04346 16.8823 5.78223 13.1284Q9.521 9.37451 15.4092 9.37451Q20.8584 9.37451 24.2263 12.2883Q27.5942 15.2021 27.5942 19.9702Q27.5942 23.8906 25.5356 26.2595Q23.477 28.6284 20.4346 28.6284Q18.936 28.6284 17.9976 27.8262Q17.0591 27.0239 16.9834 25.7373ZM14.9702 17.7451Q13.6079 17.7451 12.7527 19.2966Q11.8975 20.8481 11.8975 22.8916Q11.8975 24.3447 12.4802 25.1545Q13.063 25.9644 13.9863 25.9644Q15.4092 25.9644 16.2266 24.4355Q17.0439 22.9067 17.0439 20.3486Q17.0439 19.1377 16.4763 18.4414Q15.9087 17.7451 14.9702 17.7451Z",
      fill: "#FACA00",
      transform: "translate(0 -4)",
    },
  ],
  social_facebook: [
    {
      d: "M 19.253906 2 C 15.311906 2 13 4.0821719 13 8.8261719 L 13 13 L 8 13 L 8 18 L 13 18 L 13 30 L 18 30 L 18 18 L 22 18 L 23 13 L 18 13 L 18 9.671875 C 18 7.884875 18.582766 7 20.259766 7 L 23 7 L 23 2.2050781 C 22.526 2.1410781 21.144906 2 19.253906 2 z",
      fill: "#1877F2",
    },
  ],
  social_google: [
    {
      d: "M8.843 0.856108C12.2555 -0.316133 15.9666 -0.283662 19.358 0.948108C21.2222 1.64768 22.9303 2.70751 24.385 4.0671C23.898 4.59711 23.37 5.09311 22.866 5.60511L19.998 8.48211C19.0456 7.56851 17.8935 6.88924 16.633 6.49811C15.1458 6.05068 13.5695 5.98704 12.051 6.31311C10.2754 6.70613 8.65346 7.6099 7.385 8.91311C6.36126 9.95665 5.58663 11.2181 5.119 12.6031C3.419 11.2751 1.712 9.95511 0 8.62711C1.82751 4.98735 4.99856 2.2007 8.843 0.856106L8.843 0.856108Z",
      fill: "#EA4335",
      transform: "translate(1.67382 0)",
    },
    {
      d: "M0.307058 3.934C0.583191 2.56769 1.04127 1.24456 1.66906 0C3.37506 1.328 5.08206 2.648 6.79706 3.976C6.12458 5.94894 6.12458 8.08906 6.79706 10.062C5.09039 11.39 3.38406 12.7153 1.67806 14.038C0.0990143 10.9196 -0.383938 7.36038 0.307056 3.934L0.307058 3.934Z",
      fill: "#FBBC05",
      transform: "translate(0.01176453 8.586108)",
    },
    {
      d: "M0.00799561 0L14.718 0C15.2168 2.7628 15.08 5.60291 14.318 8.30499C13.6123 10.7875 12.2436 13.0308 10.359 14.794L5.39999 10.936C7.05489 9.81599 8.18356 8.07153 8.52699 6.103L0 6.103C0.00866699 4.06833 0.0113373 2.034 0.00799561 0L0.00799561 0Z",
      fill: "#4285F4",
      transform: "translate(15.59282 12.65911)",
    },
    {
      d: "M0 3.976C1.70667 2.65601 3.413 1.33067 5.119 0C5.77756 1.93712 7.03076 3.61691 8.7 4.80002C9.74497 5.53317 10.9295 6.04373 12.18 6.3C13.4128 6.53377 14.6782 6.53648 15.912 6.308C17.143 6.09915 18.3168 5.63522 19.358 4.946L24.326 8.804C22.5158 10.4503 20.3086 11.5975 17.921 12.133C15.2868 12.7523 12.5416 12.7235 9.921 12.049C7.84864 11.4983 5.91359 10.5223 4.239 9.183C2.46796 7.77238 1.02207 5.99632 0 3.976L0 3.976Z",
      fill: "#34A853",
      transform: "translate(1.671822 18.63511)",
    },
  ],
  social_instagram: [
    {
      d: "M16 32c-8.836 0-16-7.163-16-16s7.164-16 16-16c8.836 0 16 7.163 16 16s-7.164 16-16 16zM22.609 5.451h-13.307c-2.124 0-3.852 1.728-3.852 3.852v13.307c0 2.124 1.728 3.852 3.852 3.852h13.307c2.124 0 3.852-1.728 3.852-3.852v-13.307c0-2.123-1.728-3.852-3.852-3.852zM10.512 7.335c-1.752 0-3.177 1.425-3.177 3.177v10.976c0 1.752 1.425 3.177 3.177 3.177h10.976c1.752 0 3.177-1.425 3.177-3.177v-10.976c0-1.752-1.425-3.177-3.177-3.177h-10.976zM16 21.705c-3.145 0-5.705-2.559-5.705-5.705s2.559-5.705 5.705-5.705c3.146 0 5.705 2.559 5.705 5.705s-2.559 5.705-5.705 5.705zM21.888 11.475c-0.745 0-1.35-0.606-1.35-1.35 0-0.273 0.081-0.526 0.22-0.737 0.065-0.1 0.143-0.19 0.232-0.269 0.239-0.213 0.554-0.344 0.899-0.344 0.568 0 1.055 0.352 1.253 0.85 0.062 0.155 0.096 0.323 0.096 0.5 0 0.744-0.605 1.35-1.35 1.35zM12.705 15.999c0-1.815 1.479-3.293 3.295-3.293s3.294 1.478 3.294 3.293c0 1.817-1.477 3.295-3.294 3.295s-3.295-1.478-3.295-3.295z",
      fill: "#21125e",
    },
  ],
  social_twitter: [
    {
      d: "M16 0c-8.822 0-16 7.178-16 16s7.178 16 16 16c8.822 0 16-7.178 16-16s-7.177-16-16-16zM23.138 12.338c0.007 0.159 0.011 0.318 0.011 0.478 0 4.866-3.703 10.476-10.479 10.476-2.080 0-4.016-0.608-5.645-1.653 0.288 0.034 0.581 0.052 0.878 0.052 1.726 0 3.313-0.589 4.574-1.576-1.611-0.030-2.972-1.094-3.44-2.558 0.224 0.043 0.456 0.066 0.692 0.066 0.336 0 0.662-0.044 0.971-0.128-1.685-0.338-2.954-1.826-2.954-3.611 0-0.015 0-0.032 0.001-0.046 0.496 0.275 1.064 0.441 1.667 0.46-0.987-0.659-1.638-1.787-1.638-3.065 0-0.675 0.181-1.308 0.498-1.852 1.816 2.229 4.53 3.694 7.59 3.849-0.063-0.27-0.095-0.55-0.095-0.84 0-2.033 1.649-3.683 3.682-3.683 1.060 0 2.015 0.447 2.688 1.163 0.84-0.165 1.626-0.47 2.339-0.894-0.277 0.86-0.859 1.582-1.622 2.038 0.746-0.089 1.457-0.286 2.115-0.579-0.491 0.737-1.116 1.386-1.835 1.904z",
      fill: "#21125e",
    },
  ],
  social_youtube: [
    {
      d: "M13.084 19.507c2.401-1.245 4.781-2.479 7.183-3.724-2.409-1.257-4.788-2.498-7.183-3.747 0 2.499 0 4.973 0 7.471z",
      fill: "#21125e",
    },
    {
      d: "M16 0c-8.836 0-16 7.163-16 16s7.164 16 16 16 16-7.163 16-16c0-8.837-7.164-16-16-16zM26.902 21.341c-0.277 1.201-1.26 2.088-2.442 2.22-2.801 0.313-5.636 0.315-8.46 0.313-2.824 0.002-5.659 0-8.461-0.313-1.183-0.132-2.165-1.018-2.441-2.22-0.394-1.711-0.394-3.579-0.394-5.341s0.005-3.63 0.398-5.341c0.276-1.201 1.258-2.088 2.441-2.22 2.802-0.313 5.638-0.315 8.461-0.313 2.823-0.002 5.659 0 8.459 0.313 1.183 0.132 2.166 1.018 2.442 2.22 0.394 1.711 0.391 3.579 0.391 5.341s-0.001 3.63-0.395 5.341z",
      fill: "#21125e",
    },
  ],
  summary: [
    {
      d: "M464,14015v-2h9v2Zm0-4v-2h17v2Zm0-4v-2h17v2Zm0-4v-2h17v2Z",
      fill: "#000000",
      transform: "translate(-463 -13997)",
    },
  ],
  trash: [
    {
      d: "M26.0095 11.5905H22.2644V10.9799C22.2644 9.88816 21.3762 9 20.2845 9H16.7245C15.6328 9 14.7447 9.88816 14.7447 10.9799V11.5905H10.9996C10.722 11.5905 10.5 11.8125 10.5 12.09C10.5 12.3676 10.722 12.5896 10.9996 12.5896H11.9025V24.3281C11.9025 25.801 13.1016 27 14.5744 27H22.4346C23.9075 27 25.1065 25.801 25.1065 24.3281V12.5896H26.0095C26.287 12.5896 26.509 12.3676 26.509 12.09C26.509 11.8125 26.287 11.5905 26.0095 11.5905ZM15.7438 10.9799C15.7438 10.4396 16.1842 9.99918 16.7245 9.99918H20.2845C20.8248 9.99918 21.2652 10.4396 21.2652 10.9799V11.5905H15.7438V10.9799ZM24.1073 24.3281C24.1073 25.2496 23.3561 26.0008 22.4346 26.0008H14.5744C13.653 26.0008 12.9017 25.2496 12.9017 24.3281V12.5896H24.111V24.3281H24.1073Z",
      fill: "#323232",
    },
    {
      d: "M18.5045 24.2108C18.782 24.2108 19.0041 23.9887 19.0041 23.7112V14.8814C19.0041 14.6039 18.782 14.3818 18.5045 14.3818C18.2269 14.3818 18.0049 14.6039 18.0049 14.8814V23.7075C18.0049 23.985 18.2269 24.2108 18.5045 24.2108Z",
      fill: "#323232",
    },
    {
      d: "M15.2447 23.6595C15.5223 23.6595 15.7443 23.4375 15.7443 23.16V15.4293C15.7443 15.1517 15.5223 14.9297 15.2447 14.9297C14.9672 14.9297 14.7451 15.1517 14.7451 15.4293V23.16C14.7451 23.4375 14.9709 23.6595 15.2447 23.6595Z",
      fill: "#323232",
    },
    {
      d: "M21.7652 23.6595C22.0428 23.6595 22.2648 23.4375 22.2648 23.16V15.4293C22.2648 15.1517 22.0428 14.9297 21.7652 14.9297C21.4877 14.9297 21.2656 15.1517 21.2656 15.4293V23.16C21.2656 23.4375 21.4877 23.6595 21.7652 23.6595Z",
      fill: "#323232",
    },
  ],

  trash1: [
    {
      d: "M26.01 11.59h-3.746v-.61c0-1.092-.888-1.98-1.98-1.98h-3.56c-1.091 0-1.98.888-1.98 1.98v.61H11c-.278 0-.5.223-.5.5 0 .278.222.5.5.5h.902v11.738c0 1.473 1.2 2.672 2.672 2.672h7.86a2.675 2.675 0 0 0 2.672-2.672V12.59h.903c.278 0 .5-.222.5-.5 0-.277-.222-.5-.5-.5zm-10.266-.61c0-.54.44-.98.98-.98h3.56c.54 0 .981.44.981.98v.61h-5.521v-.61zm8.363 13.348c0 .922-.75 1.673-1.672 1.673h-7.86a1.676 1.676 0 0 1-1.673-1.673V12.59H24.11v11.738h-.004z",
      fill: "#323232",
      transform: "translate(2828 -18229)",
    },
  ],
  verified: [
    {
      d: "M9,0A9,9,0,1,1,0,9,9.247,9.247,0,0,1,1.208,4.493,8.875,8.875,0,0,1,9,0Z",
      fill: "#2a912e",
    },
    {
      d: "M6.226,10.919l-2.1-2.1-.715.71,2.816,2.816L12.27,6.3l-.71-.71Z",
      fill: "#fff",
      stroke: "#fff",
      transform: "translate(1 0)",
    },
  ],
  x: [
    {
      d: "M27.821,25.078l-2.743,2.743-6.985-6.985-6.985,6.985L8.364,25.078l6.985-6.985L8.364,11.108l2.743-2.743,6.985,6.985,6.985-6.985,2.743,2.743-6.985,6.985Z",
      fill: "#c4c4c4",
      transform: "translate(-8.364 -8.364)",
    },
  ],
  camera: [
    {
      d: "M2.286,3.286V1H3.81V3.286H6.1V4.81H3.81V7.1H2.286V4.81H0V3.286ZM4.571,7.857V5.571H6.857V3.286H12.19L13.585,4.81H16a1.528,1.528,0,0,1,1.524,1.524v9.143A1.528,1.528,0,0,1,16,17H3.81a1.528,1.528,0,0,1-1.524-1.524V7.857ZM9.9,14.714A3.81,3.81,0,1,0,6.1,10.9,3.811,3.811,0,0,0,9.9,14.714ZM7.467,10.9A2.438,2.438,0,1,0,9.9,8.467,2.435,2.435,0,0,0,7.467,10.9Z",
      transform: "translate(2 6)",
      fill: "#000000",
      path: "#000000",
    },
  ],
  greenTick: [
    {
      d: "M2410-2262a7.008,7.008,0,0,1-7-7,7.008,7.008,0,0,1,7-7,7.008,7.008,0,0,1,7,7A7.008,7.008,0,0,1,2410-2262Zm-2.659-7.061h0l-.91.909,2.581,2.581,5.163-5.162-.91-.916-4.253,4.253-1.671-1.664Z",
      transform: "translate(-2395 2283)",
      fill: "#33B95E",
      path: "#33B95E",
    },
  ],
}
