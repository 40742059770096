import { useQuery, useReactiveVar } from "@apollo/client"
import { Loader } from "@components/atoms/Loader"
import { NavLink } from "@components/atoms/NavLink"
import { secondaryMenu } from "@temp/components/Footer/queries"
import { MainMenu_shop_navigation_main_items } from "@temp/components/MainMenu/types/MainMenu"
import { MainMenuSubItem } from "@temp/components/MainMenu/types/MainMenuSubItem"
import Link from "@temp/core/link"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import Image from "next/image"
import React, { useState } from "react"
import { ReactSVG } from "react-svg"

import { FooterStartupLogo } from "../FooterStartupLogo/FooterStartupLogo"
import * as S from "./styles"

export const FooterUpper: React.FC = () => {
  const matches: GlobalMediaQueryResultInterface = useReactiveVar(getMedia)

  // States
  const [showMenu, setshowMenu] = useState([])

  const setshowMenu1 = (index: never) => {
    const showMenu1 = [...showMenu]
    if (showMenu1.includes(index)) {
      showMenu1.splice(showMenu1.indexOf(index), 1)
    } else {
      showMenu1.push(index)
    }
    setshowMenu(showMenu1)
  }

  let src = "/images/Footer/FooterUpper/buyceps_logo_yellow.svg"
  if (process.env.NEXT_PUBLIC_WEBSITE === "INWELCO") {
    src = "/images/Footer/FooterUpper/inwelco_logo_yellow.svg"
  }

  const { data, loading } = useQuery(secondaryMenu)
  if (!data || loading) return <Loader />
  return (
    <S.FooterUpperContainer isMobileScreen={!matches.large}>
      <S.FooterUpperLogoSection>
        <Link prefetch={false} href="/">
          <a>
            <S.FooterUpperLogoSectionLogo isMobileScreen={!matches.large}>
              <Image src={src} width="121" height="30.41" alt="Buyceps Logo" />
            </S.FooterUpperLogoSectionLogo>
            <S.FooterUpperLogoMessage isMobileScreen={!matches.large}>
              Powering Your Fitness Regime
            </S.FooterUpperLogoMessage>
          </a>
        </Link>
        <S.FooterUpperLogoAddress
          isMobileScreen={!matches.large}
          isIPadScreen={matches.medium}
        >
          <p>
            Shop no. 3, Building no. 25, Oshiwara Linkway CHSL, New Link Road
            Extension, Oshiwara, Andheri West, Mumbai, Maharashtra-400053, India
          </p>
          <br />

          <S.FooterUpperLogoPhone>
            <a href="tel: +917004090151">Ph: 7004090151</a>
            <br />
            <a href="mailto:hello@buyceps.com">Email: hello@buyceps.com</a>
          </S.FooterUpperLogoPhone>
        </S.FooterUpperLogoAddress>
      </S.FooterUpperLogoSection>

      {!matches.large ? (
        <S.FooterUpperMobile isMobileScreen={!matches.large}>
          {data &&
            data.shop.navigation.secondary.items.map(
              (item: MainMenu_shop_navigation_main_items, index: never) => {
                const isActive = showMenu.includes(index)
                return (
                  <S.FooterUpperMobileLinkSection key={item.id}>
                    <S.FooterUpperMobileLinkSectionHeader
                      isMobileScreen={!matches.large}
                    >
                      <NavLink
                        prefetch={false}
                        data-index={index}
                        onClick={() => {
                          setshowMenu1(index)
                        }}
                        item={item}
                      />
                      <S.FooterUpperMobileLinkSectionHeaderarrow isActive={isActive}>
                        <ReactSVG src="/images/Footer/FooterUpper/ic_keyboard_arrow_up_24px.svg" />
                      </S.FooterUpperMobileLinkSectionHeaderarrow>
                    </S.FooterUpperMobileLinkSectionHeader>
                    <S.FooterUpperMobileLinkSectionContent
                      isMobileScreen={!matches.large}
                    >
                      {isActive &&
                        item.children!.map((subItem) => (
                          <div key={subItem!.id}>
                            <NavLink
                              prefetch={false}
                              item={subItem as MainMenuSubItem}
                            />
                          </div>
                        ))}
                    </S.FooterUpperMobileLinkSectionContent>
                  </S.FooterUpperMobileLinkSection>
                )
              }
            )}
        </S.FooterUpperMobile>
      ) : (
        data &&
        data.shop.navigation.secondary.items.map(
          (item: MainMenu_shop_navigation_main_items) => (
            <S.FooterUpperLinkSection key={item.id} isMobile={!matches.large}>
              <S.FooterUpperLinkHeader>
                <NavLink prefetch={false} item={item} />
              </S.FooterUpperLinkHeader>
              <S.FooterUpperLinkContent>
                {item.children!.map((subItem) => (
                  <div key={subItem!.id}>
                    <NavLink prefetch={false} item={subItem as MainMenuSubItem} />
                  </div>
                ))}
              </S.FooterUpperLinkContent>
            </S.FooterUpperLinkSection>
          )
        )
      )}
    </S.FooterUpperContainer>
  )
}
