import { useReactiveVar } from "@apollo/client"
import { includes, isEmpty } from "lodash"
import Script from "next/script"
import React, { useEffect, useState } from "react"
import { thirdTick } from "@temp/localStates/documentComplete"
import { useRouter } from "next/router"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import { useToggle } from "@temp/@next/hooks/useToggle"
import { userGlobalState } from "../User/userGlobalState"
import { tawkToGlobalState } from "./TawkToGlobal"
import { hmacSha256Hex } from "./utils"
import * as S from "./styles"
import { loadWidgetScript } from "./states"

declare global {
  interface Window {
    Tawk_API: any
  }
}

// change bottom position of tawkTo icon on these pages
const changeBottomPages = [
  "/product/[name]/[id]",
  "/search",
  "/brand/[slug]/[id]",
  "/collection/[slug]/[id]",
  "/category/[slug]/[id]",
  "/combo-product/[name]/[id]",
]
// hide fc icon on these pages
const hiddenPages = ["payments/process-payment", "/cart"]

export const TawkTo = () => {
  const propertyId = process.env.NEXT_PUBLIC_TAWKTO_PROPERTY_ID
  const widgetId = process.env.NEXT_PUBLIC_TAWKTO_WIDGET_ID
  const apiKey = process.env.NEXT_PUBLIC_TAWKTO_API_KEY

  const router = useRouter()
  const matches: GlobalMediaQueryResultInterface = getMedia()

  const [showWidget, setShowWidget] = useState(true)
  const [minimized, setMinimized] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [bottom, toggleBottom] = useToggle(
    includes(changeBottomPages, router.pathname) && matches.small
  ) // to toggle bottom style of fc icon

  const { loaded, tawkToApi } = useReactiveVar(tawkToGlobalState)
  const { user } = useReactiveVar(userGlobalState)
  const tick = useReactiveVar(thirdTick)
  const loadscript = useReactiveVar(loadWidgetScript)

  async function handleTawkToUserAttribute() {
    if (user?.email) {
      const hashedValue = await hmacSha256Hex(apiKey, user?.email)
      // @ts-ignore
      tawkToApi.setAttributes(
        {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          hash: hashedValue,
        },
        (error: any) => {
          console.log(error)
        }
      )
    }
  }

  function setTawkToPosition(bottomInset: string): void {
    if (!matches.small) return

    // check if bottom position needs to be changed on current page(pages with CTAs)
    if (includes(changeBottomPages, router.pathname)) {
      if (loaded && !isEmpty(tawkToApi)) {
        // get all iframes with title = chat widget
        const iframes = document.querySelectorAll("iframe[title='chat widget']")
        for (const f of iframes as any) {
          f.style.bottom = bottomInset
          f.style.transition = "bottom .2s linear"
        }
        if (window.Tawk_API?.showWidget) {
          window.Tawk_API.showWidget()
        }
      } else {
        // if facade exists then toggle facade's bottom pos
        toggleBottom(true)
        if (!showWidget) {
          setShowWidget(true)
        }
      }
    } else if (includes(hiddenPages, router.pathname)) {
      if (loaded && !isEmpty(tawkToApi)) {
        if (window.Tawk_API?.hideWidget) {
          window.Tawk_API.hideWidget()
        }
      } else {
        // hide facade if facade still exists
        setShowWidget(false)
      }
    }
    // if bottom position doesn't needs to be changes then set default bottom pos
    else {
      // default facade bottom pos
      toggleBottom(false)
      // default widget bottom position
      if (loaded && !isEmpty(tawkToApi)) {
        const iframes = document.querySelectorAll("iframe[title='chat widget']")
        for (const f of iframes as any) {
          f.style.transition = "bottom .2s linear"
          f.style.bottom = "20px"
        }
        if (window.Tawk_API?.showWidget) {
          window.Tawk_API.showWidget()
        }
      } else {
        // if tawkto not loaded then show facade again
        // eslint-disable-next-line no-lonely-if
        if (!showWidget) {
          setShowWidget(true)
        }
      }
    }
  }

  // effect to set global tawkToApi state
  useEffect(() => {
    if (loaded && isEmpty(tawkToApi)) {
      tawkToGlobalState({
        ...tawkToGlobalState(),
        tawkToApi: window.Tawk_API,
      })
    }
  }, [loaded, tawkToApi])

  // effect to set user attribute in TawkTo
  useEffect(() => {
    if (loaded && !isEmpty(tawkToApi) && user?.email) {
      handleTawkToUserAttribute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, tawkToApi, user?.email])

  // effect to load Tawkto script after third tick
  useEffect(() => {
    let timer: NodeJS.Timeout
    if (tick) {
      // allow the script to load
      loadWidgetScript(true)

      // hide facade after 2 seconds
      timer = setTimeout(() => {
        setShowWidget(false)
      }, 2000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [tick])

  // effect to open(maximize) Tawkto widget after clicking on facade
  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isLoading && loaded && !isEmpty(tawkToApi)) {
      // using timeout because Tawkto takes approx 2 sec to establish its socket connection
      // this still works without timeout but only at the cost of an error message in the console.
      timer = setTimeout(() => {
        // turn off loader
        setIsLoading(false)

        // hide facade
        setShowWidget(false)
        window.Tawk_API.maximize()
      }, 2000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [isLoading, loaded, tawkToApi])

  // to change fc icon style depending on pages
  useEffect(() => {
    setTawkToPosition("90px")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname, loaded, tawkToApi])

  // effect to re-position bottom inset
  // NOTE: This is required because Tawkto widget resets its CSS after minimizing the widget
  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {}
    window.Tawk_API.onChatMinimized = function mini() {
      setMinimized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (minimized) {
      setMinimized(false)
      setTawkToPosition("90px")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimized])

  return (
    <>
      {showWidget && (
        <S.FacadeWrapper
          id="tawktofacade"
          bottom={bottom}
          onClick={() => {
            if (!loadscript) {
              loadWidgetScript(true)
              setIsLoading(true)
            }
          }}
        >
          {isLoading ? (
            <span className="spinner" />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 800 800"
              height="32px"
              width="32px"
              role="img"
            >
              <path d="M400 26.2c-193.3 0-350 156.7-350 350 0 136.2 77.9 254.3 191.5 312.1 15.4 8.1 31.4 15.1 48.1 20.8l-16.5 63.5c-2 7.8 5.4 14.7 13 12.1l229.8-77.6c14.6-5.3 28.8-11.6 42.4-18.7C672 630.6 750 512.5 750 376.2c0-193.3-156.7-350-350-350zm211.1 510.7c-10.8 26.5-41.9 77.2-121.5 77.2-79.9 0-110.9-51-121.6-77.4-2.8-6.8 5-13.4 13.8-11.8 76.2 13.7 147.7 13 215.3.3 8.9-1.8 16.8 4.8 14 11.7z" />
            </svg>
          )}
        </S.FacadeWrapper>
      )}
      {loadscript && (
        <div id="tawk-widget-wrapper">
          <Script
            id="_t_a_w_k"
            src={`https://embed.tawk.to/${propertyId}/${widgetId}`}
            onLoad={() => {
              // using timeout because tawkto takes approx 1 seconds to initialize and attach in global window object
              setTimeout(() => {
                tawkToGlobalState({
                  ...tawkToGlobalState(),
                  loaded: true,
                })
              }, 1000)
            }}
          />
        </div>
      )}
    </>
  )
}
