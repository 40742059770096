import { gql } from "@apollo/client"

export const mainMenu = gql`
  fragment MainMenuSubItem on MenuItem {
    id
    name
    featured
    finalUrl
    parent {
      id
    }
  }

  query MainMenu {
    shop {
      navigation {
        main {
          id
          items {
            ...MainMenuSubItem
            children {
              ...MainMenuSubItem
              children {
                ...MainMenuSubItem
              }
            }
          }
        }
      }
    }
  }
`
