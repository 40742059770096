import { useReactiveVar } from "@apollo/client"
import { userGlobalState } from "@temp/@next/globalStates/User/userGlobalState"
import React, { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import { ErrorButton } from "../../molecules/ErrorFallbackUi/styles"
import * as S from "./styles"
import { Input } from "../../atoms/Input"
import { Spacer } from "../../atoms/Spacer"

interface Props {
  error: Error
  componentName: string
}

export const ErrorModal = (props: Props) => {
  const { error, componentName } = props
  const { user } = useReactiveVar(userGlobalState)
  const router = useRouter()
  const matches: GlobalMediaQueryResultInterface = getMedia()

  const [message, setMessage] = useState("")
  const [navigatorData, setNavigatorData] = useState({
    platform: "",
    vendor: "",
  })

  const sharedText = `User: ${user?.id || "No User"} \n URL:${router.asPath
    } \n Commit: ${process.env.NEXT_PUBLIC_COMMIT_SHA
    } \n ComponentName: ${componentName} \n Error:${error.message} \n Message:${message || "EMPTY"
    } \n Device: ${JSON.stringify(navigatorData)} \n StackTrace: ${error.stack}`

  useEffect(() => {
    if (navigator) {
      setNavigatorData({
        platform: navigator?.userAgentData?.platform,
        vendor: navigator?.vendor,
      })
    }
  }, [])

  const handleShare = () => {
    const shareData = {
      title: "Buyceps Error Message",
      text: sharedText,
      url: router.asPath,
    }
    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Thanks for sharing!"))
        .catch((error_) => {
          console.log(`Couldn't share because of`, error_.message)
        })
    }
  }

  return (
    <S.ErrorModalContainer>
      <div>
        <S.ErrorModalRow>
          <S.ErrorModalPoint>User:</S.ErrorModalPoint> {user?.id || "No User"}
        </S.ErrorModalRow>
        <S.ErrorModalRow>
          <S.ErrorModalPoint>Error:</S.ErrorModalPoint> {error.message}
        </S.ErrorModalRow>
        <S.ErrorModalRow>
          <S.ErrorModalPoint>Component:</S.ErrorModalPoint> {componentName}
        </S.ErrorModalRow>
        <S.ErrorModalRow>
          <S.ErrorModalPoint>URL:</S.ErrorModalPoint> {router.asPath}
        </S.ErrorModalRow>
        <S.ErrorModalRow>
          <S.ErrorModalPoint>Message:</S.ErrorModalPoint>
          <div>
            <Input
              value={message}
              placeholder="Tell us what happened!"
              onChange={(event) => setMessage(event.target.value)}
              textArea
            />
          </div>
        </S.ErrorModalRow>

        <S.ErrorModalRow>
          <S.ErrorModalPoint>Stack Trace:</S.ErrorModalPoint>
          <S.ErrorModalInfo>{error.stack}</S.ErrorModalInfo>
        </S.ErrorModalRow>
      </div>
      {matches.small && <Spacer spacerStyle="spacerTriple" />}
      <S.ErrorButtonGroup>
        <S.ErrorWhatsappButton
          rel="noopener noreferrer"
          target="_blank"
          href={`https://wa.me/917004090151?text=${encodeURIComponent(sharedText)}`}
        >
          Whatsapp
        </S.ErrorWhatsappButton>
        <S.ErrorGmailButton
          rel="noopener noreferrer"
          target="_blank"
          href={`mailto:hello@buyceps.com?subject=${encodeURIComponent(
            "Issue at buyceps.com shared by user"
          )}&body=${encodeURIComponent(sharedText)}`}
        >
          Email
        </S.ErrorGmailButton>
        <ErrorButton onClick={() => handleShare()}>Other</ErrorButton>
      </S.ErrorButtonGroup>
    </S.ErrorModalContainer>
  )
}
