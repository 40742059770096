import { useReactiveVar } from "@apollo/client"
import { ContainerProps } from "@components/atoms/Container/types"
import { GlobalMediaQueryResultInterface } from "@temp/core/utils"
import { getMedia } from "@temp/localStates/media"
import React from "react"

import * as S from "./styles"

export const Container: React.FC<ContainerProps> = ({
  children,
  backgroundColor = "white",
}) => {
  const matches: GlobalMediaQueryResultInterface = useReactiveVar(getMedia)

  return (
    <S.ContainerWrapper backgroundColor={backgroundColor}>
      <S.Container
        screen={matches.large ? "large" : matches.medium ? "medium" : "small"}
      >
        {children}
      </S.Container>
    </S.ContainerWrapper>
  )
}
